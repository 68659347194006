import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {inject, observer} from "mobx-react";
import {MainAppStateInterface, ActiveDrawer} from "../../model/MainAppStateStore";
//import {Button} from "react-bootstrap";
import {withRouter} from "react-router";
import {RequirementIds, RequirementStatus} from "../../types/CovidStatusModel";
import dataController from "../../api/DataController";

import { Button, ButtonType, Spacer, Icon } from '@mit/hui';

interface TrainingLandingProps {
    mainAppState?: MainAppStateInterface;
    history?: any;
}

const TrainingLandingComponent: FC<TrainingLandingProps> = ({mainAppState, history}) => {

    const [isLoading, setIsLoading] = useState(true)
    const [trainingReqs, setTrainingReqs] = useState<any>([])

    useEffect(() => {
        setIsLoading(false)

        const abortController = new AbortController()
        dataController.fetchPassTraining(abortController.signal)
            .then((response: any) => {
                setTrainingReqs(response)
            }).catch(() => {
                //TODO
            })

        return () => {
            abortController.abort();
        };

    }, [])

    return <div className={`submitter-view`}>
        <div className={'nav-container'}>
            <div className={`main-content`}>
                <div className={`page-content text-align-center`}>
                    {!isLoading && mainAppState ? <div className={'content-page smallPaddingTop white-content'}>
                            {mainAppState.isRequirementMandatory(RequirementIds.training) ? <>


                                {mainAppState.status_model && mainAppState.evalRequirement(RequirementIds.training, RequirementStatus.complete) ? <>
                                    
                                    {(!mainAppState.evalRequirement(RequirementIds.medical, RequirementStatus.incomplete)) ? <>
                                        <div className="text-center">
                                        <div className="bigIcons"><Icon icon="check-circle" type="solid"/></div>
                                        <span>{mainAppState.texts['covid19.training.web.completed']}&nbsp;</span>
                                        {mainAppState.texts['covid19.medical.completed.text']}
                                       
                                    <Spacer/>
                                    <Button type={ButtonType.Secondary | ButtonType.Ghost} text={mainAppState!.texts['covid19.pass.training.continue.attestation']} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Attestation }/>
                                            </div>
                                        
                                    </> : <>
                                    <div className="text-center">
                                        <div className="bigIcons"><Icon icon="check-circle" type="solid"/></div>
                                        <span>{mainAppState.texts['covid19.training.web.completed']}&nbsp;</span>
                                        {mainAppState.texts['covid19.notraining.text.medical']}
                                        <Spacer />
                                        <Button type={ButtonType.Secondary | ButtonType.Ghost} text={mainAppState!.texts['covid19.pass.training.continue.medical']} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Medical} />
                                        </div>    
                                            
                                            
                                    </>}
                                </> : <>
                                    {mainAppState && mainAppState.status_model && <div>{mainAppState.texts['covid19.training.web.required']}</div>}
                                    {<div className="text-center">
                                        <Spacer/>
                                        {trainingReqs && trainingReqs.length > 0 && trainingReqs.map((req: any) => {
                                            return <Button type={ButtonType.BigIcon | ButtonType.Ghost} icon="books" key={req.id} text={req.label}  onClick={() => (window.open(req.link, "_blank"))}/>

                                        })}
                                    </div>}
                                </>}
                            </> : <>
                                {mainAppState!.texts['covid19.pass.training.not_required']}
                                {(!mainAppState.evalRequirement(RequirementIds.medical, RequirementStatus.incomplete)) ? <>
                                <div className="text-center">
                                    <div className="bigIcons"><Icon icon="check-circle" type="solid"/></div>
                                    <Button type={ButtonType.Secondary | ButtonType.Ghost} text={mainAppState!.texts['covid19.pass.training.continue.attestation']} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Attestation}/>
                                    </div>        
                                    
                                </> : <>
                                <div className="text-center">
                                    <div className="bigIcons"><Icon icon="check-circle" type="solid"/></div>
                                        <Button type={ButtonType.Secondary | ButtonType.Ghost} text={mainAppState!.texts['covid19.pass.training.continue.medical']} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Medical}/>
                                </div>
                                       
                                </>}
                            </>}


                        </div> :
                        <>Loading...</>}
                </div>
                <Spacer size="5"/>
            </div>
        </div>
    </div>
}

const TrainingLanding = withRouter(inject('mainAppState')(observer(TrainingLandingComponent)))
export default TrainingLanding