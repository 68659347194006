export enum RequirementIds {
  acknowledge = 'acknowledge',
  training = 'training',
  medical = 'medical',
  attestation = 'attestation',
}

export enum RequirementStatus {
  complete = 'complete',
  incomplete = 'incomplete',
  flushot_incomplete = 'flushot_incomplete',
  pending = 'pending',
  due_soon = 'due_soon',
  overdue_grace = 'overdue_grace',
}

export enum PassStatus {
  access_granted = 'access_granted',
  requirements_incomplete = 'requirements_incomplete',
  medical_block = 'medical_block',
  no_access = 'no_access',
  medical_symptoms = 'medical_symptoms',
  medical_positive = 'medical_positive',
  medical_overdue = 'medical_overdue',
  waiver_declined = 'waiver_declined',
  hold = 'hold',
  no_access_with_testing = 'no_access_with_testing',
}

export enum PrimaryAffiliatons {
  student = 'student',
  staff = 'staff',
  affiliate = 'affiliate',
}

export enum HoldReason {
  none = 'none',
  awaiting_test_outcome = 'awaiting_test_outcome',
  residential = 'residential',
  travel = 'travel',
  vaccine = 'vaccine',
  positive_test = 'positive_test',
  contact = 'contact'
}

export interface CovidPassRequirement {
  id: RequirementIds;
  required: boolean;
  visible: boolean;
  status: RequirementStatus;
  title_web: string;
  last_completion?: string;
  last_completion_result?: string;
  next_completion?: string;
  prerequisites?: string[];
  medical_awaiting_test_outcome: boolean;
  flu_shot_required: boolean;
  flu_shot_completed: boolean;
  flu_shot_visible: boolean;
}

export interface PassStatusModel {
  status: PassStatus;
  vaccine_status: string;
  fully_vacinated: boolean;
  hold_reason: HoldReason;
  hold_from: string;
  hold_expiry: string;
  assignment_type: number;
  primary_affiliation: PrimaryAffiliatons;
  requirements: CovidPassRequirement[];
  scheduled_to_clear: boolean
  can_retest: boolean
  max_test_count_reached: boolean
  in_90_tesing_window: boolean
  is_override: boolean
}

export interface PassHoldInfoModel {
  title_text_id: string;
  info_text_id: string
  test_date: string
  instruction_list: InstructionListModel[]
  end_isolation_enabled: boolean
  isolation_day: number
}

export interface InstructionListModel {
  period: string
  icon_id: string
  description_text_id: string
  selected: boolean
}
