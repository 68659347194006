import * as React from "react";
import { inject, observer } from "mobx-react";
import { FC, useState } from "react";
import { MainAppStateInterface } from "../../model/MainAppStateStore";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import { Text, Drawer, DrawerSize, DrawerType, DrawerLayout, Form, FormContainer, FormField, Button, TemplateModal, TemplateModalAlignment, ButtonState, AlertProps, Alert } from "@mit/hui";
import moment from "moment";
import dataController from "api/DataController";
import ReactMarkdown from "react-markdown";

interface ReportPositiveTestProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	show: boolean;
	onClose: () => void;
}

const ReportPositiveTestDrawerComponent: FC<ReportPositiveTestProps> = ({ mainAppState, userProfileStoreState, show, onClose }) => {
	const [showReportPositiveTestModal, setShowReportPositiveTestModal] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<AlertProps>({ text: "" });
	const [positiveTest, setPositiveTest] = useState<boolean>(false);

	const getTestTypeID = (testType: string) => {
		switch (testType) {
			case "PRC":
				return 3;
			case "Home Test":
				return 4;
			default:
				return 3;
		}
	};

	return (
		<>
			<Drawer
				show={show}
				onClose={onClose}
				themedColor="medical"
				size={DrawerSize.Small}
				footer={[]}
				type={DrawerType.Right}
				layout={DrawerLayout.Hero}
				header={
					<div className={"inline-heading"}>
						<Text content={"Covid Result"} type={"h3"} />
					</div>
				}
				contents={
					<>
						{alertMessage.text && <Alert {...alertMessage} />}
						{/* POSITIVE COVID RESULT */}
						<div>
							<Text content={"Result Details"} type={"h3"} />
							<Form formValidationTrigger="onSubmit">
								{(methods) => {
									const onCheck = () => {
										methods.trigger().then((valid) => {
											if (!valid) return;

											if(positiveTest)
											setShowReportPositiveTestModal(true);
											else
											onSubmit();
										});
									};

									const onSubmit = () => {
										setIsBusy(true);
										let data = methods.getValues();
										dataController
											.postTestManual(data.dateOfResult, getTestTypeID(data.testType), positiveTest)
											.then(() => {
												setShowReportPositiveTestModal(false);

												mainAppState!.reload = true;
												onClose();
											})
											.catch((e) => {
												setShowReportPositiveTestModal(false);
												setAlertMessage({ text: e, type: "error" });

												setTimeout(() => {
													setAlertMessage({ text: "", type: "success" });
												}, 5000);
											})
											.finally(() => {
												setIsBusy(false);
											});
									};

									return (
										<>
											<FormContainer id="positive-result-form" formValidationTrigger="onSubmit" methods={methods}>
												<div>
													<FormField
														maxDate={{
															year: parseInt(moment().format("YYYY")),
															month: parseInt(moment().format("M")),
															day: parseInt(moment().format("D")),
														}}
														editor="calendar"
														label="Date of result"
														id="dateOfResult"
														value={moment().format("YYYY-MM-DD")}
														required
													/>
													<FormField
														editor="dropdown"
														label="Test type"
														id="testType"
														options={[
															{
																text: "PCR",
																id: "3",
																icon: "",
															},
															{
																text: "Home Test",
																id: "4",
																icon: "",
															},
														]}
														required
													/>
													<FormField editor="toggle" label="Possitive test" id="possitiveTest" properties={{onStateChanged: (e: any) => setPositiveTest(e.choice === "Positive" ? true : false)}} />
												</div>
											</FormContainer>
											{positiveTest && (
												<div className="my-2">
													<ReactMarkdown
														linkTarget={"_blank"}
														children={
															mainAppState?.texts["covid19.notify-mit.reportinfo.markdowntext"]?.replace("{date}", moment().subtract(90, "days").format("MMM D, YYYY")) ??
															"Reporting that you are positive with Covid will disqualify you from campus.  Do not log items before 1 Jan 2022"
														}
													/>
												</div>
											)}

											<div className="d-flex justify-content-end">
												<Button text="Submit" onClick={onCheck} />
											</div>
											<TemplateModal
												show={showReportPositiveTestModal}
												theme="medical"
												onClose={() => setShowReportPositiveTestModal(false)}
												body={
													<div>
														<Text
															content={
																mainAppState!.texts["covid19.notify-mit.positive-text.info"] ??
																"Reporting that you have a positive test will put you on a Medical Hold. The isolation guidelines will get you through the next 10 days."
															}
														/>
													</div>
												}
												bodyAlignment={TemplateModalAlignment.Center}
												footer={
													<div className="d-flex justify-content-center">
														<div className="btn-padded">
															<Button text="Cancel" outlined onClick={() => setShowReportPositiveTestModal(false)} />
															<Button
																isBusy={isBusy}
																state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
																text={mainAppState!.texts["covid19.notify-mit.positive-popup.confirm"] ?? "I have a positive test result"}
																overwriteColor="danger"
																onClick={onSubmit}
															/>
														</div>
													</div>
												}
												header={<Text content={mainAppState!.texts["covid19.notify-mit.positive-popup.title"] ?? "Report positive test"} type={"h3"} />}
												name="reportModal"
											/>
										</>
									);
								}}
							</Form>
						</div>
					</>
				}
			/>
		</>
	);
};

const ReportPositiveTestDrawer = inject("mainAppState", "userProfileStoreState")(observer(ReportPositiveTestDrawerComponent));
export default ReportPositiveTestDrawer;
