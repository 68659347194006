import * as React from 'react'
import { FC, useEffect, useState, Fragment } from 'react'
import { Alert } from "react-bootstrap";
import { Answers, Question } from "../../types/Questions";
import dataController from "../../api/DataController";
import { PageError } from "../../types/PageErrors";
import { AnswerServer } from "../../types/Attestation";
import { inject, observer, Provider } from "mobx-react";
import { displayDateTime, toUtcDate } from "../../common/Formatters";
import Loader from 'react-loader'
import { mediumLoaderOptions } from "../../common/Defaults";
import { withRouter } from "react-router";
import { MainAppStateInterface } from "../../model/MainAppStateStore";
import { Toggle, Button, ButtonType, Spacer, TemplateModalAlignment, Text, TemplateModal, TemplateModalSize, TextType } from '@mit/hui';
import { withComponent } from '../../common/WithComponent';
import { ActiveDrawer } from '../../model/MainAppStateStore';
import { PostAttestationsResponse, FetchPassStatusResponse } from 'types/Api';

export interface QuestionsProps {
    mainAppState?: MainAppStateInterface;
    history?: any;
}

const QuestionsComponent: FC<QuestionsProps> = ({ mainAppState, history }) => {
    const [isShowingConfirmationPopup, setIsShowingConfirmationPopup] = useState(false);
    const [page1Error, setPage1Error] = useState<boolean>(false)
    const [page2ErrorText, setPage2ErrorText] = useState<string>('')
    const [page2Error, setPage2Error] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [answers, setAnswers] = useState<Answers>({})
    const [questions, setQuestions] = useState<Question[]>([])
    const [page1Questions, setPage1Questions] = useState<Question[]>([])
    const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })
    const [showStep1, setShowStep1] = useState<boolean>(true)
    const [showStep2, setShowStep2] = useState<boolean>(false)

    const [CONTACT_KEY, setContactKey] = useState<string>('')
    const [TESTED_KEY, setTestedKey] = useState<string>('')
    const [IS_MEDICAL_KEY, setMedicalKey] = useState<string>('')

    const onAnswerClick = (id: number, evt: any) => {

        if (evt.SWITCH_STATE.choice !== "Positive") {
            updateAnswer(`${id}`, true);
        }
        else {
            updateAnswer(`${id}`, false);
        }
    };

    //initialize answers only once
    useEffect(() => {
        dataController.fetchArray('questions').then((response: any) => {
            
            if (response.items) {
                mainAppState!.stepperSelectedIndex = 0;
                mainAppState!.stepperItemCount = 2;

                setQuestions(response.items);

                const p1Questions = response.items.filter((question: Question) => question.page === 1);
                setPage1Questions(p1Questions);

                // setContactKey(response.items.filter((q: Question) => q.short_text === 'Contact')[0].id)
                // setTestedKey(response.items.filter((q: Question) => q.short_text === 'Tested positive')[0].id)

                // let contactQ = getQuestionByKey(CONTACT_KEY, response.items)

                // setMedicalKey(contactQ.follow_up_questions!.filter((q: Question) => q.short_text === 'Health Care Worker')[0].id)
                const contactKey = response.items.find((q: Question) => q.short_text?.toLocaleLowerCase() === 'contact');
                const testedKey = response.items.find((q: Question) => q.short_text?.toLocaleLowerCase() === 'tested positive');
                contactKey && setContactKey(contactKey.id)
                testedKey && setTestedKey(testedKey.id)

                if (CONTACT_KEY) {
                    let contactQ = getQuestionByKey(CONTACT_KEY, response.items);

                    const medicalKey = contactQ.follow_up_questions!.find((q: Question) => q.short_text === 'health care worker');
                    medicalKey && setMedicalKey(medicalKey.id)
                }
            } else {
                setPageError({ error: true, errorText: 'An error occurred loading initial questions.', showRetry: false })
            }

            setIsLoading(false)
        })
    }, [])

    //Resetting the page and toggle control requires us to reset the original questions. 
    useEffect(() => {

        //This will only execute when the p1Questions have been set to [] by the reset method.
        if (!isLoading && page1Questions.length == 0) {
            var p1Questions = questions.filter((question: Question) => question.page === 1);
            setPage1Questions(p1Questions);
        }
    }, [page1Questions]);

    const getQuestionByKey = (key: string, arr: Question[]): Question => {
        return arr.filter((q: Question) => q.id == key)[0]
    }

    const isDisqualifyingQuestion = (key: string) => {
        let question = questions.filter((q: Question) => q.id == key)[0]
        return question && question.style === 'bad'
    }

    const followUpCountersNegativeAnswer = (key: string) => {
        let question = questions.filter((q: Question) => q.id == key)[0]
        //if no follow ups, pass through
        if (question.follow_up_questions && question.follow_up_questions.length > 0) {
            return question.follow_up_questions && question.follow_up_questions.filter((f: Question) => !answers[f.id]).length > 0
        } else {
            return true
        }
    }

    const getHasSymptom = (testAnswers: Answers): boolean => {
        //filter any answers that are true, are disqualifying, but are not countered by the follow up
        return Object.keys(testAnswers).filter((key: string) => isDisqualifyingQuestion(key) && testAnswers[key] === true && followUpCountersNegativeAnswer(key)).length > 0
    }

    const isInvertedQuestion = (question: Question) => {

        if (parseInt(question.id) === 11 || parseInt(question.id) === 12 || parseInt(question.id) === 13) {
            return false;
        }

        return true;
    }

    const updateAnswer = (id: string, newValue: boolean) => {
        let newAnswers = {
            ...answers,
            [id]: newValue
        }

        setAnswers(newAnswers);
    }

    const renderQuestion = (question: Question, value: boolean, invertColors: boolean = false): JSX.Element => {
        const { id, text } = question
       
        return <Fragment key={`render-q-${question.id}-${question.page}`}>

            <div className={'single-question'}>
                <div className={'question-text'}>
                    {text}
                </div>

                {
                    isInvertedQuestion(question)
                        ? <Toggle  showBothOptions positiveLabel="no" negativeLabel="yes"  choice={"NotSelected"} onClick={(evt: any) => onAnswerClick(parseInt(id), evt)} onStateChanged={() => null} />
                        :
                        <Toggle inverse  showBothOptions positiveLabel="no" negativeLabel="yes" choice={"NotSelected"} onClick={(evt: any) => onAnswerClick(parseInt(id), evt)} onStateChanged={() => null} />
                }

            </div>
            {answers[id] && question.follow_up_questions && question.follow_up_questions.map((followUpQ: Question) => {
                return renderQuestion(followUpQ, answers[followUpQ.id], true)

            })}

        </Fragment>
    }

    const validateAttestations = (): boolean => {
        // if you are on step 1, all questions must be answered
        let valid = true
        if (showStep1) {
            valid = Object.keys(answers).length === page1Questions.length
        }
        return valid
    }

    const hideModal = () => {
        setIsShowingConfirmationPopup(false);
    }

    const showModal = () => {
        if (!validateAttestations()) {
            setPage1Error(true)
            return
        }

        //@ts-ignore
        setIsShowingConfirmationPopup(true);
    }

    const submitAttestations = (callbackFn?: () => void) => {
        hideModal();
        setIsLoading(true);

        //convert web attestations to server format
        const answersServer: AnswerServer[] = Object.keys(answers).map((questionKey: string) => {
            return {
                id: questionKey,
                checked: answers[questionKey]
            }
        })

        dataController.postAttestations({
            answers: answersServer
        }, mainAppState!.submittingOnBehalfKerbId)
            .then((pr: PostAttestationsResponse) => {
                
                dataController.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId).then((statusResponse: FetchPassStatusResponse) => {
                    setIsLoading(false);
                    mainAppState!.updatePersonStatus(statusResponse)
                    mainAppState!.activeDrawer = ActiveDrawer.None;
                })
            })
    }

    const handleResetSymptoms = () => {
        setAnswers({})
        
        setPage1Questions([]);
    }

    const getModalText = () => {
        if (getHasSymptom(answers)) {
            if (answers[TESTED_KEY] && answers[CONTACT_KEY]) {
                return 'You will be submitting that you have been tested for COVID-19 and had a positive result, or have been told by a healthcare provider that you are likely positive for COVID-19, and that within the last 14 days, you have been in close contact with someone that you know had been diagnosed with COVID-19 or had experienced COVID-19 related symptoms.'
            }
            if (answers[TESTED_KEY]) {
                return `You will be submitting that you have been tested for COVID-19 and had a positive result, or have been told by a healthcare provider that you are likely positive for COVID-19.`
            }
            if (answers[CONTACT_KEY]) {
                return `You${answers[IS_MEDICAL_KEY] ? ' are a healthcare worker that' : ''} will be submitting that within the last 14 days, you have been in close contact with someone that you know had been diagnosed with COVID-19 or had experienced COVID-19 related symptoms.`
            }
            return "You will be submitting that you do exhibit symptoms related to COVID-19."
        } else {
            return "You will be submitting that you do not exhibit symptoms related to COVID-19."
        }
    }

    const showSubmitButton = (): boolean => {
        return showStep1 && getHasSymptom(answers)
    }

    const showContinueButton = (): boolean => {
        //continue button is visible if:
        //there is no symptom, and answer that no symptom has been given
        return showStep1 && !getHasSymptom(answers)
    }

    const continueToQuestion2 = () => {
        mainAppState!.stepperSelectedIndex = 1;

        if (!validateAttestations()) {
            setPage1Error(true)
            return
        } else {
            setPage1Error(false)
            setShowStep1(false)
            setShowStep2(true)
        }
    }

    const countVisibleQuestions = () => {
        let count = 0
        //count each question
        questions.forEach((q: Question) => {
            count++
            //count all follow up questions
            if (answers[q.id] && q.follow_up_questions && q.follow_up_questions.length > 0) {
                count = count + q.follow_up_questions.length
            }
        })
        return count
    }

    const submitAdditionalQuestions = () => {
        let allRequiredQuestionsAnswered = true
        let page2ErrorTextTmp = ''

        const answersLength = Object.keys(answers).length
        const visibleQ = countVisibleQuestions()
        if (visibleQ > answersLength) {
            allRequiredQuestionsAnswered = false
            page2ErrorTextTmp = 'Please answer all the questions and submit again.'
        }

        //make sure the ppe and contact question is answered YES
        questions.filter((q: Question) => (q.page === 2)).forEach((question: Question) => {
            if (question.required && question.style === 'good' && !answers[question.id]) {
                allRequiredQuestionsAnswered = false
            }
        })

        if (!allRequiredQuestionsAnswered) {
            if (page2ErrorTextTmp == '')
                page2ErrorTextTmp = 'In order to obtain campus access, you must agree to wear Personal Protective Equipment (PPE) and adhere to all rules and protocols of social distancing. Please review your answers.'
            setPage2ErrorText(page2ErrorTextTmp)
            setPage2Error(true)
        } else {
            setPage2Error(false)
            setPage2ErrorText('')
            submitAttestations()
        }
    }

    //Because our (HUI) Modal gets appended to the documentElement root, we need to rerender and update the class component in order to let our Modal re-append to the documentElement root with the latest info 
    const ModalPopup = withComponent(TemplateModal);

    return <>

        {pageError.error && <div>{pageError.errorText}</div>}
        <Loader loaded={!isLoading} options={mediumLoaderOptions}>
            {!pageError.error &&
                <div className={'question-container'}>
                    {showStep1 && <>

                        {mainAppState!.lastSubmission && <div className={'last-submission-banner'}>Your last submission was {displayDateTime(mainAppState!.lastSubmission)}</div>}
                        <div className={'symptoms-header'}>
                            <p className={'symptoms-header-question'}>Do you experience any of the following symptoms?</p>
                        </div>

                        {page1Questions.map((question: Question, index: number) => {
                            return <Fragment key={`main-q-${question.id}-${index}`}>
                                {renderQuestion(question, answers[question.id])}
                            </Fragment>
                        })}
                        <Spacer />
                    </>

                    }
                    {showStep2 && <div>
                        {questions.filter((q: Question) => q.page === 2).map((question: Question, index: number) => {
                            return <Fragment key={'follow-up-' + question.id + '-' + index}>
                                {renderQuestion(question, answers[question.id], question.style === 'good')}
                            </Fragment>
                        })}

                        <div className="text-center w-100">
                            <Spacer />
                            {page2Error && <Alert variant={'danger'} className={'smallMarginTop'}>{page2ErrorText}</Alert>}
                            <Spacer />
                            {<Button text={'Submit'} type={ButtonType.Secondary} onClick={submitAdditionalQuestions} />}
                        </div>
                    </div>}

                    {page1Error && <Alert variant={'danger'} className={'smallMarginTop'}>Please review your answers and try again.</Alert>}

                    <div className="text-center w-100 mt-3">
                   {showStep1 && <Button text={'Reset'} type={ButtonType.Secondary | ButtonType.Ghost} onClick={handleResetSymptoms} />} &nbsp;&nbsp;&nbsp;
                        {showSubmitButton() && <Button text={'Submit'} type={ButtonType.Secondary} onClick={showModal} />}

                        {showContinueButton() && <Button text={'Continue'} type={ButtonType.Secondary} onClick={continueToQuestion2} />}

                    
                    </div>
                    <Spacer size="5"/>
                </div>}
        </Loader>
        <ModalPopup
            body={
                <Loader loaded={true} options={{ ...mediumLoaderOptions, color: 'black' }}>
                    <Provider mainAppState={mainAppState} >
                        {getModalText()}
                    </Provider>
                </Loader>
            }
            bodyAlignment={TemplateModalAlignment.Left}
            show={isShowingConfirmationPopup}
            header={<Text content={"Confirmation"} type={TextType.Heading3} />}
            size={TemplateModalSize.Small}
            theme="medical-dark"
            imageUrl=""
            footer={
                <div>
                    <Button type={ButtonType.Secondary | ButtonType.Ghost} onClick={hideModal} text={"I made a mistake"} />&nbsp;
                    <Button type={ButtonType.Secondary} onClick={submitAttestations} text={"Yes, I'm sure"} />
                </div>
            }
            name="confirmationDialog"
        />
    </>
}

const QuestionsOldFlow = withRouter(inject('mainAppState')(observer(QuestionsComponent)))
export default QuestionsOldFlow