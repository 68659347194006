import { Question, TextItem } from "../types/Questions";
import { AttestationServer } from "../types/Attestation";
import BaseController from "./BaseController";
import { getResultText } from "../common/Formatters";
import { AuthProvider } from "@mit/aws-react";
import { PassHoldInfoModel, PassStatus, PassStatusModel, RequirementStatus } from "../types/CovidStatusModel";
import { CaseModel } from "../types/CaseModel";
import { CaseContactModel } from "../types/CaseContactModel";
import { VaccineStatusModel } from "../types/VaccineStatusModel";
import { VaccineSymptomModel } from "../types/VaccineSymptomModel";
import {
	FetchArrayResponse,
	FetchBlobResponse,
	FetchMedicalPlanningResponse,
	FetchMedicalQueueLengthResponse,
	FetchMedicalQueueResponse,
	FetchPassHoldInfoResponse,
	FetchPassStatusResponse,
	FetchStringResponse,
	FetchSurveyResponse,
	InfoOverview,
	PostAttestationsResponse,
	PostSurveyBody,
	PostWaiverResponse,
	ReportSymptoms,
	UnobservedCompleted,
	UserInfo,
	VaccineStatusResponse,
} from "types/Api";
class DataController extends BaseController {
	readonly url_attestations: string;
	readonly url_reset: string;
	readonly url_pass_status: string;
	readonly url_pass_hold_info: string;
	readonly url_pass_training: string;
	readonly url_array: string;
	readonly url_barcode: string;
	readonly url_waiver: string;
	readonly url_building_access: string;
	readonly url_fetch_results: string;
	readonly url_fetch_waiver: string;
	readonly url_fetch_waiver_student: string;
	readonly url_medical_planning: string;
	readonly url_medical_queue: string;
	readonly url_medical_queue_time: string;
	readonly url_tests_result_pdf: string;
	readonly url_pass_resident_confirmation: string;
	readonly url_contact_tracing: string;
	readonly url_vaccine: string;
	readonly url_vaccine_waiver: string;
	readonly url_vaccine_survey: string;
	readonly url_vaccine_answer: string;
	readonly url_not_eligible_reason: string;
	readonly url_tests_completed: string; //remove
	readonly url_unobserved_completed: string;
	readonly url_unobserved_status: string;
	readonly url_information_overview: string;
	readonly url_report_symptoms: string;
	readonly url_user_info: string;
	readonly url_test_manual_positive: string;
	readonly url_test_manual: string;
	readonly url_contact_tracing_close_contact: string;
	readonly authProvider: any;

	constructor() {
		super();

		this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=medical,vaccine`;
		// this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=medical,flu_shots,vaccine`;
		this.url_attestations = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/attestations`;
		this.url_reset = `${this.apiHost}/${this.apiPathCovid}/debug/reset_user`;
		this.url_pass_status = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/access_status`;
		this.url_pass_hold_info = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/hold_info`;
		this.url_pass_training = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/training_requirements`;
		this.url_array = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}`;
		this.url_barcode = `${this.apiHost}/${this.apiPathPass}/daily_pass`;
		this.url_waiver = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/waiver`;
		this.url_building_access = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/building_access`;
		this.url_fetch_waiver = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/waiver/text?layout=web`;
		this.url_fetch_waiver_student = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/waiver/student?layout=web`;
		this.url_medical_planning = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/medical/planning`;
		this.url_medical_queue = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/medical/queue_stats`;
		this.url_medical_queue_time = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/medical/queue_times`;
		this.url_tests_result_pdf = `${this.apiHost}/${this.apiPathMedical}/tests/result/pdf`;
		this.url_pass_resident_confirmation = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/resident_confirmation`;
		this.url_contact_tracing = `${this.apiHost}/${this.apiPathMedical}/contact_tracing`;
		this.url_vaccine = `${this.apiHost}/${this.apiPathVaccine}/vaccine`;
		this.url_vaccine_waiver = `${this.apiHost}/${this.apiPathVaccine}/vaccine/waiver`;
		this.url_vaccine_survey = `${this.apiHost}/${this.apiPathCovid}/survey`;
		this.url_vaccine_answer = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/questions_vaccine`;
		this.url_not_eligible_reason = `${this.apiHost}/${this.apiPathVaccine}/vaccine/prescreening/not_eligible_reason`;
		this.url_tests_completed = `${this.apiHost}/${this.apiPathMedical}/tests/complete`;
		this.url_unobserved_completed = `${this.apiHost}/${this.apiPathMedical}/unobserved/complete`;
		this.url_unobserved_status = `${this.apiHost}/${this.apiPathMedical}/unobserved/status`;
		this.url_information_overview = `${this.apiHost}/${this.apiPathPass}/pass/information/overview`;
		this.url_report_symptoms = `${this.apiHost}/${this.apiPathPass}/pass/report_symptoms`;
		this.url_user_info = `${this.apiHost}/${this.apiPathPass}/pass/user_info`;
		this.url_test_manual_positive = `${this.apiHost}/${this.apiPathMedical}/tests/manual_positive`;
		this.url_test_manual = `${this.apiHost}/${this.apiPathMedical}/tests/manual`;
		this.url_contact_tracing_close_contact = `${this.apiHost}/${this.apiPathMedical}/contact_tracing/close_contact`;

		this.authProvider = new AuthProvider(this.activeRegion);
	}

	fetchArray = async (endpoint: string): Promise<FetchArrayResponse> => {
		let token = await this.authProvider.getToken();
		const options: RequestInit = {
			method: "GET",
			headers: new Headers({
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			}),
		};

		let response = await fetch(`${this.url_array}/${endpoint}`, options);

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchBuildingAccess = async (kerbId?: string): Promise<FetchArrayResponse> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "GET",
			headers: new Headers(headerInit),
			mode: "cors",
		};

		let response = await fetch(`${this.url_building_access}`, options);

		if (response.status == 200) {
			let data = await response.json();
			return { items: data.map((d: any) => d.building_key) };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchWaiverText = async (kerbId?: string): Promise<FetchStringResponse> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "GET",
			headers: new Headers(headerInit),
			mode: "cors",
		};

		let response = await fetch(`${this.url_fetch_waiver}`, options);

		if (response.status == 200) {
			let data = await response.text();

			return {
				value: data,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchWaiverStudentText = async (signal?: any, kerbId?: string): Promise<FetchStringResponse> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		try {
			let response = await fetch(this.url_fetch_waiver_student, await this.requestHeaderGet(moreHeaders, signal));
			if (response.status == 200) {
				let data = await response.text();

				return {
					value: data,
				};
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
			return {
				error: true,
			};
		}
	};

	fetchPassStatus = async (signal?: any, kerbId?: string): Promise<FetchPassStatusResponse> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		try {
			let response = await fetch(this.url_pass_status, await this.requestHeaderGet(moreHeaders, signal));

			if (response.status == 200) {
				let data: PassStatusModel = await response.json();

				const resp: FetchPassStatusResponse = {
					status_model: {
						...data,
					},
				};
				return resp;
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
			return {
				error: true,
			};
		}
	};

	putPassStatus = async (status_id: number): Promise<FetchPassStatusResponse> => {
		try {
			let response = await fetch(this.url_pass_status, await this.requestHeaderPut({ status_id: status_id }));

			if (response.status == 200) {
				const resp: any = true;
				return resp;
			} else {
				let message = await response.json()
				return {
					error: message.message,
				};
			}
		} catch (e) {
			return {
				error: true,
			};
		}
	};

	fetchPassHoldInfo = async (signal?: any): Promise<FetchPassHoldInfoResponse> => {
		let moreHeaders: any = {};

		try {
			let response = await fetch(this.url_pass_hold_info, await this.requestHeaderGet(moreHeaders, signal));

			if (response.status == 200) {
				let data: PassHoldInfoModel = await response.json();

				const resp: FetchPassHoldInfoResponse = {
					status_model: {
						...data,
					},
				};
				return resp;
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
			return {
				error: true,
			};
		}
	};

	fetchPassTraining = async (signal?: any, kerbId?: string): Promise<any> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		let response = await fetch(this.url_pass_training, await this.requestHeaderGet(moreHeaders, signal));
		if (response.status == 200) {
			let data: PassStatusModel = await response.json();
			return data;
		} else {
			return Promise.reject()
		}
	};

	fetchVaccineStatus = async (signal?: any, kerbId?: string): Promise<VaccineStatusResponse> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		try {
			let response = await fetch(this.url_vaccine + "/status", await this.requestHeaderGet(moreHeaders, signal));

			if (response.ok) {
				let data: VaccineStatusModel = await response.json();

				const resp: VaccineStatusResponse = {
					status: {
						...data,
					},
				};
				return resp;
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
			return {
				error: true,
			};
		}
	};

	fetchContactTracingCases = async (signal?: any, kerbId?: string): Promise<any> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		let response = await fetch(this.url_contact_tracing, await this.requestHeaderGet(moreHeaders, signal));
		if (response.status == 200) {
			let data: CaseModel[] = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	fetchContactTracingContacts = async (case_guid: string, signal?: any, kerbId?: string): Promise<any> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact`, await this.requestHeaderGet(moreHeaders, signal));
		if (response.status == 200) {
			let data: CaseContactModel[] = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	postContactTracingSuggestion = async (case_guid: string, contact: CaseContactModel, kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(contact),
			mode: "cors",
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact_suggest`, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	postContactTracing = async (case_guid: string, contact: CaseContactModel, kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(contact),
			mode: "cors",
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact`, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	putContactTracing = async (case_guid: string, contact: CaseContactModel, kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "PUT",
			headers: new Headers(headerInit),
			body: JSON.stringify(contact),
			mode: "cors",
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact`, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	postVaccineSymptoms = async (symptoms: VaccineSymptomModel, kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(symptoms),
			mode: "cors",
		};

		let response = await fetch(this.url_vaccine + `/sideeffect`, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	postAttestations = async (attestation: AttestationServer, kerbId?: string): Promise<PostAttestationsResponse> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(attestation),
			mode: "cors",
		};

		let response = await fetch(this.url_attestations, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	async postVaccineAnswer(answer: any, kerbId?: string) {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(answer),
			mode: "cors",
		};

		let response = await fetch(this.url_vaccine_answer, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async postTestPlanning(reqBody: any): Promise<PostAttestationsResponse> {
		let response = await fetch(this.url_medical_planning, await this.requestHeaderPost(reqBody));
		if (this.checkStatusCode(response.status)) {
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	postReset = async (): Promise<PostAttestationsResponse> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			mode: "cors",
		};

		let response = await fetch(this.url_reset, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	postVaccineWaiver = async (agreed: boolean): Promise<PostWaiverResponse> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const answer: string = agreed ? "accept" : "decline";

		const body = {
			answer: answer,
		};

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: "cors",
		};

		let response = await fetch(this.url_vaccine_waiver, options);

		if (response.status >= 200 && response.status < 300) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	postWaiver = async (agreed: boolean, analyticsAgreed: boolean | undefined, kerbId?: string): Promise<PostWaiverResponse> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const answer: string = agreed ? "accept" : "decline";

		const body = {
			answer: answer,
			analytics_opt_out: analyticsAgreed,
		};
		if (typeof analyticsAgreed === "undefined") {
			delete body.analytics_opt_out;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: "cors",
		};

		let response = await fetch(this.url_waiver, options);

		if (response.status >= 200 && response.status < 300) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchBarcode = async (signal?: any, kerbId?: string): Promise<FetchStringResponse> => {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		let response = await fetch(`${this.url_barcode}`, await this.requestHeaderGet(moreHeaders, signal));
		if (response.status == 200) {
			let data = await response.blob();
			return {
				value: URL.createObjectURL(data),
			};
		} else {
			return {
				error: true,
			};
		}
	};

	async fetchTestResults(signal: AbortSignal): Promise<FetchArrayResponse> {
		let response = await fetch(`${this.url_fetch_results}`, await this.requestHeaderGet(undefined, signal));
		if (response.status >= 200 && response.status < 300) {
			let data = await response.json();

			return {
				items: data
					? data.map((item: any) => ({
							date: item.test_date,
							result: getResultText(item),
							rawResult: item.result ?? "Pending",
							guid: item.test_guid,
							can_download: item.can_download,
					  }))
					: undefined,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchMedicalPlanning(signal: AbortSignal): Promise<FetchMedicalPlanningResponse> {
		let response = await fetch(`${this.url_medical_planning}`, await this.requestHeaderGet(undefined, signal));
		if (this.checkStatusCode(response.status)) {
			return await response.json();
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchMedicalQueueStats(signal: AbortSignal): Promise<FetchMedicalQueueResponse> {
		let response = await fetch(`${this.url_medical_queue}`, await this.requestHeaderGet(undefined, signal));
		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchMedicalQueueLengths(signal: AbortSignal, number_of_days: number, medical_test_type?: number): Promise<FetchMedicalQueueLengthResponse> {
		let response = await fetch(
			`${this.url_medical_queue_time}?number_of_days=${number_of_days}${medical_test_type ? `&medical_test_type=${medical_test_type}` : ""}`,
			await this.requestHeaderGet(undefined, signal)
		);
		if (this.checkStatusCode(response.status)) {
			return { items: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchMedicalResultPDF(isFluShot: boolean, isVaccine: boolean, guid: string, signal?: any): Promise<FetchBlobResponse> {
		let moreHeaders: any = {};
		let url = `${this.url_tests_result_pdf}`;

		if (isFluShot) {
			url += "?flushot=1&test_guid=" + guid;
		} else if (isVaccine) {
			url += "?vaccine=1&test_guid=" + guid;
		} else {
			url += "?test_guid=" + guid;
		}

		let response = await fetch(url, await this.requestHeaderGet(moreHeaders, signal));
		if (response.status == 200) {
			let data = await response.blob();
			return {
				value: data,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchFlushotResultPDF(signal?: any, kerbId?: string): Promise<FetchBlobResponse> {
		let moreHeaders: any = {};
		if (kerbId && kerbId !== "") {
			moreHeaders["X-Impersonated-User"] = kerbId;
		}

		let response = await fetch(`${this.url_tests_result_pdf}`, await this.requestHeaderGet(moreHeaders, signal));
		if (response.status == 200) {
			let data = await response.blob();
			return {
				value: data,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchResidentialConfirmation(signal: AbortSignal, isResiding: boolean) {
		let url = `${this.url_pass_resident_confirmation}` + "?residing=";

		if (isResiding) {
			url += "1";
		} else {
			url += 0;
		}

		let response = await fetch(url, await this.requestHeaderGet(undefined, signal));

		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchVaccineSurvey(signal: AbortSignal): Promise<FetchSurveyResponse> {
		let url = `${this.url_vaccine_survey}`;

		let response = await fetch(url, await this.requestHeaderGet(undefined, signal));

		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async postVaccineSurvey(body: PostSurveyBody): Promise<any> {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: "cors",
		};

		let response = await fetch(this.url_vaccine_survey, options);

		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchNotEligibleReasons(signal?: AbortSignal): Promise<any> {
		let response = await fetch(`${this.url_not_eligible_reason}`, await this.requestHeaderGet(undefined, signal));
		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async getUnobservedStatus(signal?: AbortSignal): Promise<any> {
		let response = await fetch(`${this.url_unobserved_status}`, await this.requestHeaderGet(undefined, signal));
		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async postUnobservedCompleted(body: UnobservedCompleted): Promise<any> {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: "cors",
		};

		let response = await fetch(this.url_unobserved_completed, options);
		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			const text = await response.text();
			return await Promise.reject(text);
			// return {
			// 	error: true,
			// 	message: text
			// };
		}
	}

	async deleteUnobservedCompleted(): Promise<any> {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "DELETE",
			headers: new Headers(headerInit),
			mode: "cors",
		};

		let response = await fetch(this.url_unobserved_completed, options);
		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	}

	informationOverview = async (): Promise<InfoOverview[]> => {
		let token = await this.authProvider.getToken();
		const options: RequestInit = {
			method: "GET",
			headers: new Headers({
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			}),
		};

		let response = await fetch(`${this.url_information_overview}`, options);

		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	};

	async postReportSymptoms(body: ReportSymptoms): Promise<any> {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: "cors",
		};

		let response = await fetch(this.url_report_symptoms, options);
		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	}

	async postUserInfo(body: UserInfo) {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: "cors",
		};

		let response = await fetch(this.url_user_info, options);

		if (response.status == 200) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}
	async getUserPhone(): Promise<any> {
		const token = await this.authProvider.getToken();
		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "GET",
			headers: new Headers(headerInit),
			// body: JSON.stringify(phoneNumber),
			mode: "cors",
		};

		const response = await fetch(`${this.url_user_info}/phone`, options);
		if (response.ok) {
			return { data: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async patchUserPhone(phoneNumber: { phoneNumber: string }) {
		// console.log(JSON.stringify({phoneNumber}))
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "PATCH",
			headers: new Headers(headerInit),
			body: JSON.stringify(phoneNumber),
			mode: "cors",
		};

		let response = await fetch(`${this.url_user_info}/phone`, options);
		// console.log(response.ok)
		if (response.ok) {
			return { data: "sucess" };
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	}

	postTestManualPositive = async (date: string, test_type_id: number , kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify({
				date: date,
				test_type_id: test_type_id
			}),
			mode: "cors",
		};

		let response = await fetch(this.url_test_manual_positive, options);

		if (response.ok) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			const text = await response.json();
			return await Promise.reject(text?.message ?? 'Something went wrong');
		}
	};

	postTestManual = async (date: string, test_type_id: number, is_positive: boolean, kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify({
				date: date,
				test_type_id: test_type_id,
				is_positive: is_positive
			}),
			mode: "cors",
		};

		let response = await fetch(this.url_test_manual, options);

		if (response.ok) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			const text = await response.json();
			return await Promise.reject(text?.message ?? 'Something went wrong');
		}
	};

	postContactTracingCloseContact = async (date: string, kerbId?: string): Promise<any> => {
		let token = await this.authProvider.getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};

		if (kerbId && kerbId != "") {
			headerInit["X-Impersonated-User"] = kerbId;
		}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify({
				date: date
			}),
			mode: "cors",
		};

		let response = await fetch(this.url_contact_tracing_close_contact, options);

		if (response.ok) {
			let data = await response.json();
			return {
				success: true,
			};
		} else {
			const text = await response.json();
			return await Promise.reject(text?.message ?? 'Something went wrong');
		}
	};
}

const dataController = new DataController();
export default dataController;
