import  moment from 'moment';

export const simpleToday = (): string => {
    let displayDate = moment().format('MMM D')
    return `${displayDate}`
}

export const mitFormatToday = () : string => {
    return moment().format('MM/DD/YYYY')
}

export const ordinalsDate = (date: moment.Moment | string): string => {
    return date ? moment(date).format('MMMM Do') : ''
}

export const simpleDate = (d: string) : string => {
    return 'May 21'
    //return moment(d, 'MM/DD/YYYY').format('MMM D')
}

export const toDate = (dateTime: string): moment.Moment => {
    return moment(dateTime, 'YYYY-MM-DD H:mm:ss')
}

export const displayDateTime = (date?: moment.Moment | string): string => {

    if(!date)
    return ""

    if(typeof date === 'string'){
        date = moment(date)
    }

    let displayDate = moment(date).format('LLL')
    return `${displayDate}`
}

export const displayDateAbbrMonth = (date: moment.Moment | string): string => {

    if(typeof date === 'string'){
        date = moment(date)
    }

    let displayDate = moment(date).format('ll')
    return `${displayDate}`
}

export const toLocalTimeFromUct = (utcTime: string): string => {
    var date = moment.utc(utcTime).format();
    let local: moment.Moment = moment.utc(date).local();
    return local.format()
}

export const toUtc = (localTime: string) : string => {
    return moment.utc(moment(localTime)).format();
}

export const toUtcDate = (localTime: moment.Moment): string => {
    return moment.utc(localTime).format()
}

export const formatPhoneNumber = (number:string): string => {
    let cleaned = ('' + number).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return number
}

export const addHours = (inDate: string, hours: number): moment.Moment => {
    let date: moment.Moment = toDate(inDate).add(hours, 'hours')
    return date
}

export const displayDate = (date: moment.Moment | string): string => {

    if(typeof date === 'string'){
        date = moment(date)
    }

    let displayDate = moment(date).format('MM/DD/YYYY')
    return `${displayDate}`
}

export const getResultText = (item: any) => {
    switch (item.result) {
        case 'P': return 'Positive'
        case 'N': return 'Negative'
        case 'I': return 'Invalid'
        case 'FLU_SHOT': return 'Flu Shot'
        case 'VACCINE_SHOT': return item.test_company
        case 'VACCINE_COURSE_COMPLETED': return item.test_company
    }

    return 'Pending'
}

export const formatDate = (date: string | undefined) => {
  if (date) {
    return moment(date)
      .utc()
      .format('LL')
    // return moment(date).format('MMM DD, YYYY')
  }
  return ''
}
