import * as React from "react";
import { mainAppState } from "../model/MainAppStateStore";
import { FC } from "react";
import { inject, observer } from "mobx-react";

export enum COVID_TEXTS {
    "covid19.pass.medical.not_required" = "covid19.pass.medical.not_required",
    "covid19.medical.flushot.incomplete.title" = "covid19.medical.flushot.incomplete.title",
    "covid19.medical.flushot.incomplete.text" = "covid19.medical.flushot.incomplete.text",
    "covid19.pass.medical.flushot.text" = "covid19.pass.medical.flushot.text",
    "covid19.medical.flushot.url" = "covid19.medical.flushot.url",
    "covid19.medical.flushot.action1" = "covid19.medical.flushot.action1",
    "covid19.medical.flushot.action2" = "covid19.medical.flushot.action2",
    "covid19.pass.action.medical" = "covid19.pass.action.medical",
    "covid19.pass.action.vaccine" = "covid19.pass.action.vaccine",
    "covid19.pass.flushot.actionbox" = "covid19.pass.flushot.actionbox",
    "covid19.noaccess.text" = "covid19.noaccess.text",
    "covid19.pass.medical.complete" = "covid19.pass.medical.complete",
    "covid19.pass.medical.incomplete" = "covid19.pass.medical.incomplete",
    "covid19.pass.building_access" = "covid19.pass.building_access",
    "covid19.pass.test.testComplete" = "covid19.pass.test.testComplete",
    "covid19.pass.link.appstore" = "covid19.pass.link.appstore",
    "covid19.pass.link.googleplay" = "covid19.pass.link.googleplay",
    "covid19.pass.medical.continue.training" = "covid19.pass.medical.continue.training",
    "covid19.pass.medical.continue.attestation" = "covid19.pass.medical.continue.attestation",
    "covid19.pass.analytics_question_on_behalf" = "covid19.pass.analytics_question_on_behalf",
    "covid19.pass.pdf.buildings.url" = "covid19.pass.pdf.buildings.url",
    "covid19.pass.pdf.buildings.text" = "covid19.pass.pdf.buildings.text",
    "covid19.pass.pdf.access.url" = "covid19.pass.pdf.access.url",
    "covid19.pass.campusDate" = "covid19.pass.campusDate",
    "covid19.pass.attestation.completed_text" = "covid19.pass.attestation.completed_text",
    "covidpass.questions.title" = "covidpass.questions.title",
    "covid19.training.web.completed" = "covid19.training.web.completed",
    "covid19.medical.completed.text" = "covid19.medical.completed.text",
    "covid19.pass.training.continue.attestation" = "covid19.pass.training.continue.attestation",
    "covid19.notraining.text.medical" = "covid19.notraining.text.medical",
    "covid19.pass.training.continue.medical" = "covid19.pass.training.continue.medical",
    "covid19.training.web.required" = "covid19.training.web.required",
    "covid19.pass.training.not_required" = "covid19.pass.training.not_required",
    "covid19.pass.analytics_descr" = "covid19.pass.analytics_descr",
    "covid19.pass.analytics_question" = "covid19.pass.analytics_question",
    "covid19.pass.no_buildings" = "covid19.pass.no_buildings",
    "covid19.pass.medical.overdue_grace.heading" = "covid19.pass.medical.overdue_grace.heading",
    "covid19.pass.medical.overdue_grace.detail" = "covid19.pass.medical.overdue_grace.detail",
    "covid19.pass.medical.due_soon.heading" = "covid19.pass.medical.due_soon.heading",
    "covid19.pass.medical.due_soon.detail" = "covid19.pass.medical.due_soon.detail",
    "covid19.pass.help_email" = "covid19.pass.help_email",
    "covid19.pass.medical.overdue_grace.notification" = "covid19.pass.medical.overdue_grace.notification",
    "covid19.pass.medical.due_soon.notification" = "covid19.pass.medical.due_soon.notification",
    "covid19.pass.medical.notification_button" = "covid19.pass.medical.notification_button",
    "covid19.pass.has_symptoms" = "covid19.pass.has_symptoms",
    "covid19.pass.disqualified" = "covid19.pass.disqualified",
    "covid19.pass.medical_overdue" = "covid19.pass.medical_overdue",
    "covid19.pass.must_test" = "covid19.pass.must_test",
    "covid19.queue.label" = "covid19.queue.label",
    "covid19.queue.sub_label" = "covid19.queue.sub_label",
    "covid19.pass.curent_q_lengths" = "covid19.pass.curent_q_lengths",
    "covid19.medical.flushot.text" = "covid19.medical.flushot.text",
    "covid19.onboard.alreadysigned.text" = "covid19.onboard.alreadysigned.text",
    "covid19.onboard.alreadysigned.action" = "covid19.onboard.alreadysigned.action",
    "covid19.medical.hold.awaiting_test_outcome.title" = "covid19.medical.hold.awaiting_test_outcome.title",
    "covid19.medical.hold.awaiting_test_outcome.text" = "covid19.medical.hold.awaiting_test_outcome.text",
    "covid19.medical.hold.expiry" = "covid19.medical.hold.expiry",
    "covid19.medical.hold.residential.title" = "covid19.medical.hold.residential.title",
    "covid19.medical.hold.residential.text" = "covid19.medical.hold.residential.text",
    "covid19.pass.action.medical.overdue" = "covid19.pass.action.medical.overdue",
    "covid19.noaccess.test.web" = "covid19.noaccess.test.web",

    /* Incomplete */
    "covid19.pass.noaccess.sub" = "covid19.pass.noaccess.sub",
    "covid19.pass.noaccess.actionbox" = "covid19.pass.noaccess.actionbox",
    /* No access */
    "covid19.noaccess.title" = "covid19.noaccess.title",
    "covid19.noaccess.text.web" = "covid19.noaccess.text.web",
    "covid19.noaccess.instructions" = "covid19.noaccess.instructions",
    /* Medical Positive */
    "covid19.notsafe.results.title" = "covid19.notsafe.results.title",
    "covid19.notsafe.results.subtitle" = "covid19.notsafe.results.subtitle",
    /* Medical Symptoms */
    "covid19.notsafe.title" = "covid19.notsafe.title",
    "covid19.notsafe.subtitle" = "covid19.notsafe.subtitle",
    /* Medical Hold */
    "covid19.medical.hold.title" = "covid19.medical.hold.title",
    /* Waiver Declined */
    "covid19.noagreement.title" = "covid19.noagreement.title",
    "covid19.noagreement.action" = "covid19.noagreement.action",

    "covid19.pass.sub" = "covid19.pass.sub",
    "covid19.pass.info1" = "covid19.pass.info1",

    "covid19.dashboard.incomplete.attestation.action" = "covid19.dashboard.incomplete.attestation.action",
    "covid19.dashboard.incomplete.training.action" = "covid19.dashboard.incomplete.training.action",
    "covid19.dashboard.incomplete.medical.action" = "covid19.dashboard.incomplete.medical.action",
    "covid19.dashboard.incomplete.waiver.action" = "covid19.dashboard.incomplete.waiver.action",

    "covid19.resident.confirm.title" = "covid19.resident.confirm.title",
    "covid19.resident.confirm.body" = "covid19.resident.confirm.body",
    "covid19.resident.confirm.actionbar" = "covid19.resident.confirm.actionbar",
    "covid19.resident.confirm.action.no" = "covid19.resident.confirm.action.no",
    "covid19.resident.confirm.action.yes" = "covid19.resident.confirm.action.yes",

    //Travel hold
    "covid19.travel.hold.title" = "covid19.travel.hold.title",
    "covid19.travel.hold.text" = "covid19.travel.hold.text",
    "covid19.travel.hold.action" = "covid19.travel.hold.action",

    //Pin
    "pin.title" = "pin.title",
    "pin.action.create" = "pin.title",
    "pin.action.reset" = "pin.title",
    "pin.text" = "pin.title",

    //Testing
    "covid19.noaccess.wtesting.title" = "covid19.noaccess.wtesting.title",
    "covid19.noaccess.wtesting.text" = "covid19.noaccess.wtesting.text",
    "covid19.noaccess.wtesting.waiver" = "covid19.noaccess.wtesting.waiver",

    //Contact tracing
    "covid19.contactt.title" = "covid19.contactt.title",
    "covid19.contactt.info" = "covid19.contactt.info",
    "covid19.contactt.action" = "covid19.contactt.action",
    "covid19.contactt.medicaltitle" = "covid19.contactt.medicaltitle",
    "covid19.contactt.fullname" = "covid19.contactt.fullname",
    "covid19.contactt.phone" = "covid19.contactt.phone",
    "covid19.contactt.email" = "covid19.contactt.email",
    "covid19.contactt.date" = "covid19.contactt.date",

    //Vaccine
    "covid19.medical.vaccine.title" = "covid19.medical.vaccine.title",
    "covid19.medical.vaccine.action" = "covid19.medical.vaccine.action",
    "covid19.medical.vaccine.complete.badge" = "covid19.medical.vaccine.complete.badge",

    "covid19.medical.header.menu.covid" = "covid19.medical.header.menu.covid",
    "covid19.medical.header.menu.flushot" = "covid19.medical.header.menu.flushot",
    "covid19.medical.header.menu.vaccine" = "covid19.medical.header.menu.vaccine",

    //Vaccine Eligible Page:
    "covid19.vaccine.eligible.title" = "covid19.vaccine.eligible.title",
    "covid19.vaccine.eligible.text" = "covid19.vaccine.eligible.text",

    //Vaccine InProgressPage:
    "covid19.vaccine.inprogress.title" = "covid19.vaccine.inprogress.title",
    "covid19.vaccine.inprogress.text" = "covid19.vaccine.inprogress.text",

    //Vaccine Completed Page
    "covid19.vaccine.completed.title" = "covid19.vaccine.completed.title",
    "covid19.vaccine.completed.text" = "covid19.vaccine.completed.text",

    "covid19.vaccine.completed.linktext" = "covid19.vaccine.completed.linktext",
    "covid19.vaccine.completed.action" = "covid19.vaccine.completed.action",

    'covid19.pass.medical.booster.text' = 'covid19.pass.medical.booster.text',
    'covid19.pass.medical.booster.title' = 'covid19.pass.medical.booster.title'
}

interface CovidTextProps {
    textId: COVID_TEXTS | string;
    className?: string;
}

const CovidTextComponent: FC<CovidTextProps> = ({ textId, className }) => {
    return <p dangerouslySetInnerHTML={{ __html: mainAppState.texts[textId] }} className={className ? className : ""} />
}

const CovidText = inject("mainAppState")(observer(CovidTextComponent))
export default CovidText
