import { EventBus } from '@mit/aws-react'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const initializeApp = async (): Promise<void> => {
    // Simulate api call. Don't use timeout

    // You can access easy peasy state in this hook

    setTimeout(() => {
      EventBus.dispatch('APP_INITIALIZED', {
        isLoading: false,
        hasAccess: true
      })
    }, 1000)
  }

  return { initializeApp }
}
