import * as React from "react";
import { FC, useEffect } from "react";
import { mainAppState, MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { withRouter } from "react-router";
import { useState } from "react";
import dataController from "../../api/DataController";
import { PageError } from "../../types/PageErrors";
import { ActionList, Button, Spacer, Text, TextType } from "@mit/hui";
import { FetchSurvey, PostSurveyBody, FetchSurveyQuestions, FetchSurveyQuestionsOptions } from "types/Api";
export interface VaccineSurveyProps {
	mainAppState?: MainAppStateInterface;
	history?: any;
}

const VaccineSurveyComponent: FC<VaccineSurveyProps> = ({ mainAppState }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [questions, setQuestions] = useState<FetchSurvey[]>([]);
	const [questionsResult, setQuestionsResult] = useState<PostSurveyBody[]>([{survey_guid: "", results: []}]);
	const [questionPage, setQuestionsPage] = useState<number>(1);
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: "", showRetry: false });

	useEffect(() => {
		const abortController = new AbortController();
		dataController.fetchVaccineSurvey(abortController.signal).then((response) => {
			if (response.data) {
				//Create result model
				response.data.map((itm, index) => {
					let questionsResultCopy = [...questionsResult];

					questionsResultCopy[index].survey_guid = itm.survey_guid;
					questionsResultCopy[index].results = itm.questions.map((q) => {
						return {
							option_id: 0,
							question_id: q.id,
						};
					});

					setQuestionsResult(questionsResultCopy);
				});

				setQuestions(response.data);
			} else {
				setPageError({ error: true, errorText: "An error occurred loading initial questions.", showRetry: false });
			}

			setIsLoading(false);
		});
	}, []);

	const submitSurvey = (survey_guid: string) => {

        const index = questionsResult.findIndex((x) => x.survey_guid === survey_guid);

        let body: PostSurveyBody = {
            survey_guid: survey_guid,
            results: questionsResult[index].results
        }

		dataController.postVaccineSurvey(body).then((response) => {
			mainAppState!.activeDrawer = ActiveDrawer.None;
		});
	};

	if (isLoading) return <ActionList isLoading items={[]} />;

	const renderQuestionsPage = (questions: FetchSurvey) => {
		return (
			<>
				{questions.questions.map((question) => {
					return renderQuestions(question, questions.survey_guid, question.id);
				})}
				<Button theme={"medical"} text="Submit" onClick={() => submitSurvey(questions.survey_guid)} />
			</>
		);
	};

	const renderQuestions = (question: FetchSurveyQuestions, survey_guid: string, question_id: number) => {
		const clickAnwser = (answer: FetchSurveyQuestionsOptions) => {
            let questionsResultCopy = questionsResult;
            let questionsResultCopyIndex = questionsResult.findIndex((x) => x.survey_guid === survey_guid);
			let resultIndex = questionsResult[questionsResultCopyIndex].results.findIndex((x) => x.question_id === question_id);
			questionsResultCopy[questionsResultCopyIndex].results[resultIndex] = { option_id: answer.id, question_id: question_id };

			setQuestionsResult(questionsResultCopy);
		};

		//Check question page
		if (question.page === questionPage) {
			const optionsData = question.options.map((itm) => {
				return {
					text: itm.text,
					icon: "circle",
					onClick: () => clickAnwser(itm),
				};
			});

			return (
				<>
					<Spacer />
					<Text content={question.text} type={TextType.Heading5} />
					<Spacer />
					<div className="survey">
					<ActionList compact items={optionsData} />
					</div>
					<Spacer />
				</>
			);
		}

		return <></>;
	};

	return (
		<>
			{questions.map((question) => {
				return renderQuestionsPage(question);
			})}
		</>
	);
};

const VaccineSurvey = withRouter(inject("mainAppState")(observer(VaccineSurveyComponent)));
export default VaccineSurvey;
