import * as React from 'react'
import { render } from 'react-dom'
import { FC } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Initialization, Logout } from '@mit/aws-react'
import Home from './home';
import { Helmet } from "react-helmet";
import './scss/_unobserved.scss'
import appConfig from 'app-config'
import {useAppInitializer} from './hooks/Initializer'
// import { App } from 'app';

// const InitialApp: FC = () =>
// {

//     return <Router>
//         <React.Fragment>
//             <Helmet>
//                 <html lang="en" ></html>
//             </Helmet>
//             <Home/>
//         </React.Fragment>
//     </Router>;
// }

const WrappedApp: FC = () => {
    const { initializeApp } = useAppInitializer()
  
    return (
      <>
         <Helmet>
                <html lang="en" ></html>
            </Helmet>
        <Router>
          {/* <React.StrictMode> */}
            <Switch>
              <Route exact path='/logout' component={Logout} />
              <Route path='/'>
                <Initialization
                  App={Home as any}
                  appInitializer={initializeApp}
                  appConfig={appConfig}
                  useCognitoAuthentication
                  loaderProps={{
                    contactEmail: 'covidapps-help@mit.edu',
                    name: 'COVID Pass',
                    exit: false,
                    theme: 'medical'
                  }}
                />
              </Route>
            </Switch>
          {/* </React.StrictMode> */}
        </Router>
      </>
    )
  }



render(<WrappedApp/>, document.getElementById('root'))
