import * as React from "react";
import { inject, observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { ActiveDrawer, MainAppStateInterface } from "../../model/MainAppStateStore";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import {
	Text,
	Drawer,
	DrawerSize,
	DrawerType,
	DrawerLayout,
	Icon,
	Form,
	FormContainer,
	FormField,
	Button,
	TemplateModal,
	TemplateModalAlignment,
	ButtonState,
	Container,
	Spacer,
	AlertProps,
	Alert,
	TemplateModalSize,
	Textbox,
} from "@mit/hui";
import moment from "moment";
import dataController from "api/DataController";
import ReactMarkdown from "react-markdown";

interface ReportPositiveTestProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	show: boolean;
	onClose: () => void;
}

const ReportCovidSymptomsComponent: FC<ReportPositiveTestProps> = ({ mainAppState, userProfileStoreState, show, onClose }) => {
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<AlertProps>({ text: "" });
	const [phoneNr, setPhoneNr] = useState("(000)-000-0000");
	const [showConfirmPhone, setShowConfirmPhone] = useState<boolean>(false);
	const [isConfirming, setConfirming] = useState<boolean>(false);
	const [showUpdatePhone, setShowUpdatePhone] = useState<boolean>(false);

	const getLatestPhoneNr = () => {
		setIsBusy(true);
		dataController
			.getUserPhone()
			.then((res) => {
				const nr = res.data.phoneNumber;
				setPhoneNr(nr ? `(${nr?.slice(0, 3)})-${nr?.slice(3, 6)}-${nr?.slice(6)}` : nr);
				setShowConfirmPhone(true);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setConfirming(false);
				setIsBusy(false);
				onClose();
			});
	};

	const confirmedPhoneNr = (confirmed: boolean): void => {
		if (confirmed) {
			setConfirming(true)
			reportSymptoms();
		} else {
			setShowConfirmPhone(false);
			setShowUpdatePhone(true);
			setConfirming(false);
		}
	};

	const reportSymptoms = (): void => {
		dataController
			.postReportSymptoms({ has_symptoms: true })
			.then(() => {
				mainAppState!.reload = true;
				mainAppState!.activeDrawer = ActiveDrawer.None;			
				// setShowConfirmSymptoms(false)
				setShowUpdatePhone(false);				
			})
			.catch((err) => console.error(err))
			.finally(() => {
				setShowConfirmPhone(false)
				onClose();
				setConfirming(false);
			});
	};

	const updatePhoneNr = (phoneNr: string): void => {
		const digitsOnlyNr = phoneNr.replace(/\D/g, "");
		// if (digitsOnlyNr.length !== 10) {
		//   setIsNrLength10(false);
		// }
		const dataObj = {
			phoneNumber: digitsOnlyNr,
		};
		dataController
			.patchUserPhone(dataObj)
			.then((resp) => {
				console.log(resp.data);
				reportSymptoms();
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setShowUpdatePhone(false);
				setConfirming(false);
			});
	};

	return (
		<>
			{/* Report Symptoms Modal */}
			{show && (
				<TemplateModal
					show={show}
					onClose={() => onClose()}
					theme="medical"
					body={
						<div className="m-3">
							<Text content={mainAppState!.texts["covid19.notify_mit.report_symptoms.text"] ?? "Reporting symptoms will put you on a Medical hold."} />
						</div>
					}
					bodyAlignment={TemplateModalAlignment.Left}
					footer={
						<div className="d-flex justify-content-center">
							<div className="btn-padded">
								<Button text={mainAppState!.texts["covid19.notify_mit.report_symptoms.cancel"] ?? "I don't have symptoms"} outlined onClick={() => onClose()} />
								<Button
									isBusy={isBusy}
									state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
									text={mainAppState!.texts["covid19.notify_mit.report_symptoms.confirm"] ?? "I have COVID symptoms"}
									overwriteColor="danger"
									onClick={() => {
										setConfirming(true);
										getLatestPhoneNr();
									}}
								/>
							</div>
						</div>
					}
					header={<Text content={mainAppState!.texts["covid19.notify_mit.report_symptoms.title"] ?? "Report Symptoms"} type={"h3"} />}
					name="reportSymptomsModal"
				/>
			)}
			{/* Confirm phone number modal */}
			{showConfirmPhone && (
				<TemplateModal
					// body={<Text content={mainAppState!.texts["covid19.pass.report_symptoms.text"]} properties={{role: 'main'}} />}
					body={
						<>
							<Text
								content={
									mainAppState!.texts["covid19.pass.phone_popup.info"]
										? `${mainAppState!.texts["covid19.pass.phone_popup.info"]}${phoneNr}`
										: `MIT Medical may need to speak with you about your symptoms. Please make sure this phone number is correct or update it: ${phoneNr}`
								}
								properties={{ role: "main" }}
							/>
						</>
					}
					bodyAlignment={TemplateModalAlignment.Left}
					show={showConfirmPhone}
					onClose={() => setShowConfirmPhone(false)}
					header={<Text content={mainAppState!.texts["covid19.pass.phone_popup.text"] ?? "Is this the best phone number to contact you?"} type={"h3"} />}
					size={TemplateModalSize.Default}
					theme="medical"
					imageUrl=""
					footer={
						<div>
							<Button
								type={"secondary"}
								outlined
								onClick={() => {
									setConfirming(true);
									confirmedPhoneNr(false);
								}}
								text={"Update the number"}
								state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled}
								isBusy={isConfirming}
							/>
							&nbsp;
							<Button
								type={"secondary"}
								isBusy={isConfirming}
								onClick={() => confirmedPhoneNr(true)}
								text={"The number is correct"}
								state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled}
							/>
						</div>
					}
					name="confirmPhoneDialog"
				/>
			)}
			{/* Update phone number modal */}
			{showUpdatePhone && (
				<TemplateModal
					body={
						<>
							<Text content={mainAppState!.texts["covid19.medical.update_phone.text"]} properties={{ role: "main" }} />
							<div style={{ marginTop: 20 }}>
								<Textbox
									compact
									endIconRequired={false}
									masked={"tel"}
									max={10}
									maxLength={500}
									min={10}
									minLength={0}
									name="phoneValidated"
									// pattern={{
									//   message: 'Phone format',
									//   value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
									// }}
									placeholderText="Your current phone number"
									properties={{
										"aria-label": "phone number",
									}}
									value={phoneNr}
									onChange={(e: React.BaseSyntheticEvent) => setPhoneNr(e.target.value)}
									required
									type={4}
									width={100}
								/>
							</div>
						</>
					}
					bodyAlignment={TemplateModalAlignment.Left}
					show={showUpdatePhone}
					onClose={() => setShowUpdatePhone(false)}
					header={<Text content={mainAppState!.texts["covid19.medical.update_phone.title"]} type={"h3"} />}
					size={TemplateModalSize.Default}
					theme="medical"
					imageUrl=""
					footer={
						<div>
							<Button
								type={"secondary"}
								onClick={() => {
									setConfirming(true);
									updatePhoneNr(phoneNr);
								}}
								text={mainAppState!.texts["covid19.button.ok"]}
								state={phoneNr.replace(/\D/g, "").length === 10 ? ButtonState.Enabled : ButtonState.Disabled}
								isBusy={isConfirming}
							/>
							&nbsp;
							{/* <Button type={'secondary'} outlined onClick={() => confirmedPhoneNr(true)} text={"The number is correct"} state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled} /> */}
						</div>
					}
					name="updatePhoneDialog"
				/>
			)}
		</>
	);
};

const ReportCovidSymptomsModal = inject("mainAppState", "userProfileStoreState")(observer(ReportCovidSymptomsComponent));
export default ReportCovidSymptomsModal;
