import * as React from 'react'
import {FC} from "react";
import QuestionsOldFlow from "../QuestionsOldFlow";
import QuestionsNewFlow from "../QuestionsNewFlow";
import {useState} from "react";
import {inject, observer} from "mobx-react";
import {MainAppStateInterface} from "../../../model/MainAppStateStore";
import ErrorMessage from "../../../common/ErrorMessage";
import OnBehalfHeader from "../../onBehalf/OnBehalfHeader";
import {COVID_TEXTS} from "../../../common/Texts";
import {ShortQuestions} from "../ShortQuestions";

interface LandingMustSubmitProps {
    texts?: any;
    mainAppState?: MainAppStateInterface;
}

const LandingMustSubmitComponent: FC<LandingMustSubmitProps> = ({texts, mainAppState}) => {
    const [submitResult, setSubmitResult] = useState<boolean | undefined>(undefined)
    
    const hasSubmitted = (): boolean => {
        return typeof submitResult !== 'undefined'
    }

    const showSubmitErrorMessage = (): boolean => {
        return hasSubmitted() && submitResult === false
    }

    return <div className={'survey-container'}>
        {showSubmitErrorMessage() && <ErrorMessage text={`Your submission could not be recorded at this time. Please try again or contact ${mainAppState!.texts[COVID_TEXTS['covid19.pass.help_email']]} for assistance.`}/>}

        {!hasSubmitted() && <>
         
            <OnBehalfHeader />
            {mainAppState!.features['covid19.attestation.v2.enabled'] 
                ? <QuestionsNewFlow />
                : <QuestionsOldFlow />
            }            
        </>}
    </div>
}

const LandingMustSubmit = inject("mainAppState")(observer(LandingMustSubmitComponent))
export default LandingMustSubmit
