import * as React from "react";
import { FC, useState, useEffect } from "react";
import { mainAppState, MainAppStateInterface, CovidTextType, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { toJS } from "mobx";
import { RequirementIds, RequirementStatus, PassStatus, HoldReason } from "../../types/CovidStatusModel";
import { Container, Text, Spacer, Button, ButtonType, TextType, TemplateTwoColumns } from "@mit/hui";
import PersonTests from "../medical-test/PersonTests";
import Barcode from "../medical-test/Barcode";
import { CaseModel } from "../../types/CaseModel";
import Vaccine from "./Vaccine";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";

interface MedicalProps {
    mainAppState?: MainAppStateInterface;
    userProfileStoreState?: UserProfileStoreInterface;
}

const MedicalComponent: FC<MedicalProps> = ({ mainAppState, userProfileStoreState }) => {
    useEffect(() => {
        console.log(toJS(mainAppState!.texts));
    }, []);

    const medicalPositive = mainAppState!.status_model.status === PassStatus.medical_positive;
    const medicalSymptoms = mainAppState!.status_model.status === PassStatus.medical_symptoms;
    const medicalResidential = mainAppState!.status_model.status === PassStatus.medical_symptoms && mainAppState!.status_model.hold_reason == HoldReason.residential;
    const medicalHold = mainAppState!.status_model.status === PassStatus.medical_block;

    const renderMedical = () => {
        return (
            <>
                {medicalPositive && (
                    <>
                        <Text content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.results.title"]]} type={TextType.Heading1} />

                        <Spacer />

                        <Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.results.subtitle"]]} />
                    </>
                )}

                {medicalResidential && (
                    <>
                        <Text content={mainAppState!.texts[COVID_TEXTS["covid19.medical.hold.residential.title"]]} type={TextType.Heading1} />

                        <Spacer />

                        <Text
                            padded={true}
                            content={
                                mainAppState!.texts[COVID_TEXTS["covid19.medical.hold.residential.text"]]
                            }
                        />

                        <Spacer />

                        <PersonTests />
                        <Barcode />
                    </>
                )}

                {medicalSymptoms && !medicalResidential && (
                    <>
                        <Text content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.title"]]} type={TextType.Heading1} />

                        <Spacer />

                        <Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.subtitle"]]} />
                    </>
                )}

                {medicalHold && (
                    <>
                        <Text content={mainAppState!.texts[COVID_TEXTS["covid19.medical.hold.title"]]} type={TextType.Heading1} />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
                <Vaccine />
            </Provider>
            <Spacer />

            {renderMedical()}
        </>
    );
};

const Medical = inject("mainAppState")(observer(MedicalComponent));
export default Medical;
