import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import {
  mainAppState,
  MainAppStateInterface,
  CovidTextType,
  ActiveDrawer,
} from '../../model/MainAppStateStore';
import { inject, observer, Provider } from 'mobx-react';
import { COVID_TEXTS, default as CovidText } from '../../common/Texts';
import { toJS } from 'mobx';
import {
  RequirementIds,
  RequirementStatus,
  PassStatus,
} from '../../types/CovidStatusModel';
import {
  Container,
  Text,
  Spacer,
  Button,
  ButtonType,
  TextType,
  Badge,
} from '@mit/hui';
import Vaccine from './Vaccine';
import { UserProfileStoreInterface } from '../../model/UserProfileStore';
import { VaccineStatusModel } from '../../types/VaccineStatusModel';
import moment from 'moment';
import { ordinalsDate, formatDate } from '../../common/Formatters';
import { getEnv } from '../../common/Environment';

interface RequirementsIncompleteProps {
  mainAppState?: MainAppStateInterface;
  userProfileStoreState?: UserProfileStoreInterface;
}

const RequirementsIncompleteComponent: FC<RequirementsIncompleteProps> = ({
  mainAppState,
  userProfileStoreState,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const getNextState = (): RequirementIds => {
    if (
      mainAppState!.isRequirementMandatory(RequirementIds.acknowledge) &&
      !mainAppState!.evalRequirement(
        RequirementIds.acknowledge,
        RequirementStatus.complete
      )
    )
      return RequirementIds.acknowledge;
    else if (
      mainAppState!.isRequirementMandatory(RequirementIds.training) &&
      !mainAppState!.evalRequirement(
        RequirementIds.training,
        RequirementStatus.complete
      )
    )
      return RequirementIds.training;
    else if (
      mainAppState!.isRequirementMandatory(RequirementIds.medical) &&
      mainAppState!.evalRequirement(
        RequirementIds.medical,
        RequirementStatus.pending
      )
    )
      return RequirementIds.attestation;
    else if (
      mainAppState!.isRequirementMandatory(RequirementIds.medical) &&
      !mainAppState!.evalRequirement(
        RequirementIds.medical,
        RequirementStatus.complete
      )
    )
      return RequirementIds.medical;
    else if (
      mainAppState!.isRequirementMandatory(RequirementIds.attestation) &&
      !mainAppState!.evalRequirement(
        RequirementIds.attestation,
        RequirementStatus.complete
      )
    )
      return RequirementIds.attestation;

    return RequirementIds.attestation;
  };

  const getButtonText = (): string => {
    const nextState = getNextState();

    if (nextState === RequirementIds.acknowledge)
      return mainAppState!.texts[
        COVID_TEXTS['covid19.dashboard.incomplete.waiver.action']
      ];
    else if (nextState === RequirementIds.training)
      return mainAppState!.texts[
        COVID_TEXTS['covid19.dashboard.incomplete.training.action']
      ];
    else if (
      nextState === RequirementIds.medical ||
      mainAppState!.isFlushotRequired()
    )
      return mainAppState!.texts[
        COVID_TEXTS['covid19.dashboard.incomplete.medical.action']
      ];
    else if (nextState === RequirementIds.attestation)
      return mainAppState!.texts[
        COVID_TEXTS['covid19.dashboard.incomplete.attestation.action']
      ];

    return '';
  };

  const setDrawerState = () => {
    if (getNextState() === RequirementIds.acknowledge)
      mainAppState!.activeDrawer = ActiveDrawer.Acknowledgement;
    else if (
      getNextState() === RequirementIds.medical ||
      mainAppState!.isFlushotRequired()
    )
      mainAppState!.activeDrawer = ActiveDrawer.Medical;
    else if (getNextState() === RequirementIds.training)
      mainAppState!.activeDrawer = ActiveDrawer.Training;
    else if (getNextState() === RequirementIds.attestation)
      mainAppState!.activeDrawer = ActiveDrawer.Attestation;
  };

  const statusColor = (): React.ReactNode => {
    let medicalRequirement = mainAppState!.getRequirement(
      RequirementIds.medical
    );
    const status = medicalRequirement.status;
    // const status = 'overdue_grace'

    const color: any = {
      incomplete: 'info',
      pending: 'info',
      retest_due_soon: 'success',
      overdue_grace: 'warning',
      due_soon: 'warning',
      complete: 'success',
    };
    return color[status];
  };

  const medicalStatusBadge = (): React.ReactNode => {
    const medicalRequirement = mainAppState!.getRequirement(
      RequirementIds.medical
    );
    const status = medicalRequirement.status;
    const last_completion_result = medicalRequirement.last_completion_result;
    const date = medicalRequirement.last_completion ?? '';
    const last_completion = medicalRequirement.last_completion;

    const medicalStatus: any = {
      complete: () => {
        if (last_completion_result === 'negative')
          return (
            <div>
              <Badge
                type={'success'}
                content={mainAppState!.texts[
                  'covid19.pass.medical.status_badge.complete_neg'
                ].replace('{date}', ordinalsDate(date))}
              />
            </div>
          );
        if (last_completion_result === 'positive')
          return (
            <div>
              <Badge
                type={'info'}
                content={mainAppState!.texts[
                  'covid19.pass.medical.status_badge.complete_pos'
                ].replace('{date}', ordinalsDate(date))}
              />
            </div>
          );
        if (last_completion_result === 'inconclusive')
          return (
            <div>
              <Badge
                type={'info'}
                content={mainAppState!.texts[
                  'covid19.pass.medical.status_badge.complete_incon'
                ].replace('{date}', ordinalsDate(date))}
              />
            </div>
          );
        if (last_completion_result === null && last_completion !== null)
          return (
            <div>
              <Badge
                type={'info'}
                content={mainAppState!.texts[
                  'covid19.pass.medical.status_badge.pending'
                ].replace('{date}', ordinalsDate(date!))}
              />
            </div>
          );

        return (
          <div>
            <Badge
              type={'success'}
              content={mainAppState!.texts[
                'covid19.pass.medical.status_badge.complete'
              ].replace('{date}', ordinalsDate(date))}
            />
          </div>
        );
      },
      incomplete: () => {
        return (
          <div>
            <Badge
              type={'warning'}
              content={
                mainAppState!.texts[
                  'covid19.pass.medical.status_badge.incomplete'
                ]
              }
            />
          </div>
        );
      },
      due_soon: () => {
        return (
          <div>
            <Badge
              type={'danger'}
              content={
                mainAppState!.texts[
                  'covid19.pass.medical.status_badge.due_soon'
                ]
              }
            />
          </div>
        );
      },
      overdue_grace: () => {
        return (
          <div>
            <Badge
              type={'warning'}
              content={
                mainAppState!.texts[
                  'covid19.pass.medical.status_badge.overdue_grace'
                ]
              }
            />
          </div>
        );
      },
      pending: () => {
        return (
          <div>
            <Badge
              type={'info'}
              content={mainAppState!.texts[
                'covid19.pass.medical.status_badge.pending'
              ].replace('{date}', ordinalsDate(date!))}
            />
          </div>
        );
      },
    };

    if (mainAppState!.getRequirement(RequirementIds.medical).required)
      return status && medicalStatus[status]();
    else return null;
  };

  return (
    <>
      <div className="col-lg-6 col-lg-9 mx-auto mb-2 pb-2">
        <Text content="Covid Pass" type={TextType.Heading1} />
        <span className="badge badge-warning">Action Required</span>

        <>
          {/* <Spacer />
                <Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
                    <Vaccine/>
                </Provider> */}
        </>

        <Text
          content={
            <>{mainAppState!.texts[COVID_TEXTS['covid19.pass.noaccess.sub']]}</>
          }
        />

        <Spacer />
        <Container
          color="grey"
          alignment="left"
          contents={
            <>
              <div className="p-3">
                {medicalStatusBadge()}
                <Text
                  padded={true}
                  content={
                    mainAppState!.texts[
                      COVID_TEXTS['covid19.pass.noaccess.actionbox']
                    ]
                  }
                />
              </div>
              <div className="m-3 text-center">
                <Button
                  type={ButtonType.Primary}
                  text={getButtonText()}
                  onClick={() => setDrawerState()}
                />
              </div>
            </>
          }
        />
        {mainAppState!.isFlushotVisible() &&
          !mainAppState!.isFlushotCompleted() && (
            <>
              <Spacer />
              <Spacer />
              {mainAppState!.features[
                'covid19.medical.flu_shot.title.visible'
              ] && (
                <Container
                  color="grey"
                  alignment="left"
                  contents={
                    <>
                      <div className="p-3">
                        <Text
                          padded={true}
                          content={
                            mainAppState!.texts[
                              COVID_TEXTS['covid19.pass.medical.flushot.text']
                            ]
                          }
                        />
                      </div>
                      <div className="m-3 text-center">
                        <Button
                          url={`https://covidvaccine${getEnv(true)}.mit.edu`}
                          type={ButtonType.Primary}
                          text={'Continue to Vaccine'}
                          icon={'vial'}
                        />
                      </div>
                    </>
                  }
                />
              )}
            </>
          )}

        {(!mainAppState!.vaccineStatus?.booster.received ||
          mainAppState!.vaccineStatus?.booster.in_grace) && (
          <>
            <Spacer />
            <Spacer />
            {console.log(mainAppState!.vaccineStatus)}
            <Container
              color="grey"
              alignment="left"
              contents={
                <>
                  <div className="p-3">
                    {/* {medicalStatusBadge()} */}
                    <div>
                      <Badge
                        type="danger"
                        // content={mainAppState!.texts[
                        //   'covid19.pass.medical.status_badge.complete_neg'
                        // ].replace('{date}', ordinalsDate(date))}
                        content={mainAppState!.texts[
                          'covid19.pass.medical.booster.badge'
                        ].replace(
                          '{date}',
                          formatDate(
                            mainAppState!.vaccineStatus?.booster.due_date ?? ''
                          )
                        )}
                      />
                    </div>
                    {/* <Text
                      padded={true}
                      content={
                        mainAppState!.texts[
                          COVID_TEXTS['covid19.pass.noaccess.actionbox']
                        ]
                      }
                    /> */}
                    <Text
                      type="h4"
                      padded={true}
                      // content={"Hello, it's me"}
                      content={
                        mainAppState!.texts[
                          COVID_TEXTS['covid19.pass.medical.booster.title']
                        ]
                      }
                    />
                    <Text
                      padded={true}
                      // content={"Hello, it's me"}
                      content={
                        mainAppState!.texts[
                          COVID_TEXTS['covid19.pass.medical.booster.text']
                        ]
                      }
                    />
                  </div>
                  <div className="m-3 text-center">
                    <Button
                      url={`https://covidvaccine${getEnv(true)}.mit.edu`}
                      type={ButtonType.Primary}
                      text={'Continue to Vaccine'}
                      // icon={'vial'}
                    />
                  </div>
                </>
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const RequirementsIncomplete = inject('mainAppState')(
  observer(RequirementsIncompleteComponent)
);
export default RequirementsIncomplete;
