import React, {FC, useState, useEffect} from 'react'
import { withRouter } from 'react-router';
import { inject, observer, Provider } from "mobx-react";
import { ActiveDrawer, MainAppStateInterface } from '../../model/MainAppStateStore'
import { UserProfileStoreInterface } from '../../model/UserProfileStore'
import { ActionList, Alert, Button, ButtonState, ListItemProps, PagingContainer, PagingDotProps, Spacer, Text, Textbox } from '@mit/hui';
import MedicalLocations from './MedicalLocations';
import dataController from '../../api/DataController';
import { PassStatus, RequirementIds } from '../../types/CovidStatusModel';

interface MedicalAssistProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	history?: any;
}

interface Stepper {
    one: boolean
    two: boolean
    three: boolean
}

const UnobservedTestingComponent: FC<MedicalAssistProps> = ({mainAppState, userProfileStoreState}) => {
    const [noMatch, setNoMatch] = useState<boolean>(false)
    const [isBusy, setBusy] = useState<boolean>(false)
    const [barcodeOne, setBarcodeOne] = useState<string>('')
    const [barcodeTwo, setBarcodeTwo] = useState<string>('')
    const [error, setError] = useState({
        message: '',
        type: 'info'
    })
    const [step, setStep] = useState<Stepper>({
        one: true,
        two: false,
        three: false,
    })

    useEffect(() => {
        if (!mainAppState!.unobservedStatus!.canSubmit ?? !mainAppState!.unobservedStatus!.isOpen) 
            setStep({one: false, two: false, three: true})
            setBarcodeOne(mainAppState!.unobservedStatus!.barcode ?? '')
    }, [])

    const matchExact = (regex: string, string: string):any => {
        const newRegex = new RegExp(regex, 'g')
        const match = string.match(newRegex);

        return match && string === match[0];
     }

    const validateBarcodes = async () => {
        setBusy(true)
        setNoMatch(false) 
        setError({
            message: '',
            type: 'error'
        })
        const barcode = `D-${barcodeOne}`
        // const regex = new RegExp(`${mainAppState!.texts["covid19.medical.barcode.regex"]}`, 'g')
        const regex = 'D-\\d{10}$'
        const valid = await matchExact(regex, barcode)
        if (valid && barcodeOne.includes(barcodeTwo) && barcodeOne.length === 10 && barcodeTwo.length === 10) {
            dataController.postUnobservedCompleted({barcode: barcode}).then(resp => {
                setStep({one: false, two: false, three: true})
                setError({
                    message: '',
                    type: 'error'
                })
            }).catch(err => {
                const errMessage = JSON.parse(err).message
                let drawerBody = document.getElementsByClassName('drawer')[0]
                drawerBody.scroll(0,0);
                setError({
                    message: errMessage,
                    type: 'error'
                })
                setBusy(false)
            })
        } else {
            let drawerBody = document.getElementsByClassName('drawer')[0]
            drawerBody.scroll(0,0);
            setNoMatch(true) 
            setBusy(false)
            setError({
                message: mainAppState!.texts["covid19.medical.unobserved.scan.input.error"],
                type: 'error'
            })
        }
    }

    let defaultListData: PagingDotProps[] = [
        {
            selected : step.one,
            // onClick: ()=>{ setStep({
            //     one: true,
            //     two: false,
            //     three: false
            // }) },
            url: ''
        }, 
        {
            selected : step.two,
            // onClick: ()=>{ setStep({
            //     one: false,
            //     two: true,
            //     three: false,
            // }) },
            url: ''
        },
        {
            selected : step.three,
            // onClick: ()=>{ setStep({
            //     one: false,
            //     two: false,
            //     three: true
            // })},
            url: ''
        }
    ];

    const stepTwolistData: ListItemProps[] = [
		{ 
			className: "custom-item", 
			icon: 'file-medical-alt', 
			text: mainAppState!.texts['covid19.medical.unobserved.swab.details.button'], 
			actions: [{ icon: 'chevron-right', state: ButtonState.Enabled }],
			onClick: () => window.open(mainAppState!.texts["covid19.pass.medical.unobserved.instruction.url"], '_blank')?.focus()
		}
	]

    const unobservedTestingStep = (step: any) => {
        let attestationRequirement = mainAppState!.getRequirement(RequirementIds.attestation)
        
        if (step.one) {
            return (
                <div className="content">
                    <img src="images/kit.png" alt="" />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.kit.subtitle"]} type={'h3'} bold />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.kit.instructions"]} type={'body'} />
                    <div aria-busy="false" role="list" className="list-group list-group-flush">
                        <MedicalLocations dropOfforPickUp={'unobserved_drop_off'} /> 
                        <MedicalLocations dropOfforPickUp={'unobserved_pick_up'} /> 
                    </div>
                    <Button 
                        type={'primary'} 
                        text={mainAppState!.texts["covid19.medical.unobserved.kit.button"]} 
                        onClick={() => {
                            setStep({one: false, two: true, three: false})
                        }} 
                        block
                    />
                </div>
            )
        }
    
        if (step.two) {
            return (
                <div className="content">
                    <img src="images/swab.png" alt="" />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.swab.subtitle"]} type={'h3'} bold />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.swab.instructions"]} type={'body'} />
                    {/* <a href={mainAppState!.texts["covid19.pass.medical.unobserved.instruction.url"]} target={'_blank'} className={'instructions'}>{mainAppState!.texts["covid19.medical.unobserved.swab.details.button"]}<Icon icon={'chevron-right'} type={'regular'} /></a> */}
                    <ActionList 
                        type="flush"
                        items={stepTwolistData}
                    />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.scan.input.instructions"]} type={'body'} />
                    <div className={`custom-text`}>
                        <label>Barcode</label>
                        <span>D-</span>
                        <Textbox 
                            name="barcode-one" 
                            autoFocus={true}
                            masked={'custom'}
                            inputMask={{ numericOnly: true, blocks: [10] }}
                            pattern={{ "value": /\d{10}$/, "message": "Barcode needs 10 numbers." }} 
                            onChange={(v: any) => setBarcodeOne(v.currentTarget.value)}
                            required
                        />
                    </div>
                    <div className={`custom-text ${noMatch ? 'error' : ''}`}>
                        <label>Confirm Barcode</label>
                        <span>D-</span>
                        <Textbox 
                            name="barcode-two" 
                            masked={'custom'}
                            inputMask={{ numericOnly: true, blocks: [10] }}
                            pattern={{ "value": /\d{10}$/, "message": "Barcode needs 10 numbers." }} 
                            onChange={(v: any) => setBarcodeTwo(v.currentTarget.value)}  
                            required                          
                        />
                    </div>
                    <Button 
                        type={'primary'} 
                        text={mainAppState!.texts["covid19.medical.unobserved.swab.button"]} 
                        isBusy={isBusy} 
                        state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} 
                        onClick={() => {
                            validateBarcodes()
                        }} 
                        block
                    />
                </div>
            )
        }
        if (step.three) {
            return (
                <div className="content">
                    <img src="images/hazardkit.png" alt="" />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.dropoff.subtitle"]} type={'h3'} bold />
                    <Text content={mainAppState!.texts["covid19.medical.unobserved.dropoff.instructions"]} type={'body'} align={'left'} /> 
                    {/* {mainAppState!.unobservedStatus!.isOpen ? (
                        <Text content={mainAppState!.texts["covid19.medical.unobserved.dropoff.warning"]} color="red" type={'body'} align={'left'} /> 
                    ) : (
                        <Text content={mainAppState!.texts["covid19.medical.unobserved.location.closed"]} color="red" type={'body'} align={'left'} /> 
                    )} */}
                    <div aria-busy="false" role="list" className="list-group list-group-flush">
                        <MedicalLocations dropOfforPickUp={'unobserved_drop_off'} />
                    </div>
                    {mainAppState!.unobservedStatus!.canSubmit ? (
                        <div className="barcode">
                            <Text content={`D-${barcodeOne}`} type={'h4'} />
                        </div>
                    ) : (
                        <div className="barcode">
                            <Text content={barcodeOne} type={'h4'} />
                        </div>
                    )}
                    {((attestationRequirement.status !== 'complete') && attestationRequirement.visible && (mainAppState!.status_model.status !== PassStatus.medical_symptoms))
                    ? <Button
                          type={'primary'}
                          onClick={() => {
                              mainAppState!.activeDrawer = ActiveDrawer.Attestation
                              mainAppState!.reload = true
                          }}
                          text={mainAppState!.texts["covid19.dashboard.incomplete.attestation.action"]}
                          block
                      />
                    : null
                    // : <Button
                    //       type={'primary'}
                    //       onClick={() => {
                    //           // mainAppState!.activeDrawer = ActiveDrawer.Medical
                    //           mainAppState!.activeDrawer = ActiveDrawer.None
                    //           mainAppState!.reload = true
                    //       }}
                    //       // text={mainAppState!.texts["covid19.pass.medical.unobserved.complete.button"]}
                    //       text={'Done'}
                    //       block
                    //   />
                    }
                </div>
            )
        }
    }


    return (
        <>
            {error.message && <Alert text={error.message} type={'error'}/>}
            {unobservedTestingStep(step)}
            <Spacer size={'4'} />
            <PagingContainer items={defaultListData} />
        </>
    );
};

const UnobservedTesting = withRouter(inject("mainAppState", "userProfileStoreState")(observer(UnobservedTestingComponent)));
export default UnobservedTesting;