import * as React from 'react'
import {inject, observer} from "mobx-react";
import {FC, useEffect, useState} from "react";
import {MainAppStateInterface} from "../../model/MainAppStateStore";
import {UserProfileStoreInterface} from "../../model/UserProfileStore";
import dataController from "../../api/DataController";
import { COVID_TEXTS } from "../../common/Texts";
import { Barcode } from '@mit/hui';

interface BarcodeProps {
    mainAppState?: MainAppStateInterface;
    userProfileStoreState?: UserProfileStoreInterface;
}

const BarcodeComponent: FC<BarcodeProps> = ({ mainAppState, userProfileStoreState}) => {

    const [imageUrl, setImageUrl] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [barcodeError, setBarcodeError] = useState('')
    const [displayName, setDisplayName] = useState('')

    useEffect(() => {

        const abortController = new AbortController()
        dataController.fetchBarcode(abortController.signal, mainAppState!.submittingOnBehalfKerbId).then((url: any) => {
            setImageUrl(url.value)
            setIsLoading(false)
        }, (error: any) => {
            setBarcodeError(`Your barcode could not be loaded at this time. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS['covid19.pass.help_email']]}.`)
            setIsLoading(false)
        })

        return () => {
            abortController.abort();
        };
    }, [])

    return <Barcode imageUrl={imageUrl} label={userProfileStoreState!.userProfile.displayName ? userProfileStoreState!.userProfile.displayName : ""} barcodeError={barcodeError} isLoading={isLoading} />

    //OLD Barcode
    return <div className={'barcode-container'}>
        {isLoading && <div>Loading barcode...</div>}
        {!isLoading && barcodeError != '' && <div>{barcodeError}</div>}

        {!isLoading && barcodeError == '' && <>
            <div className={'barcode-name'}>{userProfileStoreState!.userProfile.displayName}</div>
            {imageUrl != '' && <img src={imageUrl} className={'img-barcode'}/>}
        </>}
    </div>
}

const MyBarcode = inject('mainAppState', 'userProfileStoreState')(observer(BarcodeComponent))
export default MyBarcode
