import * as React from 'react'
import {FC} from 'react'
import {inject, observer} from "mobx-react";
import {useEffect} from "react";
import {useState} from "react";
import {default as userController} from "../../api/UserController";
import {Button} from "react-bootstrap";
import {withRouter} from "react-router";

const OnBehalfHeaderComponent: FC<any> = ({mainAppState, history}) => {
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        //if you are submitting on behalf, get picture for the person
        if(mainAppState!.submittingOnBehalfKerbId && mainAppState!.submittingOnBehalfKerbId != '') {
            userController.fetchPicture(mainAppState!.submittingOnBehalfKerbId).then((url: any) => {
                setImageUrl(url.value)
            })
        }
    }, [])

    const handleCancelSubmitOnBehalf = () => {
        mainAppState!.cancelOnBehalfTransaction()
        history.push('/')
    }

    return <>
        {mainAppState!.submittingOnBehalfKerbId != '' && <div className={'submitting-on-behalf'}>
           <span className={'user-detail'}>
            {!imageUrl && <div className={'loading-image'}></div>}
               {imageUrl && <img src={imageUrl} className={'img-object-fit'}/>} <span className={'header-text'}> Submitting on behalf of {mainAppState!.submittingOnBehalfName}.</span><Button variant={'link'} className="reset-button" onClick={handleCancelSubmitOnBehalf}>cancel</Button>
               </span>
        </div>}
    </>
}

const OnBehalfHeader = withRouter(inject('mainAppState')(observer(OnBehalfHeaderComponent)))
export default OnBehalfHeader