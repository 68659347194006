import * as React from "react";


type TUpdateCallback = (eventObject?: any) => any
type TUpdateFieldValidation = (eventObject?: any) => any

export const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, updateCallback: TUpdateCallback, updateFieldValidation: TUpdateFieldValidation) => {
    let fieldName = event.target.name
    let value = event.target.value
    updateFieldValidation(fieldName)
    handleFieldChange(fieldName, value, updateCallback)
}

export const handleFieldChange = (fieldName: string, value: string | number | Date, updateCallback:TUpdateCallback) => {
    let eventObject = {
        [fieldName]: value
    }
    updateCallback(eventObject)
}