import * as React from "react";
import { FC, useState, useEffect } from "react";
import { MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { toJS } from "mobx";
import { Container, Text, Spacer, Button, ButtonType, TextType } from "@mit/hui";
import Vaccine from "./Vaccine";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";

interface NoAccessProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const NoAccessComponent: FC<NoAccessProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		console.log(toJS(mainAppState!.texts));
	}, []);

	const getButtonText = (): string => {
		//TODO: Get this from COVID_TEXTS
		return "View medical results";
	};

	const setDrawerState = () => {
		mainAppState!.activeDrawer = ActiveDrawer.TestingHistory;
	};

	return (
		<>
			<div className="col-lg-6 col-lg-9 mx-auto">
				<Text content="Covid Pass" type={TextType.Heading1} />
				<span className="badge badge-dark">No Access</span>
				<Spacer />
				<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
					<Vaccine />
				</Provider>
				<Text
					content={
						<>
							{mainAppState!.texts[COVID_TEXTS["covid19.noaccess.title"]]}
							<br />
							{mainAppState!.texts[COVID_TEXTS["covid19.noaccess.text.web"]]}
						</>
					}
				/>
				<Spacer />
				<Container
					color="green"
					alignment="left"
					contents={
						<>
							<div className="pl-4 pr-4">
								<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.noaccess.instructions"]]} />
								<Spacer />
							</div>
							<div className="p-4 pt-0 text-center">
								<Button type={ButtonType.Primary} text={getButtonText()} onClick={setDrawerState} />
							</div>
						</>
					}
				/>
			</div>
		</>
	);
};

const NoAccess = inject("mainAppState")(observer(NoAccessComponent));
export default NoAccess;
