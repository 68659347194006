import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { inject, observer } from "mobx-react";
import { MainAppStateInterface } from "../../model/MainAppStateStore";
import dataController from "../../api/DataController";
import Waiver from "./Waiver";
import { PageError } from "../../types/PageErrors";
import { withRouter } from "react-router";
import { RequirementIds, RequirementStatus } from "../../types/CovidStatusModel";
import { COVID_TEXTS } from '../../common/Texts';
import { TextType, Text, Button, ButtonType, Icon, Spacer } from '@mit/hui';
import { mediumLoaderOptions } from '../../common/Defaults';
import  Loader from 'react-loader'
import { FetchPassStatusResponse } from 'types/Api';

interface WaiverLandingProps {
    mainAppState?: MainAppStateInterface;
    history?: any;
}

const WaiverLandingComponent: FC<WaiverLandingProps> = ({ mainAppState, history }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [showWaiverForm, setShowWaiverForm] = useState(false)
    const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        const abortController = new AbortController()

        dataController.fetchPassStatus(abortController.signal).then((statusResponse: FetchPassStatusResponse) => {
            setIsLoading(false);

            if (!statusResponse.error) {
                mainAppState!.updatePersonStatus(statusResponse)

                if (mainAppState!.status_model &&
                    !mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.complete)) {
                    setShowWaiverForm(true)
                }
            }
        })
        return () => {
            abortController.abort();
        };
    }, [])

    const clickReset = () => {
        setShowWaiverForm(true);
    }

    return <div className={`submitter-view`}>
        <div className={'nav-container'}>
            <div className={`main-content`}>
                <div className={`page-content text-align-center`}>
                    <Loader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: 'black' }}>
                        {mainAppState && mainAppState.isRequirementMandatory(RequirementIds.acknowledge) ? <>
                            {showWaiverForm && <Waiver />}
                            {!showWaiverForm && <>
                            <div className="text-center">
                                <span className="bigIcons"><Icon icon="check-circle" type="solid"/></span>
                                <Text content={mainAppState!.texts[COVID_TEXTS['covid19.onboard.alreadysigned.text']]} type={TextType.Body} />
                                <Button theme="medical-dark" text={mainAppState!.texts[COVID_TEXTS['covid19.onboard.alreadysigned.action']]} type={ButtonType.Primary | ButtonType.Ghost} icon="edit"
                                    onClick={clickReset} /></div>
                            </>
                            }
                        </> : <>
                                Acknowledgement is not required.
                    </>}
                    <Spacer size="5"/>
                    </Loader>
                </div>
            </div>
        </div>
    </div>
}

const WaiverLanding = withRouter(inject('mainAppState')(observer(WaiverLandingComponent)))

export default WaiverLanding