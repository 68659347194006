import * as React from "react";
import { FC, useState } from "react";
import { displayDate, displayDateTime, formatPhoneNumber, toDate } from "./Formatters";
import DatePicker from "./form/DatePicker";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import  moment from "moment";
import LoadableButton from "./LoadableButton";
import { Icon } from "@mit/hui";

interface CommonTableProps {
	data: any[];
	headings: string[];
	fields: string[];
	classes?: string[];
	dataTypes: any;
	filters?: any;
}

const TableValue = (type: string | undefined, value: string) => {
	if (value === "-" || !type) {
		return value;
	}
	if (type === "phone") {
		return <a href={`tel:${value}`}>{formatPhoneNumber(value)}</a>;
	}
	if (type === "email") {
		return <a href={`mailto:${value}`}>{value}</a>;
	}
	if (type === "date") {
		return displayDateTime(value);
	}
	if (type === "shortdate") {
		return displayDate(value);
	}
	if (type === "capitalize") {
		return <span className={"capitalize"}>{value}</span>;
	}
	return value;
};

const getClassName = (fieldName: string, item: any) => {
	if (fieldName) {
		if (fieldName.toLowerCase() === "negative") return "negative";
		else if (fieldName.toLowerCase() === "positive") return "positive";
		else if (fieldName.toLowerCase() === "flu shot") return "flu";
		else if (item.rawResult === "VACCINE_SHOT") return "vaccine_shot";
		else if (item.rawResult === "VACCINE_COURSE_COMPLETED") return "vaccine_course_completed";
	}

	return "";
};

interface TableFilterProps {
	setFilter: (f: any) => void;
	filterOn: string;
}

const TableFilter: FC<TableFilterProps> = ({ setFilter, filterOn }) => {
	const [isActive, setIsActive] = useState<boolean>(false);

	const [fromDate, setFromDate] = useState<moment.Moment | undefined>();
	const [untilDate, setUntilDate] = useState<moment.Moment | undefined>();

	const [fromDateTmp, setFromDateTmp] = useState<moment.Moment | undefined>();
	const [untilDateTmp, setUntilDateTmp] = useState<moment.Moment | undefined>();

	const handleFromDateChange = (newValue: any) => {
		setFromDateTmp(moment(newValue.fromDate));
	};
	const handleUntilDateChange = (newValue: any) => {
		setUntilDateTmp(moment(newValue.untilDate));
	};

	const applyCurrentDateFilter = () => {
		setIsActive(true);
		setFromDate(fromDateTmp);
		setUntilDate(untilDateTmp);
		setFilter([
			function (inRow: any) {
				return inRow[filterOn] && toDate(inRow[filterOn]).isBetween(fromDateTmp, untilDateTmp, "dates", "[]");
			},
		]);
		document.body.click();
	};
	const clearCurrentDateFilter = () => {
		setIsActive(false);
		setFilter(undefined);
		document.body.click();
	};

	return (
		<OverlayTrigger
			trigger="click"
			key={"top"}
			placement={"top"}
			rootClose={true}
			overlay={
				<Popover id={`popover-positioned-top`}>
					<Popover.Title as="h3">{`Date Filter`}</Popover.Title>
					<Popover.Content>
						<span className={"tinyMarginBottom"}>Between</span>

						<div className={"flex center"}>
							<DatePicker defaultValue={fromDate} handleChange={handleFromDateChange} id={"from-date"} name={"fromDate"} />
							<span className={"tinyMarginLeftRight"}>and</span>
							<DatePicker defaultValue={untilDate} handleChange={handleUntilDateChange} id={"until-date"} name={"untilDate"} />
						</div>
						<div className={"flex center smallMarginTop"}>
							<Button variant={"outline-light"} className={"smallMarginRight"} onClick={applyCurrentDateFilter}>
								Apply
							</Button>
							<Button variant={"outline-light"} className={"smallMarginRight"} onClick={clearCurrentDateFilter}>
								Clear
							</Button>
						</div>
					</Popover.Content>
				</Popover>
			}>
			<img src={`/glyphicons-basic-395-filter${isActive ? "-red" : ""}.svg`} className={"filter-icon"} />
		</OverlayTrigger>
	);
};

const CommonTable: FC<CommonTableProps> = ({ headings, fields, data, dataTypes, filters }) => {
	const [currentFilters, setCurrentFilters] = useState<any>(undefined);

	const updateFilters = (newFilters: any) => {
		setCurrentFilters(newFilters);
	};

	return (
		<>
			<table className={"common-table"}>
				<thead>
					<tr>
						<th style={{ width: "1%" }}></th>
						{headings.map((text: string, index: number) => (
							<th key={`th-${index}`}>
								{text}
								{filters[text] && <TableFilter setFilter={updateFilters} filterOn={filters[text].filterOn} />}
							</th>
						))}
						<th style={{ width: "1%" }}></th>
					</tr>
				</thead>
				<tbody>
					{data.length <= 0 && (
						<tr>
							<td colSpan={headings.length}>No data</td>
							<td></td>
						</tr>
					)}
					{data &&
						data
							.filter((row: any) => {
								if (currentFilters && row) {
									let include = currentFilters.reduce((coll: boolean, curr: any) => {
										return coll && curr(row);
									}, true);
									return include;
								} else {
									return true;
								}
							})
							.map((item: any, index: number) => {
								return (
									<tr key={`tr-${index}`}>
										<td style={{ width: "1%" }}>
											<i className="fas fa-file-medical-alt"></i>
										</td>
										{fields.map((id: string, colIndex: number) => (
											<td key={`col-${index}-${colIndex}`}>
												<div className={getClassName(item[id], item)}>{TableValue(dataTypes[id], item[id])}</div>
												{item.rawResponse === "VACCINE_COURSE_COMPLETED" ? <Icon type="light" icon="shield-check" /> : ""}
											</td>
										))}
										<td style={{ width: "1%" }} key={`td-download-${index}`}>
											{item.can_download && (
												<LoadableButton
													guid={item.guid}
													index={index}
													isFluShot={item.result === "Flu Shot" ? true : false}
													isVaccine={item.rawResult.toLowerCase().includes("vaccine") ? true : false}
												/>
											)}
										</td>
									</tr>
								);
							})}
				</tbody>
			</table>
		</>
	);
};

export default CommonTable;
