import * as React from 'react'
import { FC, useState } from 'react'
import { Alert, Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import dataController from "../../api/DataController";
import { useEffect } from "react";
import './QueueLengths.scss'
import { inject, observer } from "mobx-react";
import { TemplateModal, TemplateModalSize, TemplateModalAlignment, Text, TextType, ListItem, Icon, Spacer, ButtonState } from '@mit/hui';
import { LocalStorage, StorageKeys } from '../../common/LocalStorage';
import { MainAppStateInterface } from '../../model/MainAppStateStore';
import { MedicalQueueLengths, FetchMedicalQueueLengthResponse } from 'types/Api';

interface MedicalLocationsComponentProps {
    mainAppState?: MainAppStateInterface;
    dropOfforPickUp: LocationType;
}

type LocationType = 'unobserved_pick_up' | 'unobserved_drop_off'

const MedicalLocationsComponent: FC<MedicalLocationsComponentProps> = ({ mainAppState, dropOfforPickUp }) => {
    const [queueLengths, setQueueLengths] = useState<{ [name: string]: MedicalQueueLengths[] }>({})
    const [selectionError, setSelectionError] = useState<string>('')
    const [activeTab, setActiveTab] = useState<string>('Today')
    const [isShowingPopup, setIsShowingPopup] = useState(false);

    useEffect(() => {
        let abortController = new AbortController();
        let favLocId = LocalStorage.get(StorageKeys.FAVORITE_LOCATION);

        dataController.fetchMedicalQueueLengths(abortController.signal, 7, 2).then((response: FetchMedicalQueueLengthResponse) => {
            if (!response.error) {

                /* Group data by day_title */
                let groupedData: { [name: string]: MedicalQueueLengths[] } = response.items!.filter(item => item[dropOfforPickUp]).reduce((prev: any, curr: MedicalQueueLengths) => {
                    if (!prev[curr.day_title])
                        prev[curr.day_title] = {};

                    if (curr.day_title === "Today")
                        curr.favorite = (curr.location_id === favLocId);
                    else
                        curr.favorite = undefined;

                    /* Create single object instead of array of objects for easier state update. */
                    prev[curr.day_title] = { ...prev[curr.day_title], ...{ [curr.location_id]: curr } };

                    return prev;
                }, {});  

                setQueueLengths(groupedData);
            }
            else
                setSelectionError(response.error);
        }).catch(() => {
            //TODO
        })

        return () => (abortController.abort())
    }, [])

    // const favoriteClicked = (event: any) => {

    //     let selectedLocationId = event.target.value;
    //     let day_title = activeTab;
    //     let copyQueueLengths = { ...queueLengths };

    //     for (let locationId in copyQueueLengths[day_title])
    //         copyQueueLengths[day_title][locationId].favorite = (locationId === selectedLocationId);

    //     setQueueLengths(copyQueueLengths);

    //     LocalStorage.save(StorageKeys.FAVORITE_LOCATION, selectedLocationId);
    // }

    return <>
        <ListItem 
            icon={'prescription-bottle-alt'} 
            className={'custom-item list-group-flush'} 
            text={dropOfforPickUp == 'unobserved_pick_up' ? mainAppState!.texts['covid19.medical.unobserved.need_kit.button'] : mainAppState!.texts['covid19.pass.medical.unobserved.drop_off.button']} 
            onClick={() => setIsShowingPopup(true)} 
            actions={[{ icon: 'chevron-right', state: ButtonState.Enabled }]} 
        /> 
        <TemplateModal          
            body={
                <React.Fragment>
                    {selectionError !== '' && <Row><Col xs={12}><Alert variant={'danger'}>{selectionError}</Alert></Col></Row>}

                    <Tabs id="uncontrolled-tab-example" variant={"pills"}
                        activeKey={activeTab}
                        onSelect={(k: any) => setActiveTab(k)}>

                        {queueLengths && Object.keys(queueLengths).map((day: string) => {
                            return <Tab eventKey={day} title={day} key={day}>

                                {/* {(day === "Today" && dropOfforPickUp === 'unobserved_pick_up') && (
                                    <div className={'queue-wait-select py-3'}>{mainAppState!.texts['covid19.pass.testing.location.subtitle'] ?? "Select your default testing location"}</div>
                                )} */}

                                <Container>
                                    {Object.values(queueLengths[day]).map((queue: MedicalQueueLengths, i: number) => {
                                        return <>
                                            <Row className={'queue-wait'} key={i + '-' + queue.location_id + '-' + queue.day_title}>
                                                {/* <Col xs={1}>
                                                    {queue.favorite !== null &&
                                                        <Form.Check type={'radio'} checked={queue.favorite} value={queue.location_id} onChange={favoriteClicked}></Form.Check>
                                                    }
                                                </Col> */}
                                                <Col xs={7}>
                                                    <span className={'queue-wait__name'}>{queue.name}</span>
                                                    <br />
                                                    {queue.medical_queue_indicator &&
                                                        <span className={'queue-wait__time-text'}>{queue.wait_time_text}</span>
                                                    }
                                                </Col>
                                                {queue.is_open ? (
                                                    <>
                                                        {(dropOfforPickUp === 'unobserved_pick_up') &&
                                                            <>
                                                                <Col xs={2} className="filler-words" >
                                                                    <span className={'queue-wait__open-close'}>{mainAppState!.texts['covid19.medical.queue.from']}</span>
                                                                    <br />
                                                                    <span className={'queue-wait__open-close'}>{mainAppState!.texts['covid19.medical.queue.to']}</span>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <span className={'queue-wait__open-close-time'}>{queue.open_time}</span>
                                                                    <br />
                                                                    <span className={'queue-wait__open-close-time'}>{queue.close_time}</span>
                                                                    {/* <span className={'queue-wait__open-close-time'}>{queue.unobserved_pick_up ? '24 Hours' : 'Closed'}</span> */}
                                                                </Col>
                                                            </>
                                                        }
                                                        {(dropOfforPickUp === 'unobserved_drop_off') &&
                                                            <>
                                                                <Col xs={2} className="filler-words" >
                                                                    <span className={'queue-wait__open-close'}>{mainAppState!.texts['covid19.medical.queue.last_pickup']}</span>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <span className={'queue-wait__open-close-time'}>{queue.close_time}</span>
                                                                    {/* <span className={'queue-wait__open-close-time'}>{queue.unobserved_drop_off ? '24 Hours' : 'Closed'}</span> */}
                                                                </Col>
                                                            </>
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <Col xs={2}>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <span className={'queue-wait__open-close-time'}>Closed</span>
                                                            {/* <br />
                                                            <span className={'queue-wait__open-close-time'}>&nbsp;</span> */}
                                                        </Col>
                                                    </>
                                                )
                                                }
                                                {/* {queue.is_open &&
                                                    <>
                                                        {(queue.is_open_24_hours && dropOfforPickUp === 'unobserved_pick_up') &&
                                                            <Col xs={2}>
                                                                <span className={'queue-wait__open-close-time'}>{queue.unobserved_pick_up ? '24 Hours' : 'Closed'}</span>
                                                            </Col>}
                                                        {(queue.is_open_24_hours && dropOfforPickUp === 'unobserved_drop_off') &&
                                                            <Col xs={2}>
                                                                <span className={'queue-wait__open-close-time'}>{queue.unobserved_drop_off ? '24 Hours' : 'Closed'}</span>
                                                            </Col>}
                                                    </>
                                                } */}
                                                {/* {!queue.is_open &&
                                                    <Col xs={2}>
                                                        <span className={'queue-wait__open-close-time'}>Closed</span>
                                                        <br />
                                                        <span className={'queue-wait__open-close-time'}>&nbsp;</span>
                                                    </Col>
                                                } */}
                                            </Row>
                                        </>

                                    })}
                                </Container>
                            </Tab>
                        })}

                    </Tabs>
                    <Spacer size={'4'} />
                    <a 
                        role="button" 
                        href={dropOfforPickUp == 'unobserved_pick_up' ? mainAppState!.texts["covid19.pass.medical.unobserved.pickup.map.url"] : mainAppState!.texts["covid19.pass.medical.unobserved.dropoff.map.url"]} 
                        target={'_blank'} 
                        className={'dropoff'}>
                            <Icon icon={'map'} type={'regular'} />{dropOfforPickUp == 'unobserved_pick_up' ? (mainAppState!.texts["covid19.pass.medical.unobserved.pickup.map.link"]) : (mainAppState!.texts["covid19.pass.medical.unobserved.location.map.link"])}
                    </a>
                </React.Fragment>
            }
            show={isShowingPopup}
            onClose={() => setIsShowingPopup(false)}
            bodyAlignment={TemplateModalAlignment.Left}
            header={<Text content={dropOfforPickUp == 'unobserved_pick_up' ? mainAppState!.texts['covid19.pass.medical.unobserved.pick_up.title'] : mainAppState!.texts['covid19.pass.medical.unobserved.drop_off.title']} type={TextType.Heading3} />}
            size={TemplateModalSize.Large}
            imageUrl=""
            theme="medical"
            footer={<div></div>}
            name="queueLengthsModal"
        />
    </>
}

const MedicalLocations = inject('mainAppState')(observer(MedicalLocationsComponent))
export default MedicalLocations