import { BaseApiController, Region, RegionalConfig, AuthProvider, AppConfig } from '@mit/aws-react'
import appConfig from '../app-config'

// Override any methods from BaseApiController here. Extend all other controllers off this controller
export default class BaseController extends BaseApiController {
	// public readonly apiPath: string
	public readonly appConfig: AppConfig
	public readonly apiHost: string
	public readonly apiPathCovid: string;
    public readonly apiPathVaccine: string;
    public readonly apiPathPass: string;
    public readonly apiPathMedical: string;
    public readonly apiPathDigitalId: string;
    public readonly apiPathPrefix: string;
	public readonly activeRegion: RegionalConfig

	public constructor() {
		super()
		this.appConfig = (appConfig as unknown) as AppConfig
		const region = new Region(this.appConfig)

		this.activeRegion = region.getActiveRegionConfig()
		this.apiHost = `https://${this.activeRegion.api.domain}`

		this.apiPathDigitalId = this.activeRegion.api.paths['digital-id']
		this.apiPathCovid = this.activeRegion.api.paths["covid19"];
        this.apiPathVaccine = this.activeRegion.api.paths["vaccine"];
        this.apiPathPass = this.activeRegion.api.paths["pass"];        
        this.apiPathMedical = this.activeRegion.api.paths["medical"];
        this.apiPathPrefix = "pass";
	}
	/*
    If you do not use cognito, implement your authorization header logic here
  */
	public async getAuthorizationHeaders(): Promise<Record<string, string>> {
		const token = await new AuthProvider(this.activeRegion).getToken()

		return {
			Authorization: `Bearer ${token}`
		}
	}

	public checkStatusCode(status: number): boolean {
        return status >= 200 && status < 300;
    }

	requestHeaderGet = async (
        moreHeaders?: any,
        signal?: AbortSignal
    ): Promise<RequestInit> => {
        try {
			const token = await new AuthProvider(this.activeRegion).getToken()

            const options: RequestInit = {
                method: "GET",
                signal: signal,
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                    ...moreHeaders,
                }),
            };
            return options;
        } catch (e) {
            console.error(e);
        }
        return {};
    };

    requestHeaderPost = async (requestBody: any): Promise<RequestInit> => {
		const token = await new AuthProvider(this.activeRegion).getToken()
        const options: RequestInit = {
            method: "POST",
            headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(requestBody),
        };
        return options;
    };

    requestHeaderPut = async (requestBody: any): Promise<RequestInit> => {
		const token = await new AuthProvider(this.activeRegion).getToken()
        const options: RequestInit = {
            method: "PUT",
            headers: new Headers({
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(requestBody),
        };
        return options;
    };
}
