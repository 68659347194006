import { MainAppStateInterface } from "model/MainAppStateStore";
import { PassStatus, RequirementIds, RequirementStatus } from "types/CovidStatusModel";

export const isNavStateEnabled = (id: RequirementIds, mainAppState?: MainAppStateInterface) => {

	const showMedicalEnabled = (): boolean => {
		return (
			mainAppState!.status_model.status === PassStatus.medical_positive ||
			mainAppState!.status_model.status === PassStatus.medical_symptoms ||
			mainAppState!.status_model.status === PassStatus.medical_block
		);
	};

	if (showMedicalEnabled() && id !== RequirementIds.medical) return false;
	else {
		let medicalRequirement = mainAppState!.getRequirement(RequirementIds.medical);
		let ackRequirement = mainAppState!.getRequirement(RequirementIds.acknowledge);

		if (ackRequirement?.status === "incomplete") return false;

		if (
			id == RequirementIds.attestation &&
			((mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.incomplete) && ackRequirement.required) ||
				(mainAppState!.evalRequirement(RequirementIds.medical, RequirementStatus.incomplete) && medicalRequirement.required))
		)
			return false;

		if (id == RequirementIds.attestation && medicalRequirement) {
			if (medicalRequirement?.status == RequirementStatus.pending && medicalRequirement.medical_awaiting_test_outcome) {
				return false;
			}

			if (mainAppState!.isFlushotRequired()) return false;
		}

		if (id == RequirementIds.attestation && mainAppState!.status_model.status === PassStatus.hold) return false;

		if (mainAppState!.status_model.status === PassStatus.no_access) return false;

		return true;
	}
};
