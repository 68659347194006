import { FetchStringResponse } from "types/Api";
import BaseController from "./BaseController";

export interface UserControllerInterface {
	url_picture: string;
	url_profile: string;
	url_feature: string;

	fetchFeatures: () => Promise<any>;
	fetchProfile: (kerbId?: string) => Promise<any>;
	fetchPicture: (kerbId?: string) => Promise<any>;
	fetchPin: (kerbId?: string) => Promise<any>;
	createPin: (kerbId?: string) => Promise<any>;
}

class UserControllerClass extends BaseController {
	readonly url_picture: string;
	readonly url_profile: string;
	readonly url_feature: string;
	readonly url_pin: string;

	constructor() {
		super();
		this.url_picture = `${this.apiHost}/${this.apiPathDigitalId}/picture`;
		this.url_profile = `${this.apiHost}/${this.apiPathDigitalId}/profile`;
		this.url_pin = `${this.apiHost}/${this.apiPathDigitalId}/pin`;
		this.url_feature = `${this.apiHost}/${this.apiPathPass}/${this.apiPathPrefix}/features`;
	}

	async fetchFeatures(): Promise<any> {
		try {
			let response = await fetch(`${this.url_feature}`, await this.requestHeaderGet());

			if (response.status == 200) {
				let data = await response.json();
				return data;
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
		}
	}

	async fetchProfile(kerbId?: string): Promise<any> {
		let response = await fetch(`${this.url_profile}${kerbId && kerbId !== "" ? `/${kerbId}` : ``}`, await this.requestHeaderGet());
		if (response.status == 200) {
			try {
				let data = await response.json();
				return data;
			} catch (error) {
				return {
					department: "",
					display_name: "",
					email: "",
					first_name: "",
					initials: "",
					kerberos: "",
					kerberos_id: "",
					krb_name: "",
					last_name: "",
					mit_id: "",
					mitid: "",
					office_location: "",
					phone_number: "",
					source: "",
				};
			}
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchPin(kerbId?: string): Promise<any> {
		let response = await fetch(`${this.url_pin}${kerbId && kerbId !== "" ? `/${kerbId}` : ``}`, await this.requestHeaderGet());
		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	}

	async createPin(kerbId?: string): Promise<any> {
		let response = await fetch(`${this.url_pin}/create${kerbId && kerbId !== "" ? `/${kerbId}` : ``}`, await this.requestHeaderGet());
		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchPicture(kerbId?: string): Promise<FetchStringResponse> {
		let moreHeaders: any = {};
		let response = await fetch(`${this.url_picture}${kerbId && kerbId !== "" ? `/${kerbId}` : ``}`, await this.requestHeaderGet());
		if (response.status == 200) {
			let data = await response.blob();
			return {
				value: URL.createObjectURL(data),
			};
		} else {
			return {
				error: true,
			};
		}
	}
}

const userController: UserControllerInterface = new UserControllerClass();
export default userController;
