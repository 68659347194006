import React, {FC, useState, useEffect} from 'react'
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import { ActiveModal, MainAppStateInterface } from '../model/MainAppStateStore'
import { ActionList, Button, ButtonType, Error, ErrorContext, ErrorType, ListItemProps, PagingContainer, PagingDotProps, Spacer, TemplateModal, TemplateModalAlignment, TemplateModalSize, Text } from '@mit/hui';
import { LocalStorage, StorageKeys } from '../common/LocalStorage';
import { COVID_TEXTS } from '../common/Texts';
import { InfoOverview, InfoOverviewItems } from 'types/Api';

interface InformationOverviewComponentProps {
	mainAppState?: MainAppStateInterface;
	history?: any;
}

const InformationOverviewComponent: FC<InformationOverviewComponentProps> = ({mainAppState, history}) => {
    const [page, setPage] = useState<InfoOverview>()
    
    useEffect(() => {
        const page = mainAppState!.informationOverview.find(item => item.pageIndex === mainAppState!.informationPage)
        setPage(page)

    },[mainAppState!.informationPage])

    const nextStep = () => {
        let index = mainAppState!.informationPages.indexOf(page!.pageIndex)
        let nextItem;
        if (index >= 0 && index < mainAppState!.informationPages.length - 1) {
            nextItem = mainAppState!.informationPages[index + 1]
            mainAppState!.informationPage = nextItem
        } else {
            if (!LocalStorage.get(StorageKeys.INFO_OVERVIEW)) {
                LocalStorage.save(StorageKeys.INFO_OVERVIEW, 'disabled')
            } 
            mainAppState!.activeModal = ActiveModal.None
        }
        
    }

    let PagingDots: PagingDotProps[] = []
    if (mainAppState!.informationPages) {
        mainAppState!.informationPages.forEach((informationPage: number) => {
            PagingDots.push({
                selected: page?.pageIndex === informationPage,
                onClick: () => mainAppState!.informationPage = informationPage,
                url: ''
            }) 
        });
    }

    let pageItems: ListItemProps[] = []
    if (page) {
        let items = page.items.map((item: InfoOverviewItems) => {
            return {icon: mainAppState!.texts[item.icon_text_id], text: mainAppState!.texts[item.text_id]}
        })
        pageItems = items
    }

    return (
        <>
            <TemplateModal 
                name={'informationOverview'}
                show={mainAppState!.activeModal == ActiveModal.InfoOverview}
                size={TemplateModalSize.Default}
                header={<></>}
                bodyAlignment={TemplateModalAlignment.Left}
                body={
                    page ? (
                        <div className="info">
                            <Text content={mainAppState!.texts[page.title_text_id]} type={'h3'} color={'green'} />
                            <Text content={mainAppState!.texts[page.subtitle_text_id]} type={'body'} bold/>
                            <ActionList items={pageItems} type={'flush'} selectable={false} />
                            <Spacer size={'2'} />
                        </div>
                    ) : (
                        <Error context={ErrorContext.Component} message={`Something went wrong and the information could not be loaded. If the problem persists, please contact ${mainAppState!.texts['covid19.pass.help_email']}.`} type={ErrorType.NoData} />
                    )
                }
                footer={
                    !mainAppState!.informationPageClose && (
                        <>
                            <div className="button text-center">
                            <Button 
                                type={ButtonType.Primary} 
                                text={mainAppState!.informationPage === mainAppState!.informationPages[mainAppState!.informationPages.length - 1] ? `I'm ready` : 'Continue'} 
                                theme={'medical-dark'}
                                onClick={() => nextStep()} />
                            </div>
                            <Spacer size={'2'} />
                            <PagingContainer items={PagingDots} />
                        </>
                    )
                }
                // {...close}
                onClose={() => {
                    if (!LocalStorage.get(StorageKeys.INFO_OVERVIEW)) {
                        LocalStorage.save(StorageKeys.INFO_OVERVIEW, 'disabled')
                        } 
                        mainAppState!.activeModal = ActiveModal.None
                    }
                }
            />
        </>
    )
}

const InformationOverview = withRouter(inject("mainAppState")(observer(InformationOverviewComponent)));

export default InformationOverview;