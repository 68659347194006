import BaseController from "../../api/BaseController";

export interface VaccineWaiverInterface {
    ack_title: string;
    text: string;
    type: 'waiver' | 'analytics';
}

export interface VaccineWaiverResponse {
    waivers?: VaccineWaiverInterface[];
    error?: boolean;
}
class VaccineWaiverApi extends BaseController {
    readonly url_waiver_text_all: string

    constructor() {
        super()

        this.url_waiver_text_all = `${this.apiHost}/${this.apiPathCovid}/vaccine/waiver/text?layout=web`
    }

    async fetchWaiverTextAll():Promise<VaccineWaiverResponse> {
        let response = await fetch(this.url_waiver_text_all, await this.requestHeaderGet())
        if(this.checkStatusCode(response.status)) {
            let data: VaccineWaiverInterface[] = await response.json()
            return {
                waivers: data
            }
        } else {
            return {
                error: true
            }
        }
    }
}

const vaccineWaiverApi = new VaccineWaiverApi()
export default vaccineWaiverApi