import BaseController from "./BaseController";

export interface PersonImpersonate {
    KRB_NAME: string;
    FIRST_NAME: string;
    LAST_NAME: string;
    MIT_ID: string;
}

export interface PersonAws {
    mit_id: string
    kerberos: string;
    first_name: string;
    last_name: string;
    display_name: string;
}

export interface PersonAutocomplete {
    id: number;
    label: string;
    kerberosId: string;
    firstName: string;
    lastName: string;
    displayName: string;
}

export const peopleResponseConverterAws = (people: PersonAws[]): PersonAutocomplete[] => {

    return people.map((awsPerson: PersonAws, index:number) => ({
        id: index + 1,
        mit_id: awsPerson.mit_id,
        label: `${awsPerson.display_name} (${awsPerson.kerberos})`,
        kerberosId: awsPerson.kerberos,
        firstName: awsPerson.first_name,
        lastName: awsPerson.last_name,
        displayName: awsPerson.display_name
    }))
}

export const peopleResponseConverterImpersonate = (people: PersonImpersonate[]): PersonAutocomplete[] => {
    return people.map((awsPerson: PersonImpersonate, index:number) => ({
        id: index + 1,
        label: `${awsPerson.FIRST_NAME} ${awsPerson.LAST_NAME} (${awsPerson.KRB_NAME})`,
        firstName: awsPerson.FIRST_NAME,
        kerberosId: awsPerson.KRB_NAME,
        lastName: awsPerson.LAST_NAME,
        displayName: `${awsPerson.FIRST_NAME} ${awsPerson.LAST_NAME}`
    }))
}

export interface FetchPeopleResponseAws {
    people?: PersonAutocomplete[];
    error?: any;
}

export default class PeopleController extends BaseController {
    readonly url_people: string
    readonly url_impersonate: string

    constructor() {
        super()
        this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
        this.url_impersonate = `${this.apiHost}/${this.apiPathCovid}/impersonate`
    }

    fetchPeopleAws = async (query: string): Promise<FetchPeopleResponseAws> => {
        let response = await fetch(this.url_people + query, await this.requestHeaderGet())
        if (response.status == 200) {
            let people = await response.json()
            if (people) {
                let peopleResults: PersonAutocomplete[] = peopleResponseConverterAws(people)
                return {
                    people: peopleResults
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    fetchPeopleImpersonate = async (): Promise<FetchPeopleResponseAws> => {
        let response = await fetch(this.url_impersonate, await this.requestHeaderGet())
        if (response.status == 200) {
            let people = await response.json()
            if (people) {
                let peopleResults: PersonAutocomplete[] = peopleResponseConverterImpersonate(people)
                return {
                    people: peopleResults
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

}
