import * as React from "react";
import {FC, useEffect, useState} from "react";
import {PageError} from "../../types/PageErrors";
import {withRouter} from "react-router";
import LandingMustSubmit from "./landings/LandingMustSubmit";
import {MainAppStateInterface} from "../../model/MainAppStateStore";
import {inject, observer} from "mobx-react";
import ErrorMessage from "../../common/ErrorMessage";
import {RequirementIds} from "../../types/CovidStatusModel";

interface MainAppProps {
    history: any;
    mainAppState?: MainAppStateInterface;
}

const AttestationLandingComponent: FC<MainAppProps> = ({history, mainAppState}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [pageError, setPageError] = useState<PageError>({error: false, errorText: '', showRetry: false})
    const [texts, setTexts] = useState<any>({})

    useEffect(() => {
        setTexts(mainAppState!.texts)
        setIsLoading(false)
    }, [])

    const showPageError = (): boolean => {
        return !isLoading && pageError.error
    }

    const showSurveyPage = (): boolean => {
        let allPrereqComp = mainAppState!.allPrereqsMet(RequirementIds.attestation)
        return !showPageError() && !showSubmitOnBehalfPage() &&
            allPrereqComp &&
            !mainAppState!.hasMedicalIssue()
    }

    const showSubmitOnBehalfPage = (): boolean => {
        return mainAppState!.isSubmittingOnBehalf && mainAppState!.submittingOnBehalfKerbId === ''
    }

    return <>
        <div className={`submitter-view`}>
            {showPageError() && <ErrorMessage text={pageError.errorText}/>}
            <div className={'nav-container'}>
                <div className={`main-content`}>
                   
                    <div className={`page-content text-align-center`}>
                        {showSurveyPage() && <LandingMustSubmit texts={texts}/>}
                    </div>
                </div>
            </div>
        </div>
    </>
}

const AttestationLanding = withRouter(inject("mainAppState")(observer(AttestationLandingComponent)))
export default AttestationLanding