import BaseController from "../../api/BaseController";

export interface WaiverInterface {
    ack_title: string;
    text: string;
    type: 'waiver' | 'analytics';
}

export interface WaiverResponse {
    waivers?: WaiverInterface[];
    error?: boolean;
}
class WaiverApi extends BaseController {
    readonly url_waiver_text_all: string

    constructor() {
        super()

        this.url_waiver_text_all = `${this.apiHost}/${this.apiPathCovid}/pass/waiver/text-all?layout=web`
    }

    async fetchWaiverTextAll():Promise<WaiverResponse> {
        let response = await fetch(this.url_waiver_text_all, await this.requestHeaderGet())
        if(this.checkStatusCode(response.status)) {
            let data: WaiverInterface[] = await response.json()
            return {
                waivers: data
            }
        } else {
            return {
                error: true
            }
        }
    }
}

const waiverApi = new WaiverApi()
export default waiverApi