import * as React from "react";
import { FC, useState, useEffect } from "react";
import { mainAppState, MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { Text, Button, ButtonState, ActionList, SkeletonLoader, AlertProps, Alert, TemplateModal, TemplateModalAlignment, TemplateModalSize, ButtonType, Container, Icon } from "@mit/hui";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import dataController from "api/DataController";
import { PassHoldInfoModel, RequirementIds } from "types/CovidStatusModel";
import moment from "moment";
import { FetchPassStatusResponse } from "types/Api";
import Barcode from "../medical-test/Barcode";
import ReactMarkdown from "react-markdown";

interface MedicalSymptomsProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const HoldSymptomsComponent: FC<MedicalSymptomsProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [isRefreshLoading, setIsRefreshLoading] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [message, setMessage] = useState<AlertProps>({ text: "" });
	const [holdInfo, setHoldInfo] = useState<PassHoldInfoModel | undefined>();

	const getHoldInfo = (isRefresh?: boolean) => {
		isRefresh ? setIsRefreshLoading(true) : setIsLoading(true);

		dataController
			.fetchPassHoldInfo()
			.then((res) => {
				if (res.status_model) {
					setHoldInfo(res.status_model);
				}

				if (isRefresh) {
					dataController.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId).then((statusResponse: FetchPassStatusResponse) => {
						if (!statusResponse.error) {
							mainAppState!.updatePersonStatus(statusResponse);
						}
					});
					setMessage({ text: "Successfully refreshed status", type: "success" });

					setTimeout(() => {
						setMessage({ text: "" });
					}, 5000);
				}
			})
			.finally(() => {
				setIsLoading(false);
				setIsRefreshLoading(false);
			});
	};

	const endIsolation = () => {
		setIsBusy(true);
		dataController
			.putPassStatus(6)
			.then(() => {
				dataController.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId).then((statusResponse: FetchPassStatusResponse) => {
					if (!statusResponse.error) {
						mainAppState!.updatePersonStatus(statusResponse);

						setMessage({ text: "Successfully updated status", type: "success" });

						setTimeout(() => {
							setMessage({ text: "" });
						}, 5000);
					} else {
						setMessage({ text: statusResponse.error, type: "error" });

						setTimeout(() => {
							setMessage({ text: "" });
						}, 5000);
					}

					setIsBusy(false);
					setShowConfirmModal(false);
				});
			})
			.catch((e) => {
				setIsBusy(false);
				setShowConfirmModal(false);
				setMessage({ text: "Something went wrong", type: "error" });

				setTimeout(() => {
					setMessage({ text: "" });
				}, 5000);
			});
	};

	useEffect(() => {
		getHoldInfo();
	}, []);

	useEffect(() => {
		if (mainAppState!.reload) getHoldInfo();
	}, [mainAppState!.reload]);

	const getMarkupText = () => {
		return mainAppState!.texts[holdInfo?.info_text_id ?? ""]?.replace(
			"{date}",
			`<strong>${holdInfo?.test_date?.toString() ? moment(holdInfo?.test_date?.toString()).format("MMM D, YYYY") : "no date"}</strong>`
		);
	};

	const isLinconLabs = (): boolean => {
		return mainAppState!.status_model.assignment_type === 9 
	}

	const isMedicalVisible = (): boolean => {
		return mainAppState!.getRequirement(RequirementIds.medical).visible
	}

	const MedicalTest = (): JSX.Element => {
		return <>
			<Container
				contents={
					<div className="m-1" style={{cursor: "pointer"}} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Medical}>
						<div className="row align-items-center" style={{width: "100%", margin: "0 auto"}}>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-start">
									<Icon icon="microscope" type="regular" />
								</div>
							</div>
							<div className="col-sm-10">
								<div className="py-2 d-flex flex-column text-lg-left text-center no-margin">
									<Text content={mainAppState!.texts["covid19.pass.testing.title"]} bold type={"h4"} />
									<Text content={mainAppState!.texts["covid19.pass.testing.info"] ?? "Unobserved testing and history"} type={"body"} />
								</div>
							</div>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-end">
									<Icon icon="chevron-right" type="regular" />
								</div>
							</div>
						</div>
					</div>
				}
				raised={false}
				padding={1}
			/>
		</>
	}

	const MedicalHistory = (): JSX.Element => {
		return <>
			<Container
				contents={
					<div className="m-1" style={{cursor: "pointer"}} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.TestingHistory}>
						<div className="row align-items-center" style={{width: "100%", margin: "0 auto"}}>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-start">
									<Icon icon="microscope" type="regular" />
								</div>
							</div>
							<div className="col-sm-10">
								<div className="py-2 d-flex flex-column text-lg-left text-center no-margin">
									<Text content={mainAppState!.texts["covid19.medical.results.button"]} bold type={"h4"} />
									<Text content={mainAppState!.texts["covid19.pass.testing.history-info"] ?? "Unobserved testing history"} type={"body"} />
								</div>
							</div>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-end">
									<Icon icon="chevron-right" type="regular" />
								</div>
							</div>
						</div>
					</div>
				}
				raised={false}
				padding={1}
			/>
		</>
	}

	return (
		<>
			<div className="hold-container container p-4">
				{message.text && <Alert {...message} />}
				{isLoading ? (
					<SkeletonLoader type="list" />
				) : (
					<>
						<Text content={mainAppState!.texts[holdInfo?.title_text_id ?? ""]?.replace("{day}", holdInfo?.isolation_day?.toString() ?? "0")} type={"h1"} />
						<p dangerouslySetInnerHTML={{ __html: getMarkupText() }}></p>
						<a className="mt-n3 d-block" style={{ cursor: "pointer" }} href={mainAppState!.texts["covid19.hold.info.link.url"] ?? ""} target="_blank">
							{mainAppState!.texts["covid19.hold.info.link.text"] ?? "Further CDC guidelines on isolation"}
						</a>
						{mainAppState!.status_model?.hold_reason === "positive_test" && holdInfo?.isolation_day && holdInfo?.isolation_day < 6 && (
							<div className="mt-2">
								{mainAppState?.status_model.assignment_type !== 9 && (
									<div
										style={{ cursor: "pointer" }}
										onClick={() => {
											if (mainAppState?.texts["covid19.notsafe.link.url"]) window.open(mainAppState?.texts["covid19.notsafe.link.url"]);
										}}>
										<ReactMarkdown linkTarget={"_blank"} children={mainAppState?.texts["covid19.notsafe.link.markdowntext"] ?? ""} />
									</div>
								)}
							</div>
						)}
						<div className="py-2 pt-3">
							{mainAppState!.status_model.scheduled_to_clear ? (
								<>
									<Container
										padding={3}
										raised={false}
										className="schedule-to-clear"
										contents={
											<div className="container">
												<div className="d-flex flex-column align-items-center">
													<div style={{ fontSize: "2rem" }}>
														<Icon icon="clock" type="regular" />
													</div>
													<div>
														<Text content={mainAppState?.texts["covid19.hold.info.scheduled.title"] ?? "Scheduled to clear"} type={"h3"} />
													</div>
													<div className="text-center">
														<Text
															content={
																mainAppState?.texts["covid19.hold.info.scheduled.info"] ??
																"Please allow up to 30 minutes for your campus hold to clear and your campus access to be restored."
															}
														/>
													</div>
												</div>
											</div>
										}
									/>
								</>
							) : (
								<>
									{holdInfo?.instruction_list?.map((x, index) => {
										return (
											<>
												<div className={`row py-2 day-selected ${x.selected ? "day-selected" : "day-unselected "}`}>
													<div className="col-2">
														<span>{x.period}</span>
													</div>
													<div className="col-10">
														<div className="row">
															<div className="">
																<i className={`fas fa-${mainAppState!.texts[x.icon_id ?? ""]}`}></i>
															</div>
															<div className="col-11">
																<span>{mainAppState!.texts[x.description_text_id ?? ""]}</span>
															</div>
														</div>
													</div>
												</div>
												{index !== holdInfo.instruction_list.length - 1 && <hr />}
											</>
										);
									})}
								</>
							)}
						</div>
						<div className="py-2 pb-3">
							<div className="d-flex justify-content-center full-width">
								{holdInfo?.end_isolation_enabled && !mainAppState?.status_model.scheduled_to_clear && (
									<Button
										state={mainAppState!.status_model.scheduled_to_clear ? ButtonState.Disabled : ButtonState.Enabled}
										onClick={() => setShowConfirmModal(true)}
										text={mainAppState!.texts["covid19.hold.info.submit.button.title"]}
									/>
								)}
							</div>
							{/* <div className="d-flex justify-content-center">
								<Button
									isBusy={isRefreshLoading}
									type={ButtonType.TextNaked}
									state={isRefreshLoading ? ButtonState.Disabled : ButtonState.Enabled}
									icon="sync-alt"
									text="Refresh my status"
									onClick={() => getHoldInfo(true)}
								/>
							</div> */}
						</div>

						{isMedicalVisible() ? (
							<MedicalTest />
						) : (
							<MedicalHistory />
						)}

						<div className="d-flex justify-content-center m-2">
							<Text
								content={
									mainAppState!.texts["covid19.hold.info.report.test.button.info"] ??
									"If you have tested positive outside of MIT, please report it in order to update your isolation timeline."
								}
							/>
						</div>
						
						{!isLinconLabs() && (
							<div className="report-btn">
								<Button type={ButtonType.Primary} text={mainAppState!.texts["covid19.pass.notify_mit.action"] ?? "I need to report..."} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.ReportOptions} />
							</div>
						)}
					</>
				)}
			</div>
			{mainAppState!.features["covid19.app.medical.always.show.barcode"] && mainAppState!.status_model?.hold_reason === "contact" && <Barcode />}
			<TemplateModal
				show={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				body={
					<>
						<Text content={mainAppState!.texts["covid19.hold.info.submit.popup.message"]} />
						<hr />
						<div className="d-flex justify-content-end">
							<div className="btn-padded">
								<Button overwriteColor="danger" outlined onClick={() => setShowConfirmModal(false)} text={mainAppState!.texts["covid19.hold.info.submit.popup.cancel"] ?? "Cancel"} />
								<Button
									isBusy={isBusy}
									state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
									onClick={endIsolation}
									text={mainAppState!.texts["covid19.hold.info.submit.popup.submit"] ?? "I Agree"}
								/>
							</div>
						</div>
					</>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				size={TemplateModalSize.Default}
				footer={<></>}
				header={<Text content={mainAppState!.texts["covid19.hold.info.submit.popup.title"]} type={"h3"} />}
				name="end-isolation"
				theme="medical"
			/>
		</>
	);
};

const HoldSymptoms = inject("mainAppState")(observer(HoldSymptomsComponent));
export default HoldSymptoms;
