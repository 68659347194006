import * as React from "react";
import { inject, observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { ActiveDrawer, MainAppStateInterface } from "../../model/MainAppStateStore";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import {
	Text,
	Drawer,
	DrawerSize,
	DrawerType,
	DrawerLayout,
	Icon,
	Form,
	FormContainer,
	FormField,
	Button,
	TemplateModal,
	TemplateModalAlignment,
	ButtonState,
	Container,
	Spacer,
	AlertProps,
	Alert,
} from "@mit/hui";
import moment from "moment";
import dataController from "api/DataController";
import ReactMarkdown from "react-markdown";

interface ReportPositiveTestProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	show: boolean;
	onClose: () => void;
}

const ReportCloseContactComponent: FC<ReportPositiveTestProps> = ({ mainAppState, userProfileStoreState, show, onClose }) => {
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<AlertProps>({ text: "" });

	return (
		<>
			<Drawer
				show={show}
				onClose={onClose}
				themedColor="medical"
				size={DrawerSize.Small}
				footer={[]}
				type={DrawerType.Right}
				layout={DrawerLayout.Hero}
				header={
					<div className={"inline-heading"}>
						<Text content={"Close Contact"} type={"h3"} />
					</div>
				}
				contents={
					<>
						{alertMessage.text && <Alert {...alertMessage} />}
						<div>
							{(mainAppState!.vaccineStatus?.status === "full" || mainAppState!.vaccineStatus?.status === "complete" || mainAppState!.vaccineStatus?.status === "completed") &&
							mainAppState!.vaccineStatus.booster.valid &&
							!mainAppState!.vaccineStatus?.booster.exemption ? (
								<Container
									contents={
										<>
											<div className="d-flex flex-column text-center">
												<Text content={mainAppState!.texts["covid19.notify-mit.contact.title"] ?? "Close contacts who do not feel symptoms:"} type={"h4"} />
												<ReactMarkdown
													children={
														mainAppState!.texts["covid19.notify-mit.contact.infomarkdown"] ??
														"You do not need to quarantine unless you develop symptoms, your campus access will remain in place\n\n\n* Get tested at least 5 days after you last had close contact.\n* Watch for symptoms until 10 days after you last had close contact. If you develop symptoms isolate immediately and get tested.\n* Wear a mask for 10 days any time you are around others."
													}
												/>
											</div>
										</>
									}
									padding={5}
									raised={false}
								/>
							) : (
								<>
									<Text content={"Contact date"} type={"h3"} />
									<Form formValidationTrigger="onSubmit">
										{(methods) => {
											const onCheck = () => {
												methods.trigger().then((valid) => {
													if (!valid) return;

													let data = methods.getValues();
													setIsBusy(true);
													dataController
														.postContactTracingCloseContact(data.dateOfContact)
														.then(() => {
															onClose();
															mainAppState!.activeDrawer = ActiveDrawer.None;
															mainAppState!.reload = true;
														})
														.catch((e: any) => {
															setAlertMessage({ text: e, type: "error" });

															setTimeout(() => {
																setAlertMessage({ text: "", type: "success" });
															}, 5000);
														})
														.finally(() => {
															setIsBusy(false);
														});
												});
											};

											return (
												<>
													<FormContainer id="close-contact-form" formValidationTrigger="onSubmit" methods={methods}>
														<div>
															<FormField
																maxDate={{
																	year: parseInt(moment().format("YYYY")),
																	month: parseInt(moment().format("M")),
																	day: parseInt(moment().format("D")),
																}}
																editor="calendar"
																label="Date of contact"
																id="dateOfContact"
																value={moment().format("YYYY-MM-DD")}
																required
															/>
														</div>
													</FormContainer>
													<div>
														<ReactMarkdown
															children={
																mainAppState!.texts["covid19.notify-mit.contact.instructions"]?.replace(
																	"{date}",
																	moment().subtract(10, "days").format("MMM D, YYYY")
																) ??
																"Close contact with someone without being vaccinated or boosted status being up to date puts you at risk and you will have to isolate for safety reasons."
															}
														/>
													</div>
													<div className="d-flex justify-content-end">
														<Button text="Submit" onClick={onCheck} isBusy={isBusy} state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} />
													</div>
												</>
											);
										}}
									</Form>
								</>
							)}
						</div>
					</>
				}
			/>
		</>
	);
};

const ReportCloseContactDrawer = inject("mainAppState", "userProfileStoreState")(observer(ReportCloseContactComponent));
export default ReportCloseContactDrawer;
