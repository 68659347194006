import * as React from "react";
import { FC } from "react";
import PrintButton from "../attestation/PrintButton";
import RetakeSurveyButton from "../attestation/RetakeSurveyButton";
import { inject, observer, Provider } from "mobx-react";
import { ActiveDrawer, mainAppState, MainAppStateInterface } from "../../model/MainAppStateStore";
import { useState } from "react";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import { Dropdown as BDropdown, DropdownButton } from "react-bootstrap";
import ReportPositiveTestDrawer from "./ReportPositiveTestDrawer";
import ReportCloseContactDrawer from "./ReportCloseContactDrawer";
import ReportCovidSymptomsModal from "./ReportCovidSymptomsModal";
import { RequirementIds } from "types/CovidStatusModel";
import { Button, Icon, Spacer, Text } from "@mit/hui";

interface Props {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const INeedToComponent: FC<Props> = ({ mainAppState, userProfileStoreState }) => {
	const [showReportPositiveTestDrawer, setShowReportPositiveTestDrawer] = useState<boolean>(false);
	const [showReportCloseContactDrawer, setShowReportCloseContactDrawer] = useState<boolean>(false);
	const [showReportCovidSymptomsModal, setShowReportCovidSymptomsModal] = useState<boolean>(false);

	return (
		<div className="col-lg-6 col-lg-9 mx-auto m-2 p-2">
			<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
				<div className="row justify-content-center">
					{mainAppState!.getRequirement(RequirementIds.attestation)?.visible && mainAppState!.getRequirement(RequirementIds.attestation)?.required && (
						<>
							<Button icon="clipboard-list-check" text={mainAppState!.texts["covid19.pass.retake"]} type={"textNaked"} onClick={() => (mainAppState!.activeDrawer = ActiveDrawer.Attestation)} />
						</>
					)}
					{mainAppState!.status_model?.assignment_type !== 9 && (
						<>
							<Button icon="vial" text="Report Manual Test" type={"textNaked"} onClick={() => setShowReportPositiveTestDrawer(true)} />
							<Button icon="virus" text="Report Covid Symptoms" type={"textNaked"} onClick={() => setShowReportCovidSymptomsModal(true)} />
							<Button icon="people-arrows" text="Report Close Contact" type={"textNaked"} onClick={() => setShowReportCloseContactDrawer(true)} />
						</>
					)}
				</div>
				{/* <div className="m-2 d-flex justify-content-center align-items-center">
					<div className="w-50">
						<DropdownButton className="d-flex justify-content-center align-items-center" variant="outline-primary" title="I need to...">
							{mainAppState!.getRequirement(RequirementIds.attestation)?.visible && mainAppState!.getRequirement(RequirementIds.attestation)?.required && (
								<BDropdown.Item
									onClick={() => {
										mainAppState!.activeDrawer = ActiveDrawer.Attestation;
									}}>
									{mainAppState!.texts["covid19.pass.retake"] ?? "Retake Attestation"}
								</BDropdown.Item>
							)}
							{mainAppState!.status_model?.assignment_type !== 9 && (
								<>
									<BDropdown.Item
										onClick={() => {
											setShowReportPositiveTestDrawer(true);
										}}>
										Report Positive Covid Results
									</BDropdown.Item>
									<BDropdown.Item
										onClick={() => {
											setShowReportCovidSymptomsModal(true);
										}}>
										Report Covid Symptoms
									</BDropdown.Item>
									<BDropdown.Item
										onClick={() => {
											setShowReportCloseContactDrawer(true);
										}}>
										Report Close Contact
									</BDropdown.Item>
								</>
							)}
						</DropdownButton>
					</div>
				</div> */}
				<ReportPositiveTestDrawer show={showReportPositiveTestDrawer} onClose={() => setShowReportPositiveTestDrawer(false)} />
				<ReportCloseContactDrawer show={showReportCloseContactDrawer} onClose={() => setShowReportCloseContactDrawer(false)} />
				<ReportCovidSymptomsModal show={showReportCovidSymptomsModal} onClose={() => setShowReportCovidSymptomsModal(false)} />
			</Provider>
		</div>
	);
};

const INeedToDropdown = inject("mainAppState", "userProfileStoreState")(observer(INeedToComponent));
export default INeedToDropdown;
