import * as React from "react";
import { FC, useState, useImperativeHandle } from "react";
import "../scss/app.scss";
import {
	Badge,
	BasicLayout,
	Button,
	ButtonType,
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	Icon,
	MobilePromo,
	NavBar,
	NavBarTypes,
	NavItemProps,
	NavItemState,
	PagingContainer,
	Spacer,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	Text,
	TextType,
} from "@mit/hui";
import { inject, observer, Provider } from "mobx-react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { ActiveDrawer, ActiveModal, MainAppStateInterface } from "../model/MainAppStateStore";
import VaccineWaiverLanding from "./vaccine_waiver/VaccineWaiverLanding";
import WaiverLanding from "./waiver/WaiverLanding";
import MedicalLanding from "./medical-test/MedicalLanding";
import TrainingLanding from "./training/TrainingLanding";
import AttestationLanding from "./attestation/AttestationLanding";
import { UserProfileStoreInterface } from "../model/UserProfileStore";
import AppLanding from "./AppLanding";
import { PassStatus, RequirementIds, RequirementStatus } from "../types/CovidStatusModel";
import ReleaseNotesComponent from "./ReleaseNotes";
import { COVID_TEXTS } from "../common/Texts";
import dataController from "../api/DataController";
import VaccineSurvey from "./vaccine/VaccineSurvey";
import MedicalAssist from "./medical-test/MedicalAssist";
import UnobservedTesting from "./medical-test/UnobservedTesting";
import PersonTests from "./medical-test/PersonTests";
import moment from "moment";
import InformationOverview from "./InformationOverview";
import { LocalStorage, StorageKeys } from "../common/LocalStorage";
import { ordinalsDate } from "../common/Formatters";
import { FetchPassStatusResponse } from "types/Api";
import { isNavStateEnabled } from "common/Extentions";
import INeedToDropdown from "./dashboard/INeedToDropdown";

interface MyselfComponentProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	history?: any;
}

const MyselfComponent: FC<MyselfComponentProps> = ({ mainAppState, userProfileStoreState, history }) => {
	const [isShowingResidentialPopup, setIsShowingResidentialPopup] = useState(false);
	const [isShowingConfirmationPopup, setIsShowingConfirmationPopup] = useState(false);

	const getTestCompleted = () => {
		dataController
			.getUnobservedStatus()
			.then((resp) => {
				mainAppState!.updateUnobservedStatus(resp.data);
				mainAppState!.reload = true;
			})
			.catch((err) => console.log(err))
			.finally(() => {
				mainAppState!.activeDrawer = ActiveDrawer.Medical;
			});
	};

	const isVisible = (reqId: RequirementIds): boolean => {
		if (mainAppState!.status_model.status === PassStatus.no_access_with_testing) {
			return false;
		} else if (mainAppState && mainAppState.getRequirement(reqId) && mainAppState.getRequirement(reqId)!.visible) {
			return true;
		} else {
			return false;
		}
	};

	const closeDrawer = () => {
		mainAppState!.activeDrawer = ActiveDrawer.None;
		setIsShowingConfirmationPopup(false);
	};

	const acknowledgementClose = () => {
		if (IsNoAccessWithTesting()) {
			return;
		}

		let requirement = mainAppState!.getRequirement(RequirementIds.acknowledge);

		if (!mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.complete) && requirement != undefined && requirement.required) {
			setIsShowingConfirmationPopup(true);
		} else {
			mainAppState!.activeDrawer = ActiveDrawer.None;
		}
	};

	const vaccineAcknowledgementClose = () => {
		mainAppState!.activeDrawer = ActiveDrawer.None;

		dataController.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId).then((statusResponse: FetchPassStatusResponse) => {
			if (!statusResponse.error) {
				mainAppState!.updatePersonStatus(statusResponse);
			}
		});
	};

	const vaccineSurveyClose = () => {
		mainAppState!.activeDrawer = ActiveDrawer.None;
	};

	const attestationClose = () => {
		let requirement = mainAppState!.getRequirement(RequirementIds.attestation);

		if (requirement != undefined && requirement.required) {
			setIsShowingConfirmationPopup(true);
		} else {
			mainAppState!.activeDrawer = ActiveDrawer.None;
		}
	};

	const confirmResidential = (isResiding: boolean) => {
		setIsShowingResidentialPopup(false);

		const abortController = new AbortController();
		dataController.fetchResidentialConfirmation(abortController.signal, isResiding).then((resp) => {});

		return () => {
			abortController.abort();
		};
	};

	const getMenuState = (requirement: RequirementIds): NavItemState => {
		let state = isNavStateEnabled(requirement, mainAppState!) ? NavItemState.None : NavItemState.Disabled;

		if (!isVisible(requirement)) return NavItemState.Invisible;

		return state;
	};

	/** This is code to make nav a bit more dynamic */
	// const myselfTabItems: NavItemProps[] = []
	// if (mainAppState!.status_model && mainAppState!.status_model.requirements) {
	// 	mainAppState!.status_model.requirements.forEach(requirement => {
	// 		myselfTabItems.push(		{
	// 			icon: mainAppState!.evalRequirement(requirement.id, requirement.status) ? "check-circle" : "clipboard-check",
	// 			iconStyle: mainAppState!.evalRequirement(requirement.id, requirement.status) ? "solid" : "light",
	// 			text: mainAppState!.getRequirement(requirement.id).title_web,
	// 			iconOnly: false,
	// 			onClick: () => {
	// 				if (requirement.id === 'acknowledge') mainAppState!.activeDrawer = ActiveDrawer.Acknowledgement;
	// 				if (requirement.id === 'training') mainAppState!.activeDrawer = ActiveDrawer.Training;
	// 				if (requirement.id === 'medical') mainAppState!.activeDrawer = ActiveDrawer.Medical;
	// 				if (requirement.id === 'attestation') mainAppState!.activeDrawer = ActiveDrawer.Attestation;
	// 			},
	// 			state: getMenuState(requirement.id),
	// 		},)
	// 	});
	// }
	let myselfTabItems: NavItemProps[] = [];

	// in the case of no access, requirements are empty, need to first check it
	if (mainAppState!.status_model.requirements !== undefined && mainAppState!.status_model.requirements.length > 0) {
		myselfTabItems = [
			{
				icon: mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.complete) ? "check-circle" : "clipboard-check",
				iconStyle: mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.complete) ? "solid" : "light",
				text: mainAppState!.getRequirement(RequirementIds.acknowledge).title_web,
				iconOnly: false,
				onClick: () => {
					mainAppState!.activeDrawer = ActiveDrawer.Acknowledgement;
				},
				state: getMenuState(RequirementIds.acknowledge),
			},
			{
				icon: mainAppState!.evalRequirement(RequirementIds.training, RequirementStatus.complete) ? "check-circle" : "books",
				iconStyle: mainAppState!.evalRequirement(RequirementIds.training, RequirementStatus.complete) ? "solid" : "light",
				text: mainAppState!.getRequirement(RequirementIds.training).title_web,
				iconOnly: false,
				onClick: () => {
					mainAppState!.activeDrawer = ActiveDrawer.Training;
				},
				state: getMenuState(RequirementIds.training),
			},
			{
				icon: mainAppState!.evalRequirement(RequirementIds.medical, RequirementStatus.complete)
					? "check-circle"
					: mainAppState!.evalRequirement(RequirementIds.medical, RequirementStatus.pending)
					? "hourglass"
					: "microscope",
				iconStyle: mainAppState!.evalRequirement(RequirementIds.medical, RequirementStatus.complete) ? "solid" : "light",
				text: mainAppState!.getRequirement(RequirementIds.medical).title_web,
				iconOnly: false,
				onClick: () => {
					mainAppState!.activeDrawer = ActiveDrawer.Medical;
				},
				state: getMenuState(RequirementIds.medical),
			},
			{
				icon: mainAppState!.evalRequirement(RequirementIds.attestation, RequirementStatus.complete) ? "check-circle" : "clipboard-list-check",
				iconStyle: mainAppState!.evalRequirement(RequirementIds.attestation, RequirementStatus.complete) ? "solid" : "light",
				text: mainAppState!.getRequirement(RequirementIds.attestation).title_web,
				iconOnly: false,
				onClick: () => {
					mainAppState!.activeDrawer = ActiveDrawer.Attestation;
				},
				state: getMenuState(RequirementIds.attestation),
			},
		];
	}

	const IsNoAccessWithTesting = () => {
		if (
			mainAppState!.status_model.status === PassStatus.no_access_with_testing &&
			mainAppState!.getRequirement(RequirementIds.acknowledge).required &&
			mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.incomplete)
		) {
			return true;
		}

		return false;
	};

	const medicalStatusBadge = (): React.ReactNode => {
		const medicalRequirement = mainAppState!.getRequirement(RequirementIds.medical);
		if (medicalRequirement === undefined) {
			return null;
		}
		const status = medicalRequirement.status;
		const last_completion_result = medicalRequirement.last_completion_result;
		const last_completion = medicalRequirement.last_completion;
		const date = medicalRequirement.last_completion ?? "";

		//Can't Retest
		if (!mainAppState!.status_model.can_retest)
			return (
				<div>
					<Badge type={"success"} content={mainAppState!.texts["covid19.cant_retest.badge"] ?? "No testing"} />
				</div>
			);

		//Max count reached
		if (mainAppState!.status_model.max_test_count_reached)
			return (
				<div>
					<Badge type={"success"} content={mainAppState!.texts["covid19.max_test.badge"] ?? "Max test count reached"} />
				</div>
			);

		//Optional Testing
		if (!mainAppState!.getRequirement(RequirementIds.medical)?.required && mainAppState!.getRequirement(RequirementIds.medical)?.visible)
			return (
				<div>
					<Badge type={"success"} content={mainAppState!.texts["covid19.optional_test.badge"] ?? "Testing is optional"} />
				</div>
			);

		const medicalStatus: any = {
			complete: () => {
				if (last_completion_result === "negative")
					return (
						<div>
							<Badge type={"success"} content={mainAppState!.texts["covid19.pass.medical.status_badge.complete_neg"].replace("{date}", ordinalsDate(date))} />
						</div>
					);
				if (last_completion_result === "positive")
					return (
						<div>
							<Badge type={"success"} content={mainAppState!.texts["covid19.pass.medical.status_badge.complete_pos"].replace("{date}", ordinalsDate(date))} />
						</div>
					);
				if (last_completion_result === "inconclusive")
					return (
						<div>
							<Badge type={"success"} content={mainAppState!.texts["covid19.pass.medical.status_badge.complete_incon"].replace("{date}", ordinalsDate(date))} />
						</div>
					);
				if (last_completion_result === null && last_completion !== null)
					return (
						<div>
							<Badge type={"info"} content={mainAppState!.texts["covid19.pass.medical.status_badge.pending"].replace("{date}", ordinalsDate(date!))} />
						</div>
					);

				return (
					<div>
						<Badge type={"success"} content={mainAppState!.texts["covid19.pass.medical.status_badge.complete"].replace("{date}", ordinalsDate(date))} />
					</div>
				);
			},
			incomplete: () => {
				return (
					<div>
						<Badge type={"warning"} content={mainAppState!.texts["covid19.pass.medical.status_badge.incomplete"]} />
					</div>
				);
			},
			due_soon: () => {
				return (
					<div>
						<Badge type={"danger"} content={mainAppState!.texts["covid19.pass.medical.status_badge.due_soon"]} />
					</div>
				);
			},
			overdue_grace: () => {
				return (
					<div>
						<Badge type={"warning"} content={mainAppState!.texts["covid19.pass.medical.status_badge.overdue_grace"]} />
					</div>
				);
			},
			pending: () => {
				return (
					<div>
						<Badge type={"info"} content={mainAppState!.texts["covid19.pass.medical.status_badge.pending"].replace("{date}", ordinalsDate(date!))} />
					</div>
				);
			},
		};

		if (mainAppState!.getRequirement(RequirementIds.medical).required) return status && medicalStatus[status]();
		else return null;
	};

	const medicalStatusText = (): any => {
		let medicalRequirement = mainAppState!.getRequirement(RequirementIds.medical);

		if (medicalRequirement === undefined) {
			return null;
		}

		if (!mainAppState!.getRequirement(RequirementIds.medical)?.required && mainAppState!.getRequirement(RequirementIds.medical)?.visible) return null;

		if (mainAppState!.status_model.max_test_count_reached)
			return (
				<>
					{mainAppState!.getRequirement(RequirementIds.medical)?.status === RequirementStatus.complete &&
						mainAppState!.getRequirement(RequirementIds.medical)?.required &&
						mainAppState!.getRequirement(RequirementIds.medical)?.visible && (
							<p>
								{mainAppState!.texts["covid19.medical.completed.test.until"]?.replace("{date}", "")}{" "}
								<strong>{moment(mainAppState!.getRequirement(RequirementIds.medical)?.next_completion ?? "").format("MMM D, YYYY")}</strong>
							</p>
						)}
				</>
			);

		let status = medicalRequirement.status;
		const testOutcome = medicalRequirement.medical_awaiting_test_outcome;
		const nextCompletionDate = medicalRequirement.next_completion ?? "";
		const nextCompletion = medicalRequirement.next_completion;
		const medicalText: any = {
			complete: () => {
				if (!!nextCompletion) {
					return (
						<>
							<Text content={mainAppState!.texts["covid19.pass.medical.status.complete.title"].replace("{date}", ordinalsDate(nextCompletionDate))} type={"h4"} />
							<Spacer size={"1"} />
							<Text content={mainAppState!.texts["covid19.pass.medical.status.complete.text"]} type={"body"} />
						</>
					);
				}
			},
			incomplete: () => {
				return (
					<>
						<Text content={mainAppState!.texts["covid19.pass.medical.status.incomplete.title"]} type={"h4"} />
						<Spacer size={"1"} />
						<Text content={mainAppState!.texts["covid19.pass.medical.status.incomplete.text"]} type={"body"} />
					</>
				);
			},
			due_soon: () => {
				return (
					<>
						<Text content={mainAppState!.texts["covid19.pass.medical.status.due_soon.title"].replace("{date}", ordinalsDate(nextCompletionDate))} type={"h4"} />
						<Spacer size={"1"} />
						<Text content={mainAppState!.texts["covid19.pass.medical.status.due_soon.text"]} type={"body"} />
					</>
				);
			},
			overdue_grace: () => {
				return (
					<>
						<Text content={mainAppState!.texts["covid19.pass.medical.status.overdue_grace.title"].replace("{date}", ordinalsDate(nextCompletionDate))} type={"h4"} />
						<Spacer size={"1"} />
						<Text content={mainAppState!.texts["covid19.pass.medical.status.overdue_grace.text"]} type={"body"} />
					</>
				);
			},
			pending: () => {
				return testOutcome ? (
					<>
						<Text content={mainAppState!.texts["covid19.pass.medical.status.await_outcome.title"]} type={"h4"} />
						<Spacer size={"1"} />
						<Text content={mainAppState!.texts["covid19.pass.medical.status.await_outcome.text"]} type={"body"} />
					</>
				) : (
					<>
						<Text content={mainAppState!.texts["covid19.pass.medical.status.pending.title"].replace("{date}", ordinalsDate(nextCompletionDate))} type={"h4"} />
						<Spacer size={"1"} />
						<Text content={mainAppState!.texts["covid19.pass.medical.status.pending.text"]} type={"body"} />
					</>
				);
			},
		};

		return status ? medicalText[status]() : null;
	};

	const onboardingInfoSteps = () => {
		let infoSteps: any[number] = [];
		if (mainAppState!.isRequirementMandatory(RequirementIds.acknowledge) && isVisible(RequirementIds.acknowledge)) {
			infoSteps.push(mainAppState!.informationOverview.find((item) => item.id.includes("overview"))!.pageIndex);
		}
		if (mainAppState!.isRequirementMandatory(RequirementIds.training) && isVisible(RequirementIds.training)) {
			infoSteps.push(mainAppState!.informationOverview.find((item) => item.id.includes(RequirementIds.training))!.pageIndex);
		}
		if (mainAppState!.isRequirementMandatory(RequirementIds.medical) && isVisible(RequirementIds.medical)) {
			infoSteps.push(mainAppState!.informationOverview.find((item) => item.id.includes(RequirementIds.medical))!.pageIndex);
		}
		if (mainAppState!.isRequirementMandatory(RequirementIds.attestation) && isVisible(RequirementIds.attestation)) {
			infoSteps.push(mainAppState!.informationOverview.find((item) => item.id.includes(RequirementIds.attestation))!.pageIndex);
		}
		mainAppState!.informationPages = infoSteps;
	};

	const overviewPage = (page: string) => {
		mainAppState!.informationPageClose = true;
		const selectedPage = mainAppState!.informationOverview.find((item) => item.id === page);
		if (selectedPage) mainAppState!.informationPage = selectedPage.pageIndex;
		mainAppState!.activeModal = ActiveModal.InfoOverview;
	};

	React.useEffect(() => {
		if (window.location.href.indexOf("/residential-confirmation") > -1) {
			setIsShowingResidentialPopup(true);
		}

		if (window.location.href.indexOf("/vaccine-survey") > -1) {
			mainAppState!.activeDrawer = ActiveDrawer.VaccineSurvey;
		}

		if (IsNoAccessWithTesting()) {
			mainAppState!.activeDrawer = ActiveDrawer.Acknowledgement;
		}

		if (mainAppState!.informationOverview.length >= 1) {
			if (!LocalStorage.get(StorageKeys.INFO_OVERVIEW)) {
				mainAppState!.activeModal = ActiveModal.InfoOverview;
			} else {
				LocalStorage.get(StorageKeys.INFO_OVERVIEW);
			}
			onboardingInfoSteps();
		}
	}, []);

	React.useEffect(() => {
		if (mainAppState!.reload) {
			const statusPromise = dataController.fetchPassStatus();
			const unobservedStatusPromise = dataController.getUnobservedStatus();

			Promise.all([statusPromise, unobservedStatusPromise])
				.then(([statusResponse, unobservedStatusResponse]) => {
					try {
						// Checking if status loaded
						if (statusResponse.status_model === null) {
							return null;
						} else {
							if (statusResponse.error) {
								return null;
							} else {
								if (statusResponse.status_model!.status === null) {
									return null;
								}
							}
						}
						mainAppState!.reload = false;
						mainAppState!.unobservedStatus = unobservedStatusResponse;

						if (!!unobservedStatusResponse.data.barcode && !unobservedStatusResponse.data.canSubmit) {
							mainAppState!.unobservedTestDone = true;
						} else {
							mainAppState!.unobservedTestDone = false;
						}

						// have a null check up higher
						if (!statusResponse.error) {
							mainAppState!.updatePersonStatus(statusResponse);
						} else {
							return null;
						}
					} catch (exception) {
						console.error(exception);
					}
				})
				.catch((error: any) => {
					mainAppState!.reload = false;
					console.error(error);
				});
		}
	}, [mainAppState!.reload]);

	const isAttestationHidden = () => {
		return !mainAppState!.getRequirement(RequirementIds.attestation)?.visible && !mainAppState!.getRequirement(RequirementIds.attestation)?.required;
	};

	return (
		<div>
			<BasicLayout
				startState
				content={
					<>
						<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
							<AppLanding />
						</Provider>
					</>
				}
				contentTitle={""}
				navigation={/*<NavBar itemAlignment={1} type={NavBarTypes.IconText} tabs={myselfTabItems} />*/<></>}
			/>

			<Drawer
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				header={
					<>
						<div className="header d-flex align-items-center" style={{ gridColumnGap: "0.5rem" }}>
							<Text content="Acknowledgement" type={TextType.Heading3} />
							{!isAttestationHidden() && (
								<div style={{ cursor: "pointer" }}>
									<Icon
										icon={`info-circle`}
										type={"light"}
										onClick={() => {
											overviewPage("overview");
										}}
									/>
								</div>
							)}
						</div>
					</>
				}
				headerContent={<React.Fragment> {mainAppState!.getStepperItems() && <PagingContainer items={mainAppState!.getStepperItems()} />}</React.Fragment>}
				type={DrawerType.Right}
				show={mainAppState!.activeDrawer == ActiveDrawer.Acknowledgement}
				contents={
					<Router>
						<Provider mainAppState={mainAppState}>
							<WaiverLanding />
						</Provider>{" "}
					</Router>
				}
				footer={[]}
				onClose={() => acknowledgementClose()}
			/>
			{/** Information Overview MODAL*/}
			<InformationOverview />

			{/* <TemplateModal 
        name={'informationOverview'}
        show={mainAppState!.activeModal == ActiveModal.InfoOverview}
        size={TemplateModalSize.Default}
        // header={<Text type={TextType.Heading3} content={"Information Overview"} />}
        header={<></>}
        bodyAlignment={TemplateModalAlignment.Left}
        body={
          <Router>
						<Provider mainAppState={mainAppState}>
							<InformationOverview />
						</Provider>
					</Router>
        }
        footer={[]}
        {...close}
      /> */}

			{/** MEDICAL DRAWER
			 *   This contains the MIT Medical assist and Unbserved test buttons
			 *   These two buttons go to their respective drawers
			 */}
			<Drawer
				themedColor={"medical"}
				className={"tabbed-nav"}
				header={
					<>
						<div className="header d-flex align-items-center" style={{ gridColumnGap: "0.5rem" }}>
							<Text content="Testing" type={TextType.Heading3} />
							{!isAttestationHidden() && (
								<div style={{ cursor: "pointer" }}>
									<Icon
										icon={`info-circle`}
										type={"light"}
										onClick={() => {
											overviewPage("medical");
										}}
									/>
								</div>
							)}
						</div>
						{/* <Spacer size={"1"} />
						{medicalStatusBadge()}
						<Spacer size={"2"} />
						{medicalStatusText()} */}
					</>
				}
				type={DrawerType.Right}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Small}
				show={mainAppState!.activeDrawer === ActiveDrawer.Medical}
				contents={
					<div>
						<Router>
							<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
								<MedicalLanding />
							</Provider>
						</Router>
					</div>
				}
				footer={[]}
				onClose={() => {
					mainAppState!.activeDrawer = ActiveDrawer.None;
					mainAppState!.reload = true;
				}}
			/>
			{/* UNOBSERVED TESTING DRAWER */}
			<Drawer
				themedColor={"medical"}
				className={"tabbed-nav"}
				header={<Text type={TextType.Heading3} content={mainAppState!.texts["covid19.medical.unobserved.title"]} />}
				type={DrawerType.Right}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Small}
				show={mainAppState!.activeDrawer == ActiveDrawer.UnobservedTesting}
				contents={
					<div>
						<Router>
							<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
								<UnobservedTesting />
							</Provider>
						</Router>
					</div>
				}
				footer={[]}
				// onClose={() => {mainAppState!.activeDrawer = ActiveDrawer.Medical; getTestCompleted()}}
				onClose={() => {
					getTestCompleted();
				}}
			/>
			{/* MEDICAL ASSIST DRAWER */}
			{mainAppState!.getRequirement(RequirementIds.medical) !== undefined && (
				<Drawer
					themedColor={"medical"}
					className={"tabbed-nav"}
					header={<Text type={TextType.Heading3} content={mainAppState!.getRequirement(RequirementIds.medical).title_web ?? "Testing"} />}
					type={DrawerType.Right}
					layout={DrawerLayout.Hero}
					size={DrawerSize.Medium}
					show={mainAppState!.activeDrawer === ActiveDrawer.MedicalAssist}
					contents={
						<div>
							<Router>
								<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
									<MedicalAssist />
								</Provider>
							</Router>
						</div>
					}
					footer={[]}
					onClose={() => (mainAppState!.activeDrawer = ActiveDrawer.Medical)}
				/>
			)}
			{/* TESTING HISTORY DRAWER */}
			<Drawer
				themedColor={"medical"}
				className={"tabbed-nav"}
				header={<Text type={TextType.Heading3} content={"Testing History"} />}
				type={DrawerType.Right}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				show={mainAppState!.activeDrawer === ActiveDrawer.TestingHistory}
				contents={
					<div>
						<Router>
							<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
								<div className="p-4">
									<PersonTests />
								</div>
							</Provider>
						</Router>
					</div>
				}
				footer={[]}
				onClose={() =>
					(mainAppState!.getRequirement(RequirementIds.medical))
						? (mainAppState!.getRequirement(RequirementIds.medical) && !mainAppState!.getRequirement(RequirementIds.medical).visible && mainAppState!.status_model?.status === PassStatus.access_granted)
							? (mainAppState!.activeDrawer = ActiveDrawer.None) 
							: mainAppState!.status_model.status === PassStatus.medical_symptoms &&
								(mainAppState!.status_model?.hold_reason === "positive_test" || mainAppState!.status_model?.hold_reason === "contact") &&
								mainAppState!.status_model?.assignment_type !== 9
							? (mainAppState!.activeDrawer = ActiveDrawer.None)
							: (mainAppState!.activeDrawer = ActiveDrawer.Medical)
						: (mainAppState!.activeDrawer = ActiveDrawer.None) 
				}
			/>
			<Drawer
				layout={DrawerLayout.Hero}
				size={DrawerSize.Small}
				themedColor={"medical"}
				header={
					<>
						<div className="header d-flex align-items-center" style={{ gridColumnGap: "0.5rem" }}>
							<Text content="Training" type={TextType.Heading3} />
							{!isAttestationHidden() && (
								<div style={{ cursor: "pointer" }}>
									<Icon
										icon={`info-circle`}
										type={"light"}
										onClick={() => {
											overviewPage("acknowledgement_training");
										}}
									/>
								</div>
							)}
						</div>
					</>
				}
				type={DrawerType.Right}
				show={mainAppState!.activeDrawer == ActiveDrawer.Training}
				contents={
					<Router>
						<Provider mainAppState={mainAppState}>
							<TrainingLanding />
						</Provider>
					</Router>
				}
				footer={[]}
				onClose={() => (mainAppState!.activeDrawer = ActiveDrawer.None)}
			/>
			<Drawer
				header={
					<>
						<div className="header d-flex align-items-center" style={{ gridColumnGap: "0.5rem" }}>
							<Text content="Attestation" type={TextType.Heading3} />
							{!isAttestationHidden() && (
								<div style={{ cursor: "pointer" }}>
									<Icon
										icon={`info-circle`}
										type={"light"}
										onClick={() => {
											overviewPage("attestation");
										}}
									/>
								</div>
							)}
						</div>
					</>
				} // This should actually come from the API
				headerContent={<></>}
				themedColor={"medical"}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				type={DrawerType.Right}
				show={mainAppState!.activeDrawer === ActiveDrawer.Attestation}
				// show={true}
				contents={
					<div>
						<Router>
							<Provider mainAppState={mainAppState}>
								{" "}
								<AttestationLanding />{" "}
							</Provider>{" "}
						</Router>
					</div>
				}
				footer={[]}
				onClose={() => attestationClose()}
			/>
			<Drawer
				layout={DrawerLayout.Clean}
				size={DrawerSize.Medium}
				header={<Text type={TextType.Heading3} content={"What's New"} />}
				themedColor={"medical"}
				type={DrawerType.Right}
				show={mainAppState!.activeDrawer == ActiveDrawer.ReleaseNotes}
				contents={
					<Router>
						<ReleaseNotesComponent />
					</Router>
				}
				footer={[]}
				onClose={() => (mainAppState!.activeDrawer = ActiveDrawer.None)}
			/>

			<Drawer
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				header={<Text type={TextType.Heading3} content={"Vaccine Acknowledgement"} />}
				headerContent={<React.Fragment> {mainAppState!.getStepperItems() && <PagingContainer items={mainAppState!.getStepperItems()} />}</React.Fragment>}
				type={DrawerType.Right}
				show={mainAppState!.activeDrawer == ActiveDrawer.VaccineAcknowledgement}
				contents={
					<Router>
						<Provider mainAppState={mainAppState}>
							<VaccineWaiverLanding />
						</Provider>{" "}
					</Router>
				}
				footer={[]}
				onClose={() => vaccineAcknowledgementClose()}
			/>

			<TemplateModal
				name="vaccineSurvey"
				footer={<div></div>}
				header={<Text content="Vaccine Survey" type={TextType.Heading4} />}
				show={mainAppState!.activeDrawer == ActiveDrawer.VaccineSurvey}
				body={
					<Router>
						<Provider mainAppState={mainAppState}>
							<VaccineSurvey />
						</Provider>{" "}
					</Router>
				}
				bodyAlignment={TemplateModalAlignment.Center}
				size={TemplateModalSize.Large}
			/>

			<TemplateModal
				body={<Text content={"All progress will be lost if this page is closed. Continue?"} properties={{ role: "main" }} />}
				bodyAlignment={TemplateModalAlignment.Left}
				show={isShowingConfirmationPopup}
				onClose={() => setIsShowingConfirmationPopup(false)}
				header={<Text content={"Confirmation"} type={TextType.Heading3} />}
				size={TemplateModalSize.Small}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button type={ButtonType.Secondary | ButtonType.Ghost} onClick={() => setIsShowingConfirmationPopup(false)} text={"I made a mistake"} />
						&nbsp;
						<Button type={ButtonType.Secondary} onClick={() => closeDrawer()} text={"Yes, I'm sure"} />
					</div>
				}
				name="confirmationDialog"
			/>

			<TemplateModal
				body={
					<>
						<Text content={mainAppState!.texts[COVID_TEXTS["covid19.resident.confirm.title"]]} type={TextType.Heading3} />
						<Spacer />
						<Text content={mainAppState!.texts[COVID_TEXTS["covid19.resident.confirm.body"]]} />
					</>
				}
				show={isShowingResidentialPopup}
				onClose={() => setIsShowingResidentialPopup(false)}
				bodyAlignment={TemplateModalAlignment.Left}
				header={<Text content={mainAppState!.texts[COVID_TEXTS["covid19.resident.confirm.actionbar"]]} type={TextType.Heading3} />}
				size={TemplateModalSize.Small}
				theme="medical-dark"
				imageUrl=""
				footer={
					<div>
						<Button
							type={ButtonType.Secondary | ButtonType.Ghost}
							onClick={() => confirmResidential(false)}
							text={mainAppState!.texts[COVID_TEXTS["covid19.resident.confirm.action.no"]]}
						/>
						&nbsp;
						<Button type={ButtonType.Secondary} onClick={() => confirmResidential(true)} text={mainAppState!.texts[COVID_TEXTS["covid19.resident.confirm.action.yes"]]} />
					</div>
				}
				name="residentialDialog"
			/>
			{/* Report options */}
			<TemplateModal
				body={<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
					<INeedToDropdown />
				</Provider>}
				bodyAlignment={TemplateModalAlignment.Left}
				show={mainAppState!.activeDrawer === ActiveDrawer.ReportOptions}
				onClose={() => mainAppState!.activeDrawer = ActiveDrawer.None}
				header={<Text content={"Select a option"} type={"h3"} />}
				size={TemplateModalSize.Default}
				theme="medical"
				imageUrl=""
				footer={<></>}
				name="reportOptionsDialog"
			/>
			<MobilePromo title="Get it on mobile" iosLink="https://apps.apple.com/za/app/mit-atlas/id1498222646" androidLink="https://play.google.com/store/apps/details?id=edu.mit.atlas" />
		</div>
	);
};

const Myself = withRouter(inject("mainAppState", "userProfileStoreState")(observer(MyselfComponent)));
export default Myself;
