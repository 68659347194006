import * as React from "react";
import { FC, useState, useEffect } from "react";
import { mainAppState, MainAppStateInterface } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { Container, Text, Spacer, Button, ButtonType, TextType, TemplateTwoColumns } from "@mit/hui";
import PersonTests from "../medical-test/PersonTests";
import { CaseModel } from "../../types/CaseModel";
import Vaccine from "./Vaccine";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import { HoldReason } from "../../types/CovidStatusModel";
import { getEnv } from "../../common/Environment";

interface HoldProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const HoldComponent: FC<HoldProps> = ({ mainAppState, userProfileStoreState }) => {
	useEffect(() => {
		document.body.classList.add("hold");

		return function cleanup() {
			document.body.classList.remove("hold");
		};
	}, []);

	const actionHold = (url?: string) => {
		let actionUrl = mainAppState!.texts[getCovidText("covid19.{holdReason}.hold.action.url")] as string;

		if (getActionType() === "navigate") {
			const currEnv = getEnv(true) as string;
			window.location.href = actionUrl.replace('{env}', currEnv)
			// if (mainAppState!.status_model.hold_reason === HoldReason.vaccine) {
			// 	/*TEMP fix need to do testing to see if we need to make a global adjustment to the textAPI action.url links*/
			// } else {
			// 	window.location.href = actionUrl;
			// }
		} else if (getActionType() === "refresh") {
			window.location.href = window.location.href;
		}
	};

	const getActionType = () => {
		let actionType = mainAppState!.texts[getCovidText("covid19.{holdReason}.hold.action.type")] as string;

		return actionType;
	};

	const getCovidText = (text: string): string => {
		let replacedText = text.replace("{holdReason}", mainAppState!.status_model.hold_reason.toLowerCase());

		return replacedText;
	};

	const renderHolds = () => {
		return (
			<>
				<Text content={'Vaccine Hold'} type={'h3'}/>
				<Spacer />
				{mainAppState!.status_model.hold_reason.toLowerCase() !== "vaccine"  && (
				<Text padded={true} content={mainAppState!.texts[getCovidText("covid19.{holdReason}.hold.text")]} />
				)}

				{mainAppState!.status_model.hold_reason.toLowerCase() === "vaccine"  && (
				<p className="my-3">Your access to campus has been put on hold because you have not fully met MIT's
					Covid-19 vaccination requirement. Please visit <a href="https://covidapps.mit.edu/vaccine-information" target="_blank">https://covidapps.mit.edu/vaccine-information</a> for
					more information on vaccination requirements and exemptions.</p>

				)}
					<Spacer />

				{getActionType() !== "gone" && (
					<Button type={ButtonType.Primary} onClick={actionHold} text={mainAppState!.texts[getCovidText("covid19.{holdReason}.hold.action")]} />
				)}

				<Spacer />

				<PersonTests />
			</>
		);
	};

	return (
		<>
			<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
				<Vaccine />
			</Provider>
			<Spacer />
			
			{renderHolds()}
			
		</>
	);
};

const Hold = inject("mainAppState")(observer(HoldComponent));
export default Hold;
