import * as React from "react";
import { FC, useState, useEffect } from "react";
import { mainAppState, MainAppStateInterface, CovidTextType, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { toJS } from "mobx";
import { RequirementIds, RequirementStatus } from "../../types/CovidStatusModel";
import { Container, Text, Spacer, Button, ButtonType, TextType } from "@mit/hui";
import Vaccine from "./Vaccine";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";

interface WaiverDeclinedProps {
    mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const WaiverDeclinedComponent: FC<WaiverDeclinedProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		console.log(toJS(mainAppState!.texts));
	}, []);

	const getButtonText = (): string => {
		return mainAppState!.texts[COVID_TEXTS["covid19.noagreement.action"]];
	};

	const setDrawerState = () => {
		mainAppState!.activeDrawer = ActiveDrawer.Acknowledgement;
	};

	return (
		<>
			<div className="col-lg-6 col-lg-9 mx-auto">
				<span className="badge badge-warning">Action Required</span>
				<Text content={mainAppState!.texts[COVID_TEXTS["covid19.noagreement.title"]]} type={TextType.Heading1} />
				<Spacer />
				<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
					<Vaccine />
				</Provider>
				<Spacer />
				<Container
					color={1}
					alignment="left"
					contents={
						<>
							<div className="p-4 pt-0 text-center">
								<Button type={ButtonType.Secondary} text={getButtonText()} onClick={() => setDrawerState()} />
							</div>
						</>
					}
				/>
			</div>
		</>
	);
};

const WaiverDeclined = inject("mainAppState")(observer(WaiverDeclinedComponent));
export default WaiverDeclined;
