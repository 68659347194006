import * as React from "react";
import { FC } from "react";
import { inject, observer, Provider } from "mobx-react";
import { withRouter } from "react-router";
import { MainAppStateInterface } from "../model/MainAppStateStore";
import { PassStatus, RequirementIds, RequirementStatus } from "../types/CovidStatusModel";
import { APPLICATION_PATHS, mediumLoaderOptions } from "../common/Defaults";
import Loader from "react-loader";
import { useState } from "react";
import NoAccess from "./dashboard/NoAccess";
import AccessGranted from "./dashboard/AccessGranted";
import { useEffect } from "react";
import dataController from "../api/DataController";
import { PageError } from "../types/PageErrors";
import ErrorMessage from "../common/ErrorMessage";
import { COVID_TEXTS } from "../common/Texts";
import { UserProfileStoreInterface } from "../model/UserProfileStore";
import RequirementsIncomplete from "./dashboard/RequirementsIncomplete";
import Medical from "./dashboard/Medical";
import MedicalOverdue from "./dashboard/MedicalOverdue";
import WaiverDeclined from "./dashboard/WaiverDeclined";
import { toJS } from "mobx";
import Hold from "./dashboard/Hold";
import NoAccessTesting from "./dashboard/NoAccessTesting";
import MedicalSymptoms from "./dashboard/MedicalSymptoms";
import { CaseModel } from "../types/CaseModel";
import { FetchPassStatusResponse } from "types/Api";
import HoldSymptoms from "./dashboard/HoldSymptoms";
import INeedToDropdown from "./dashboard/INeedToDropdown";
import Myself from "./Myself";
import { isNavStateEnabled } from "common/Extentions";

interface AppLandingProps {
	history: any;
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const AppLandingComponent: FC<AppLandingProps> = ({ mainAppState, userProfileStoreState, history }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: "", showRetry: false });

	useEffect(() => {
		dataController
			.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId)
			.then((statusResponse: FetchPassStatusResponse) => {
				if (!statusResponse.error) {
					mainAppState!.updatePersonStatus(statusResponse);
					setIsLoading(false);
				} else {
					history.push(APPLICATION_PATHS.noAccess);
				}
			})
			.catch((error: any) => {
				setIsLoading(false);
				setPageError({
					error: true,
					errorText: `It appears that your session has expired. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS["covid19.pass.help_email"]]}.`,
					showRetry: false,
				});
			});
	}, []);

	const showAccessGranted = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.access_granted;
	};

	const showMedicalOverdue = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.medical_overdue;
	};

	const showMedical = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.medical_positive || mainAppState!.status_model.status === PassStatus.medical_block;
	};

	const showMedicalSymptoms = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.medical_symptoms
	}

	const showMedicalSymptomHold = (): boolean => {
		return (
			mainAppState!.status_model.status === PassStatus.medical_symptoms &&
			(mainAppState!.status_model?.hold_reason === "positive_test" || mainAppState!.status_model?.hold_reason === "contact") &&
			mainAppState!.status_model?.assignment_type !== 9
		);
	};

	const showNoAccess = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.no_access;
	};

	const showHold = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.hold;
	};

	const showAccessTesting = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.no_access_with_testing;
	};

	const showIncomplete = (): boolean => {
		return mainAppState!.isIncomplete();
	};

	const showWaiverDeclined = (): boolean => {
		return mainAppState!.status_model.status === PassStatus.waiver_declined;
	};

	const hasAccess = () => {
		return mainAppState!.personCanAccessApplication();
	};

	const showPageError = (): boolean => {
		return !isLoading && pageError.error;
	};

	const medicalIsEnabled = () => {
		return isNavStateEnabled(RequirementIds.medical, mainAppState!) && isVisible(RequirementIds.medical);
	};

	const isVisible = (reqId: RequirementIds): boolean => {
		if (mainAppState!.status_model.status === PassStatus.no_access_with_testing) {
			return false;
		} else if (mainAppState && mainAppState.getRequirement(reqId) && mainAppState.getRequirement(reqId)!.visible) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			{showPageError() && <ErrorMessage text={pageError.errorText} />}
			<Loader loaded={!isLoading} options={mediumLoaderOptions}>
				<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
					{showIncomplete() && <RequirementsIncomplete />}
					{showMedicalOverdue() && <MedicalOverdue />}
					{showMedical() && <Medical />}
					{showWaiverDeclined() && <WaiverDeclined />}
					{showHold() && <Hold />}
					{/* {medicalIsEnabled() && <INeedToDropdown />} */}

					{showNoAccess() && <NoAccess />}
					{showAccessTesting() && <NoAccessTesting />}
					{showAccessGranted() && <AccessGranted />}
					{showMedicalSymptoms() && <>{showMedicalSymptomHold() ? <HoldSymptoms /> : <MedicalSymptoms />}</>}
				</Provider>
			</Loader>
		</>
	);
};

const AppLanding = withRouter(inject("mainAppState", "userProfileStoreState")(observer(AppLandingComponent)));
export default AppLanding;
