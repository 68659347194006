import * as React from "react";
import { inject, observer, Provider } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { ActiveDrawer, MainAppStateInterface } from "../../model/MainAppStateStore";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import {
	Text,
	Drawer,
	DrawerSize,
	DrawerType,
	DrawerLayout,
	Icon,
	Form,
	FormContainer,
	FormField,
	Button,
	TemplateModal,
	TemplateModalAlignment,
	ButtonState,
	Container,
	Spacer,
	AlertProps,
	Alert,
} from "@mit/hui";
import moment from "moment";
import dataController from "api/DataController";
import ReactMarkdown from "react-markdown";
import ReportCovidSymptomsModal from "components/dashboard/ReportCovidSymptomsModal";

interface NotifyMedicalProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	show: boolean;
	onClose: () => void;
}

const NotifyMedicalDrawerComponent: FC<NotifyMedicalProps> = ({ mainAppState, userProfileStoreState, show, onClose }) => {
	const [page, setPage] = useState<"" | "positive-covid-result" | "close-contact">("");
	const [showReportPositiveTestModal, setShowReportPositiveTestModal] = useState<boolean>(false);
	const [showReportSymptomsModal, setShowReportSymptomsModal] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<AlertProps>({ text: "" });

	useEffect(() => {
		setPage("");
	}, [show]);

	const getHeadingText = () => {
		switch (page) {
			case "positive-covid-result":
				return "Positive Covid Result";
			case "close-contact":
				return "Close Contact";
			default:
				return "Notify MIT Medical";
		}
	};

	const getTestTypeID = (testType: string) => {
		switch (testType) {
			case "PRC":
				return 3;
			case "Rapid Antigen":
				return 4;
			default:
				return 3;
		}
	};

	return (
		<>
			<Drawer
				show={show}
				onClose={onClose}
				size={DrawerSize.Small}
				themedColor="medical"
				footer={[]}
				type={DrawerType.Right}
				layout={DrawerLayout.Hero}
				header={
					<div className={page !== "" ? "inline-heading-padded" : "inline-heading"}>
						<Text content={getHeadingText()} type={"h3"} />
					</div>
				}
				contents={
					<>
						{alertMessage.text && <Alert {...alertMessage} />}
						{/* LANDING PAGE */}
						{page === "" && (
							<div className="row">
								<div className="col-6">
									<div className="d-flex justify-content-center">
										<div aria-label="Positive Covid Result" tabIndex={0} className="box-item" onClick={() => setPage("positive-covid-result")}>
											<Icon icon="vial" type="regular" />
											<Spacer />
											<Text content={mainAppState!.texts["covid19.notify-mit.positive"] ?? "Positive Covid Result"} bold />
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="d-flex justify-content-center">
										<div aria-label="Covid Symptoms" tabIndex={0} className="box-item" onClick={() => setShowReportSymptomsModal(true)}>
											<Icon icon="viruses" type="regular" />
											<Spacer />
											<Text content={mainAppState!.texts["covid19.notify-mit.symptoms"] ?? "Covid Symptoms"} bold />
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className="d-flex justify-content-center">
										<div aria-label="Close Contact" tabIndex={0} className="box-item" onClick={() => setPage("close-contact")}>
											<Icon icon="people-arrows" type="regular" />
											<Spacer />
											<Text content={mainAppState!.texts["covid19.notify-mit.contact"] ?? "Close Contact"} bold />
										</div>
									</div>
								</div>
							</div>
						)}
						{/* POSITIVE COVID RESULT */}
						{page === "positive-covid-result" && (
							<>
								<div className="header-back-btn">
									<Button
										text={"Notify MIT Medical"}
										icon="chevron-left"
										padded={false}
										type="textNaked"
										onClick={() => {
											setPage("");
										}}
									/>
								</div>
								<div>
									<Text content={"Result Details"} type={"h3"} />
									<Form formValidationTrigger="onSubmit">
										{(methods) => {
											const onCheck = () => {
												methods.trigger().then((valid) => {
													if (!valid) return;

													setShowReportPositiveTestModal(true);
												});
											};

											const onSubmit = () => {
												setIsBusy(true);
												let data = methods.getValues();
												dataController
													.postTestManualPositive(data.dateOfResult, getTestTypeID(data.testType))
													.then(() => {
														setShowReportPositiveTestModal(false);
														onClose();
														mainAppState!.activeDrawer = ActiveDrawer.None;
														mainAppState!.reload = true;
													})
													.catch((e) => {
														setShowReportPositiveTestModal(false);
														setAlertMessage({ text: e, type: "error" });

														setTimeout(() => {
															setAlertMessage({ text: "", type: "success" });
														}, 5000);
													})
													.finally(() => {
														setIsBusy(false);
													});
											};

											return (
												<>
													<FormContainer id="positive-result-form" formValidationTrigger="onSubmit" methods={methods}>
														<div>
															<FormField
																maxDate={{
																	year: parseInt(moment().format("YYYY")),
																	month: parseInt(moment().format("M")),
																	day: parseInt(moment().format("D")),
																}}
																editor="calendar"
																label="Date of result"
																id="dateOfResult"
																value={moment().format("YYYY-MM-DD")}
																required
															/>
															<FormField
																editor="dropdown"
																label="Test type"
																id="testType"
																options={[
																	{
																		text: "PCR",
																		id: "3",
																		icon: "",
																	},
																	{
																		text: "Rapid Antigen",
																		id: "4",
																		icon: "",
																	},
																]}
																required
															/>
														</div>
													</FormContainer>
													<div className="my-2">
														<ReactMarkdown
															linkTarget={"_blank"}
															children={
																mainAppState?.texts["covid19.notify-mit.reportinfo.markdowntext"]?.replace(
																	"{date}",
																	moment().subtract(90, "days").format("MMM D, YYYY")
																) ?? "Reporting that you are positive with Covid will disqualify you from campus.  Do not log items before 1 Jan 2022"
															}
														/>
													</div>
													<div className="d-flex justify-content-end">
														<Button text="Submit" onClick={onCheck} isBusy={isBusy} state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} />
													</div>
													{showReportPositiveTestModal && (
														<TemplateModal
															theme="medical"
															show={showReportPositiveTestModal}
															onClose={() => setShowReportPositiveTestModal(false)}
															body={
																<div>
																	<div className="py-3">
																		<Text
																			content={
																				mainAppState!.texts["covid19.notify-mit.positive-text.info"] ??
																				"Reporting that you have a positive test will put you on a Medical Hold. The isolation guidelines will get you through the next 10 days."
																			}
																		/>
																	</div>
																</div>
															}
															bodyAlignment={TemplateModalAlignment.Center}
															footer={
																<div className="d-flex justify-content-center">
																	<div className="btn-padded">
																		<Button text="Cancel" outlined onClick={() => setShowReportPositiveTestModal(false)} />
																		<Button
																			isBusy={isBusy}
																			state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
																			text={mainAppState!.texts["covid19.notify-mit.positive-popup.confirm"] ?? "I have a positive test result"}
																			overwriteColor="danger"
																			onClick={onSubmit}
																		/>
																	</div>
																</div>
															}
															header={<Text content={mainAppState!.texts["covid19.notify-mit.positive-popup.title"] ?? "Report positive test"} type={"h3"} />}
															name="reportModal"
														/>
													)}
												</>
											);
										}}
									</Form>
								</div>
							</>
						)}
						{/* CLOSE CONTACT */}
						{page === "close-contact" && (
							<>
								<div className="header-back-btn">
									<Button
										text={"Notify MIT Medical"}
										icon="chevron-left"
										padded={false}
										type="textNaked"
										onClick={() => {
											setPage("");
										}}
									/>
								</div>
								<div>
									{(mainAppState!.vaccineStatus?.status === "full" || mainAppState!.vaccineStatus?.status === "complete") &&
									mainAppState!.vaccineStatus.booster.valid &&
									!mainAppState!.vaccineStatus?.booster.exemption ? (
										<Container
											contents={
												<>
													<div className="d-flex flex-column text-center">
														<Text content={mainAppState!.texts["covid19.notify-mit.contact.title"] ?? "Close contacts who do not feel symptoms:"} type={"h4"} />
														<ReactMarkdown
															children={
																mainAppState!.texts["covid19.notify-mit.contact.infomarkdown"] ??
																"You do not need to quarantine unless you develop symptoms, your campus access will remain in place\n\n\n* Get tested at least 5 days after you last had close contact.\n* Watch for symptoms until 10 days after you last had close contact. If you develop symptoms isolate immediately and get tested.\n* Wear a mask for 10 days any time you are around others."
															}
														/>
													</div>
												</>
											}
											padding={5}
											raised={false}
										/>
									) : (
										<>
											<Text content={"Contact date"} type={"h3"} />
											<Form formValidationTrigger="onSubmit">
												{(methods) => {
													const onCheck = () => {
														methods.trigger().then((valid) => {
															if (!valid) return;

															let data = methods.getValues();
															setIsBusy(true);
															dataController
																.postContactTracingCloseContact(data.dateOfContact)
																.then(() => {
																	onClose();
																	mainAppState!.activeDrawer = ActiveDrawer.None;
																	mainAppState!.reload = true;
																})
																.catch((e: any) => {
																	setAlertMessage({ text: e, type: "error" });

																	setTimeout(() => {
																		setAlertMessage({ text: "", type: "success" });
																	}, 5000);
																})
																.finally(() => {
																	setIsBusy(false);
																});
														});
													};

													return (
														<>
															<FormContainer id="close-contact-form" formValidationTrigger="onSubmit" methods={methods}>
																<div>
																	<FormField
																		maxDate={{
																			year: parseInt(moment().format("YYYY")),
																			month: parseInt(moment().format("M")),
																			day: parseInt(moment().format("D")),
																		}}
																		editor="calendar"
																		label="Date of contact"
																		id="dateOfContact"
																		value={moment().format("YYYY-MM-DD")}
																		required
																	/>
																</div>
															</FormContainer>
															<div>
																<ReactMarkdown
																	children={
																		mainAppState!.texts["covid19.notify-mit.contact.instructions"]?.replace(
																			"{date}",
																			moment().subtract(10, "days").format("MMM D, YYYY")
																		) ??
																		"Close contact with someone without being vaccinated or boosted status being up to date puts you at risk and you will have to isolate for safety reasons."
																	}
																/>
															</div>
															<div className="d-flex justify-content-end">
																<Button text="Submit" onClick={onCheck} isBusy={isBusy} state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} />
															</div>
														</>
													);
												}}
											</Form>
										</>
									)}
								</div>
							</>
						)}
						<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
							{/* Report Symptoms Modal */}
							<ReportCovidSymptomsModal show={showReportSymptomsModal} onClose={() => setShowReportSymptomsModal(false)} />
						</Provider>
					</>
				}
			/>
		</>
	);
};

const NotifyMedicalDrawer = inject("mainAppState", "userProfileStoreState")(observer(NotifyMedicalDrawerComponent));
export default NotifyMedicalDrawer;
