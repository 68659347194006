import * as React from 'react'
import { FC, useState } from 'react'
import { NavBar, NavBarTypes, NavItemProps, ReleaseNotes, NavItemState, BasicLayout, ReleaseNoteItemProps } from '@mit/hui';
import { withRouter } from 'react-router-dom'

interface ReleaseNotesProps {
    history?: any;
}

const ReleaseNotesComponent: FC<ReleaseNotesProps> = ({ history }) => {
    const [showVideo, setShowVideo] = useState(false);

    const docsHeading: NavItemProps[] = [
        { icon: 'id-card', text: 'Release Notes', onClick: () => setShowVideo(false), iconOnly: false, state: !showVideo ? NavItemState.Active : NavItemState.None },
        { icon: 'id-card', text: 'Videos', iconOnly: false, onClick: () => setShowVideo(true), state: showVideo ? NavItemState.Active : NavItemState.None },
    ];

    const releaseNotes: ReleaseNoteItemProps[] = [
        {
            date: "12/27/2020",
            items: [
                { heading: "Vaccination", description: "Notitification on systematic rollout of vaccination is included as a capability of Covid Pass. Covid Pass users will be notified once they become eligable to receive vaccine." }
            ] 
        },
        {
            date: "11/30/2020",
            items: [
                { heading: "Contact Tracing", description: "Allows for the addition of recent contacts to be listed upon positive test results. This will allow MIT Medical to investigate more efficiently." }
            ] 
        },
        {
            date: "11/20/2020",
            items: [
                { heading: "Covid Travel", description: "A dedicated link to Covid Travel was added for convenience and awareness." },
                { heading: "Campus & Travel Holds", description: "Campus as well as Travel Holds are nou effectively communicated through the app." }
            ] 
        },
        {
            date: "9/28/2020",
            items: [
                { heading: "Flu Shots", description: "Instructions on timing and location of annual flu shots at MIT Medical added to medical requirements." },
                { heading: "Residential Holds", description: "Residential holds are now effectively communicated through the app." }
            ] 
        },
       
        {
            date: "8/31/2020",
            items: [
                { heading: "Queues", description: "Medical testing locations are now being displayed, with more information like open and closing times, as well as estimated waiting times." }
            ,  { heading: "Acknowledgement forms", description: "The acknowledgement step is now a multi-part form. Those who have submitted a previous version of the forms will be required to read and submit the new version." }
            
            ] 
        },
        {
            date: "07/8/2020",
            items: [
                { heading: "Push Notifications", description: "Receive a push notification 2 hours prior to our campus access expiration and when a new test result is available." }
            ]
        },

    ];

    const videosPage: ReleaseNoteItemProps[] = [
        {
            videos: [
                { label: "Covid Pass Mobile", url: "https://www.youtube.com/embed/QJx32gkSm3g", youtube: true }
            ],
            date: "",
            items: [
                { heading: "Covid Pass Mobile", description: "Complete your Covid Pass requirements on via the ATLAS Mobile App." },
            ]
        }
    ];

    return (
    <ReleaseNotes items={releaseNotes} />
    );
}

export default withRouter(ReleaseNotesComponent);
