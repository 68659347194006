import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { inject, observer, Provider } from "mobx-react";
import { MainAppStateInterface } from "../../model/MainAppStateStore";
import VaccineWaiver from "./VaccineWaiver";
import { PageError } from "../../types/PageErrors";
import { withRouter } from "react-router";
import { Spacer } from '@mit/hui';
import { mediumLoaderOptions } from '../../common/Defaults';
import Loader from 'react-loader'

interface VaccineWaiverLandingProps {
    mainAppState?: MainAppStateInterface;
    history?: any;
}

const VaccineWaiverLandingComponent: FC<VaccineWaiverLandingProps> = ({ mainAppState, history }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [showWaiverForm, setShowWaiverForm] = useState(false)
    const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        setIsLoading(false);
        setShowWaiverForm(true);
    }, [])

    const clickReset = () => {
        setShowWaiverForm(true);
    }

    return <div className={`submitter-view`}>
        <div className={'nav-container'}>
            <div className={`main-content`}>
                <div className={`page-content text-align-center`}>
                    <Loader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: 'black' }}>
                    <Provider mainAppState={mainAppState}><VaccineWaiver /></Provider>
                    <Spacer size="5"/>
                    </Loader>
                </div>
            </div>
        </div>
    </div>
}

const VaccineWaiverLanding = withRouter(inject('mainAppState')(observer(VaccineWaiverLandingComponent)))

export default VaccineWaiverLanding