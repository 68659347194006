import * as React from 'react'
import { FC, useEffect } from "react";
import CommonTable from "../../common/CommonTable";
import dataController from "../../api/DataController";
import { useState } from "react";
import { toDate } from "../../common/Formatters";
import { mediumLoaderOptions } from "../../common/Defaults";
import Loader from 'react-loader'
import { Spacer } from '@mit/hui';
import { inject, observer } from 'mobx-react';
import { MainAppStateInterface } from '../../model/MainAppStateStore';

export const executeSortDesc = (a: any, b: any) => {
    const fieldName = 'date'
    let dateTimeA: moment.Moment = toDate(a[fieldName]!)
    let dateTimeB: moment.Moment = toDate(b[fieldName]!)
    return dateTimeB.diff(dateTimeA)
}

interface PersonTestsComponentProps {
    mainAppState?: MainAppStateInterface;
    label?: string;
    alwaysShowButton?: boolean;
}

const PersonTestsComponent: FC<PersonTestsComponentProps> = ({ mainAppState, label, alwaysShowButton }) => {
    const [personTests, setPersonTests] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(true)
    

    useEffect(() => {
        const abortController = new AbortController();
        setIsLoading(true);

        dataController.fetchTestResults(abortController.signal).then((resp) => {
            let items:any[] = resp.items ? resp.items.sort(executeSortDesc) : [];

            items = items.filter((itm: any) =>
                (itm.rawResult !== "FLU_SHOT" && itm.rawResult && itm.rawResult.toLowerCase().indexOf("vaccine") === -1)
            );

            setPersonTests(items);
            setIsLoading(false);
        })

        return () => {
            abortController.abort();
        };
    }, []);

    const headings = [
        'Date',
        'Result'
    ]

    const filters = {}

    const fields = [
        'date',
        'result'
    ]

    const dataTypes = {
        'date': 'date'
    }

    return <>
        <Loader loaded={!isLoading} options={mediumLoaderOptions}>
            {/* <Spacer size="3" /> */}
            <div className="results-curb">
                <CommonTable headings={headings} data={personTests} fields={fields} dataTypes={dataTypes} filters={filters} />
            </div>
            <Spacer size="3"/>
        </Loader>

        {label && <p>{label}</p>}
    </>
}

const PersonTests = inject('mainAppState')(observer(PersonTestsComponent))
export default PersonTests
