import * as React from "react";
import { FC, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { withRouter } from "react-router";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import dataController from "../../api/DataController";
import {
	Text,
	Icon,
	ActionList,
	ListItemProps,
	ButtonState,
	Spacer,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	Button,
	Drawer,
	DrawerType,
	DrawerLayout,
	DrawerSize,
	Form,
	FormContainer,
	GridContainer,
	LayoutColumn,
	Label,
	ListItem,
	ListItemState,
	FormField,
	Textbox,
	Container,
} from "@mit/hui";
import MedicalLocations from "./MedicalLocations";
import { UserInfo } from "types/Api";
import userController from "api/UserController";
import moment from "moment";
import NotifyMedicalDrawer from "./NotifyMedicalDrawer";
import ReactMarkdown from "react-markdown";
import { RequirementIds } from "types/CovidStatusModel";

interface MedicalLandingProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	history?: any;
}

const MedicalLandingComponent: FC<MedicalLandingProps> = ({ mainAppState, userProfileStoreState, history }) => {
	const [isConfirming, setConfirming] = useState<boolean>(false);
	const [showConfirmCancelUnobservedTest, setShowConfirmCancelUnobservedTest] = useState<boolean>(false);
	const [showConfirmPhone, setShowConfirmPhone] = useState<boolean>(false);
	const [showCannotUpdate, setShowCannotUpdate] = useState<boolean>(false);
	const [showUpdatePhone, setShowUpdatePhone] = useState<boolean>(false);
	const [showConfirmSymptoms, setShowConfirmSymptoms] = useState(false);
	const [showNotifyMedicalDrawer, setShowNotifyMedicalDrawer] = useState(false);
	const [showInfoUpdate, setInfoUpdate] = useState(mainAppState!.unobservedStatus.requireUserInfoUpdate);
	const [typeAddress, setTypeAddress] = useState(false);
	const [phoneNr, setPhoneNr] = useState("(000)-000-0000");
	const [maxDate, setMaxDate] = useState({ year: parseInt(moment().format("YYYY")), month: parseInt(moment().format("M")), day: parseInt(moment().format("D")) });
	const [showUnobservedTest, setUnobservedTest] = useState<boolean>(mainAppState!.features["covid19.pass.unobserved_test"]);
	const [showMaximumTestCountReached, setShowMaximumTestCountReached] = useState<boolean>(mainAppState!.status_model.max_test_count_reached);
	const [unobservedTestDone, setUnobservedTestDone] = useState<boolean>(mainAppState!.unobservedTestDone);
	const [isBusy, setBusy] = useState<boolean>(false);
	const [userInfo, setUserInfo] = useState<UserInfo>({ dob: "", address1: "", address2: "", city: "", state: "", postal_code: "" });

	useEffect(() => {
		// setInfoUpdate(mainAppState!.unobservedStatus.requireUserInfoUpdate)
		// setUnobservedTest(mainAppState!.features["covid19.pass.unobserved_test"])
		// setUnobservedTestDone(mainAppState!.unobservedTestDone)
		ageRestriction();
		setShowMaximumTestCountReached(mainAppState!.status_model.max_test_count_reached);
	}, []);

	// const showUnobservedTest = (): boolean => {
	// 	return mainAppState!.features["covid19.pass.unobserved_test"]
	// }

	// const showMedicalAssist = (): boolean => {
	// 	return mainAppState!.features["covid19.pass.medical_assist"]
	// }

	const ageRestriction = (): void => {
		const restiction = moment().subtract(14, "years").format();
		setMaxDate({ year: parseInt(moment(restiction).format("YYYY")), month: parseInt(moment(restiction).format("M")), day: parseInt(moment(restiction).format("D")) });
	};

	const listData: ListItemProps[] = [
		{
			className: "custom-item",
			icon: "microscope",
			text: mainAppState!.texts["covid19.medical.results.button"],
			actions: [{ icon: "cloud-download-alt", iconType: "solid", state: ButtonState.Enabled }],
			onClick: () => (mainAppState!.activeDrawer = ActiveDrawer.TestingHistory),
		},
		{
			className: "custom-item",
			icon: "question-circle",
			text: mainAppState!.texts["covid19.pass.faq.button.text"],
			actions: [{ icon: "chevron-right", state: ButtonState.Enabled }],
			onClick: () => window.open(mainAppState?.texts["covid19.pass.faq.url"], "_blank")?.focus(),
		},
	];

	if (mainAppState?.status_model.can_retest && mainAppState!.features["covid19.pass.unobserved_test"]) {
		listData.unshift({
			className: "custom-item",
			icon: "user",
			text: mainAppState!.texts["covid19.medical.assisted.button"],
			actions: [{ icon: "chevron-right", state: ButtonState.Enabled }],
			onClick: () => (mainAppState!.activeDrawer = ActiveDrawer.MedicalAssist),
			// maybe todo - if on medical hold, disable button (state: ListItemState.Disabled)
		});
	}

	const reportSymptoms = (): void => {
		dataController
			.postReportSymptoms({ has_symptoms: true })
			.then(() => {
				mainAppState!.reload = true;
				mainAppState!.activeDrawer = ActiveDrawer.None;
				// setShowConfirmSymptoms(false)
				setShowUpdatePhone(false);
				setConfirming(false);
			})
			.catch((err) => console.error(err));
	};
	const confirmedPhoneNr = (confirmed: boolean): void => {
		if (confirmed) {
			reportSymptoms();
		} else {
			setShowConfirmPhone(false);
			setShowUpdatePhone(true);
			setConfirming(false);
		}
	};
	const getLatestPhoneNr = () => {
		dataController
			.getUserPhone()
			.then((res) => {
				const nr = res.data.phoneNumber;
				setPhoneNr(`(${nr.slice(0, 3)})-${nr.slice(3, 6)}-${nr.slice(6)}}`);
				setShowConfirmPhone(true);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setConfirming(false);
			});
	};
	const updatePhoneNr = (phoneNr: string): void => {
		const digitsOnlyNr = phoneNr.replace(/\D/g, "");
		// if (digitsOnlyNr.length !== 10) {
		//   setIsNrLength10(false);
		// }
		const dataObj = {
			phoneNumber: digitsOnlyNr,
		};
		dataController
			.patchUserPhone(dataObj)
			.then((resp) => {
				console.log(resp.data);
				reportSymptoms();
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setShowUpdatePhone(false);
				setConfirming(false);
			});
	};

	const cancelUnobservedTest = (): void => {
		dataController
			.getUnobservedStatus()
			.then((resp) => {
				// console.log(resp.data)
				if (resp.data.canCancelSubmittedTest) {
					dataController
						.deleteUnobservedCompleted()
						.then((response) => {
							console.log(response);
							mainAppState!.activeDrawer = ActiveDrawer.None;
							mainAppState!.reload = true;
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					// More than 12 hours - cannot delete
					setShowCannotUpdate(true);
				}
			})
			.catch((err) => console.error(err))
			.finally(() => {
				setShowConfirmCancelUnobservedTest(false);
				setConfirming(false);
			});
	};

	//RENDER UNOBSERVED TESTING
	// // State ONE
	// mainAppState!.features["covid19.pass.unobserved_test"] = false

	// // State TWO
	// mainAppState!.features["covid19.pass.unobserved_test"] = true
	// mainAppState!.status_model.can_retest = false

	// // State THREE
	// mainAppState!.features["covid19.pass.unobserved_test"] = true
	// mainAppState!.status_model.can_retest = true
	// mainAppState!.status_model.max_test_count_reached = true

	// // State FOUR
	// mainAppState!.features["covid19.pass.unobserved_test"] = true
	// mainAppState!.status_model.can_retest = true
	// mainAppState!.status_model.max_test_count_reached = false
	// mainAppState!.status_model.requirements[2].visible = true
	// mainAppState!.status_model.requirements[2].required = false

	// // State FIVE
	// mainAppState!.features["covid19.pass.unobserved_test"] = true
	// mainAppState!.status_model.can_retest = true
	// mainAppState!.status_model.max_test_count_reached = false
	// mainAppState!.status_model.requirements[2].visible = true
	// mainAppState!.status_model.requirements[2].required = true

	const UnobservedTesting = (): JSX.Element => {
		if (!mainAppState!.features["covid19.pass.unobserved_test"]) {
			return <UnobservedTestingUnavailableCard />
		} else {
			if (!mainAppState!.status_model.can_retest) {
				return <CantRetestCard />
			} else if (showMaximumTestCountReached) {
				return <MaxTestCountReachedCard />
			} else if (mainAppState!.getRequirement(RequirementIds.medical)?.visible && !mainAppState!.getRequirement(RequirementIds.medical)?.required) {
				return <OptionalTestingCard />
			} else {
				return <UnobservedTestCard />
			}
		}
	}
	const UnobservedTestingUnavailableCard = (): JSX.Element => {
		return (
			<Container
				padding={3}
				raised={false}
				contents={
					<div className="p-4">
						<div style={{ fontSize: "4rem" }} className="d-flex justify-content-center align-items-center">
							<span style={{ width: "4rem" }}>
								<Icon icon="exclamation-circle" type={"regular"} color="#1a4d4f" />
							</span>
						</div>
						<div className="text-center">
							<Text content={mainAppState!.texts["covid19.pass.unobserved_test"] ?? "Unobserved testing is not needed at the moment."} bold />
						</div>
					</div>
				}
			/>
		);
	};

	const UnobservedTestCard = (): JSX.Element => {
		return (
			<>
				{mainAppState!.features["covid19.pass.unobserved_test.show_info"] && (
					<div className="unobserved-info" style={{ textAlign: "center" }}>
						<Text content={mainAppState!.texts["covid19.medical.unobserved.info"]} type={"body"} align={"center"} />
						<a href={mainAppState!.texts["covid19.medical.unobserved.info.url"]} target={"_blank"}>
							{mainAppState!.texts["covid19.medical.unobserved.info.urltext"]}
						</a>
					</div>
				)}
				<div className={`medical-options ${!unobservedTestDone ? "" : "test-done"}`}>
					<div className={"card rounded"} tabIndex={0}>
						{!unobservedTestDone ? (
							<>
								{/* <Badge content={'New'} type={'success'} /> */}
								<Icon icon={"house"} type={"light"} color={"green"} />
								<div className="text-center">
									<Text content={mainAppState!.texts["covid19.medical.unobserved.button.sub_text"] ?? "Unobserved/At Home"} type={"h4"} bold />
									<Text content={mainAppState!.texts["covid19.medical.unobserved.instruction"] ?? "You are not fully vaccinated and need a frequent test."} />
								</div>
								<div className="d-flex justify-content-center">
									<Button
										text={mainAppState!.texts["covid19.medical.unobserved.button"] ?? "Take my test"}
										outlined
										onClick={() => {
											mainAppState!.activeDrawer = ActiveDrawer.UnobservedTesting;
										}}
									/>
								</div>
							</>
						) : (
							<>
								<img src="images/hazardkit.png" alt="" />
								<Text content={mainAppState!.texts["covid19.medical.unobserved.followup.title"]} type={"h3"} bold />
								<Text content={mainAppState!.texts["covid19.medical.unobserved.dropoff.instructions"]} type={"body"} align={"left"} />
							</>
						)}
					</div>
					{unobservedTestDone && (
						<div aria-busy="false" role="list" className="list-group list-group-flush">
							<MedicalLocations dropOfforPickUp={"unobserved_drop_off"} />
							<ListItem
								icon="trash"
								text={mainAppState!.texts["covid19.medical.unobserved.cancel_test.link"]}
								className={"custom-item list-group-flush red-icon"}
								actions={[{ icon: "chevron-right", state: ButtonState.Enabled }]}
								onClick={() => setShowConfirmCancelUnobservedTest(true)}
							/>
						</div>
					)}
				</div>
			</>
		);
	};

	const MaxTestCountReachedCard = (): JSX.Element => {
		return (
			<div className={`medical-options`}>
				<div className={"card rounded"} tabIndex={0}>
					<div style={{ opacity: 0.5 }}>
						<Icon icon={"ban"} type={"light"} />
					</div>
					<div className="text-center">
						<Text content={mainAppState!.texts["covid19.optional_max_test.title"] ?? "Maximum test count for week reached"} type={"h4"} bold />
						<Text content={mainAppState!.texts["covid19.optional_max_test.instruction"] ?? "You do not need more than 1 test a week at this stage."} />
					</div>
				</div>
			</div>
		);
	};

	const OptionalTestingCard = (): JSX.Element => {
		return (
			<Container
				padding={3}
				raised={false}
				contents={
					<div className="p-4">
						<div style={{ fontSize: "4rem" }} className="d-flex justify-content-center align-items-center">
							<span style={{ width: "4rem" }}>
								<Icon icon="shield-check" type="regular" color="green" />
							</span>
						</div>
						<div className="text-center">
							<Text content={mainAppState!.texts["covid19.optional_test.title"] ?? "You do not need to take a test"} type="h3" />
							<Text content={mainAppState!.texts["covid19.optional_test.info"] ?? "You are fully vaccinated & benefit from exemption on testing cadence."} />
						</div>
						{mainAppState!.status_model.can_retest && (
							<div className="text-center">
								<Button
									outlined
									text={mainAppState!.texts["covid19.optional_test.button.text"] ?? "Take test anyway"}
									onClick={() => {
										mainAppState!.activeDrawer = ActiveDrawer.UnobservedTesting;
									}}
								/>
							</div>
						)}
					</div>
				}
			/>
		);
	};

	const CantRetestCard = (): JSX.Element => {
		return (
			<Container
				padding={3}
				raised={false}
				contents={
					<div className="p-4">
						<div style={{ fontSize: "4rem" }} className="d-flex justify-content-center align-items-center">
							<span style={{ width: "4rem", opacity: 0.5 }}>
								<Icon icon="ban" type={"light"} />
							</span>
						</div>
						<div className="text-center">
							<Text content={mainAppState!.texts["covid19.pass.unobserved_cant_retest.info"] ?? "You have recently tested positive and therefore cannot re-test until 90 days after that test date."} bold />
						</div>
					</div>
				}
			/>
		);
	};

	return (
		<>
			<div className="medical-drawer">
				<UnobservedTesting />
				<ActionList type="flush" items={listData} />
			</div>
			<NotifyMedicalDrawer show={showNotifyMedicalDrawer} onClose={() => setShowNotifyMedicalDrawer(false)} />
			<Drawer
				themedColor={"medical"}
				className={"userUpdateForm"}
				header={<></>}
				type={DrawerType.Right}
				layout={DrawerLayout.Clean}
				size={DrawerSize.Small}
				show={showInfoUpdate}
				contents={
					<>
						<Text type={"h3"} content={mainAppState!.texts["covid19.pass.update.user_info.title"]} />
						<Text type={"body"} content={mainAppState!.texts["covid19.pass.update.user_info.sub_text"]} />
						<Form formValidationTrigger={"onSubmit"}>
							{(methods) => {
								const onSave = () => {
									methods.trigger().then((valid) => {
										if (!valid) {
											return null;
										}
										setBusy(true);
										// console.log('key::userInfo', userInfo)
										// setTimeout(() => {
										// 	setBusy(false)
										// }, 2000);
										dataController
											.postUserInfo(userInfo)
											.then(() => {
												const featuresPromise = userController.fetchFeatures();
												const unobservedStatusPromise = dataController.getUnobservedStatus();
												Promise.all([featuresPromise, unobservedStatusPromise])
													.then(([featureResponse, unobservedStatusResponse]) => {
														setInfoUpdate(false);
														setBusy(false);
														let permissions = featureResponse.reduce((obj: any, curr: any) => {
															return { ...obj, [curr.id]: curr.value };
														}, {});

														mainAppState!.features = permissions;
														setUnobservedTest(permissions["covid19.pass.unobserved_test"]);

														mainAppState!.updateUnobservedStatus(unobservedStatusResponse.data);
														if (!!unobservedStatusResponse.data.barcode && !unobservedStatusResponse.data.canSubmit) {
															mainAppState!.unobservedTestDone = true;
															setUnobservedTestDone(true);
														} else {
															mainAppState!.unobservedTestDone = false;
															setUnobservedTestDone(false);
														}
													})
													.catch((err) => console.error(err));
											})
											.catch((err) => console.error(err));
									});
								};
								return (
									<>
										<FormContainer methods={methods} formValidationTrigger="onSubmit" submitText="Save" id="userUpdate">
											<GridContainer showGutters={true}>
												<LayoutColumn colSize={12}>
													{typeAddress ? (
														<>
															<FormField
																id="addressOne"
																editor="textbox"
																onChange={(e: any) => setUserInfo({ ...userInfo, address1: e.target.value })}
																label="Address 1"
																placeholder="Street"
																labelDisplay="nextto"
																required
															/>
															<FormField
																id="addressTwo"
																editor="textbox"
																onChange={(e: any) => setUserInfo({ ...userInfo, address2: e.target.value })}
																label="Address 2"
																placeholder="Appartment/building number"
																labelDisplay="nextto"
															/>
															<FormField
																id="city"
																editor="textbox"
																onChange={(e: any) => setUserInfo({ ...userInfo, city: e.target.value })}
																label="City"
																placeholder="City"
																labelDisplay="nextto"
																required
															/>
															<FormField
																id="state"
																editor="textbox"
																onChange={(e: any) => setUserInfo({ ...userInfo, state: e.target.value })}
																label="State"
																placeholder="State"
																labelDisplay="nextto"
																required
															/>
															<FormField
																id="postalCode"
																editor="textbox"
																onChange={(e: any) => setUserInfo({ ...userInfo, postal_code: e.target.value })}
																label="Postal Code"
																placeholder="Zip Code / Postal Code"
																labelDisplay="nextto"
																required
															/>
														</>
													) : (
														<FormField
															id="address"
															editor="locationsearch"
															onChange={(v: any) => {
																// console.log('key::locationsearch', v)
																setUserInfo({
																	...userInfo,
																	address1: v.text.split(",")[0],
																	address2: "",
																	city: v.city,
																	state: v.state ? v.state.id.split("-")[1] : null,
																	postal_code: v.postal_code,
																});
															}}
															label="Address"
															placeholder="Search for your address"
															labelDisplay="nextto"
															required
															properties={{ ["country"]: "US" }}
														/>
													)}
													<FormField
														id="dob"
														editor="calendar"
														onChange={(v: any) => setUserInfo({ ...userInfo, dob: v })}
														label="Date of birth"
														isRangeSelected={false}
														isMultiSelect={false}
														maxDate={maxDate}
														shouldHighlightWeekends={true}
														placeholder="Please select a date of birth"
														theme="Pink"
														labelDisplay="nextto"
														required
													/>
												</LayoutColumn>
											</GridContainer>
										</FormContainer>
										<div className="d-flex" style={{ flexDirection: "column", textAlign: "center" }}>
											<Button
												text={mainAppState!.texts["covid19.pass.update.user_info.address.button"]}
												icon={"keyboard"}
												type={"textNaked"}
												onClick={() => setTypeAddress(!typeAddress)}
											/>
											<Button
												icon="save"
												text="Save"
												state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
												isBusy={isBusy}
												isFormSubmitButton={true}
												onClick={() => onSave()}
											/>
										</div>
									</>
								);
							}}
						</Form>
					</>
				}
				footer={[]}
				onClose={() => setInfoUpdate(false)}
			/>

			{/* Report Symptoms Modal */}
			<TemplateModal
				body={<Text content={mainAppState!.texts["covid19.pass.report_symptoms.text"]} properties={{ role: "main" }} />}
				bodyAlignment={TemplateModalAlignment.Left}
				show={showConfirmSymptoms}
				onClose={() => setShowConfirmSymptoms(false)}
				header={<Text content={"Confirmation"} type={"h3"} />}
				size={TemplateModalSize.Default}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button
							type={"secondary"}
							overwriteColor={"danger"}
							onClick={() => {
								setConfirming(true);
								getLatestPhoneNr();
								setShowConfirmSymptoms(false);
								// setShowConfirmPhone(true)
							}}
							text={"I have COVID symptoms"}
							isBusy={isConfirming}
						/>
						&nbsp;
						<Button
							type={"secondary"}
							outlined
							onClick={() => setShowConfirmSymptoms(false)}
							text={"I dont have COVID symptoms"}
							state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled}
						/>
					</div>
				}
				name="symptomsDialog"
			/>

			{/* Confirm phone number modal */}
			<TemplateModal
				// body={<Text content={mainAppState!.texts["covid19.pass.report_symptoms.text"]} properties={{role: 'main'}} />}
				body={<Text content={`MIT medical will call you at ${userProfileStoreState!.userProfile.phoneNr}`} properties={{ role: "main" }} />}
				bodyAlignment={TemplateModalAlignment.Left}
				show={showConfirmPhone}
				onClose={() => setShowConfirmPhone(false)}
				header={<Text content={"Is this phone number correct?"} type={"h3"} />}
				size={TemplateModalSize.Default}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button type={"secondary"} onClick={() => confirmedPhoneNr(true)} text={"The number is correct"} state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled} />
						&nbsp;
						<Button
							type={"secondary"}
							outlined
							onClick={() => {
								setConfirming(true);
								confirmedPhoneNr(false);
							}}
							text={"Update the number"}
							isBusy={isConfirming}
						/>
					</div>
				}
				name="confirmPhoneDialog"
			/>

			{/* Update phone number modal */}
			<TemplateModal
				body={
					<>
						<Text content={mainAppState!.texts["covid19.medical.update_phone.text"]} properties={{ role: "main" }} />
						<div style={{ marginTop: 20 }}>
							<Textbox
								compact
								endIconRequired={false}
								masked={"tel"}
								max={10}
								maxLength={500}
								min={10}
								minLength={0}
								name="phoneValidated"
								// pattern={{
								//   message: 'Phone format',
								//   value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
								// }}
								placeholderText="Your current phone number"
								properties={{
									"aria-label": "phone number",
								}}
								value={phoneNr}
								onChange={(e: React.BaseSyntheticEvent) => setPhoneNr(e.target.value)}
								required
								type={4}
								width={100}
							/>
						</div>
					</>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={showUpdatePhone}
				onClose={() => setShowUpdatePhone(false)}
				header={<Text content={mainAppState!.texts["covid19.medical.update_phone.title"]} type={"h3"} />}
				size={TemplateModalSize.Default}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button
							type={"secondary"}
							onClick={() => {
								setConfirming(true);
								updatePhoneNr(phoneNr);
							}}
							text={mainAppState!.texts["covid19.button.ok"]}
							state={phoneNr.replace(/\D/g, "").length === 10 ? ButtonState.Enabled : ButtonState.Disabled}
							isBusy={isConfirming}
						/>
						&nbsp;
						{/* <Button type={'secondary'} outlined onClick={() => confirmedPhoneNr(true)} text={"The number is correct"} state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled} /> */}
					</div>
				}
				name="updatePhoneDialog"
			/>

			{/* Cancel Unobserved Test Modal */}
			<TemplateModal
				body={<Text content={mainAppState!.texts["covid19.medical.unobserved.cancel_test.text"]} />} // Temp. Text to be loaded from API?
				bodyAlignment={TemplateModalAlignment.Left}
				show={showConfirmCancelUnobservedTest}
				onClose={() => setShowConfirmCancelUnobservedTest(false)}
				header={<Text content={mainAppState!.texts["covid19.medical.unobserved.cancel_test.title"]} type={"h3"} />}
				size={TemplateModalSize.Small}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button
							type={"secondary"}
							outlined
							onClick={() => setShowConfirmCancelUnobservedTest(false)}
							text={mainAppState!.texts["covid19.medical.unobserved.cancel_test.button.secondary"]}
							state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled}
						/>
						&nbsp;
						<Button
							type={"secondary"}
							onClick={() => {
								setConfirming(true);
								cancelUnobservedTest();
							}}
							text={mainAppState!.texts["covid19.medical.unobserved.cancel_test.button.primary"]}
							isBusy={isConfirming}
						/>
					</div>
				}
				name="cancelTestDialog"
			/>

			{/* Cannot Cancel Test Modal */}
			<TemplateModal
				body={<Text content={mainAppState!.texts["covid19.medical.unobserved.cancel_test.error.text"]} />} // Temp. Text to be loaded from API?
				bodyAlignment={TemplateModalAlignment.Left}
				show={showCannotUpdate}
				onClose={() => setShowCannotUpdate(false)}
				header={<Text content={mainAppState!.texts["covid19.medical.unobserved.cancel_test.error.title"]} type={"h3"} />}
				size={TemplateModalSize.Small}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button
							type={"secondary"}
							outlined
							onClick={() => setShowCannotUpdate(false)}
							text={mainAppState!.texts["covid19.button.ok"]}
							state={isConfirming ? ButtonState.Disabled : ButtonState.Enabled}
						/>
						&nbsp;
						{/* <Button type={'secondary'} onClick={() => {
							setConfirming(true)
              cancelUnobservedTest()
						}} text={mainAppState!.texts['covid19.medical.unobserved.cancel_test.button.primary']} isBusy={isConfirming} /> */}
					</div>
				}
				name="cannotCancelDialog"
			/>
		</>
	);
};

const MedicalLanding = withRouter(inject("mainAppState", "userProfileStoreState")(observer(MedicalLandingComponent)));
export default MedicalLanding;
