import * as React from 'react'
import {useEffect, useState} from "react";
import {AttestQuestions, NotEligibleReason} from "../../types/Questions";
import {Button, ButtonBar, ButtonBarType, ButtonProps, ButtonType, Icon, Spacer, Toggle} from "@mit/hui";
import {Alert} from "react-bootstrap";
import {PageError} from "../../types/PageErrors";
import {NotVaccinatedReason} from "./NotVaccinatedReason";

interface ModalOptions {
  show: boolean;
  title?: string;
  text?: string;
  submitFunction?: () => void
  button1Text?: string;
  button2Text?: string;
}

interface Props {
  onSubmit: (answer: {answer: string, reason: string}) => void
  question: AttestQuestions
  notEligibleReasons: NotEligibleReason[]
}

const ShortQuestionsComponent: React.FC<Props> = ({onSubmit, question, notEligibleReasons}) => {
  const [showError, setError] = useState<PageError>({error: false, errorText: '', showRetry: false})
  const [showAdditional, setShowAdditional] = useState<boolean>(false)
  const [answer, setAnswer] = useState<string>('')
  const [reason, setReason] = useState<string>('')
  
  useEffect(() => {
    setAnswer(question.answer)
    if (question.answer === 'none') {
      setShowAdditional(true)
      if (notEligibleReasons && notEligibleReasons.length) {
        const reasonResponse = notEligibleReasons.find((reason: any) => reason.reason_id === question.reason_id)
        if (reasonResponse) setReason(reasonResponse?.reason)
      }
    }
  }, [question, notEligibleReasons])

  useEffect(() => {
    if (answer === 'none') setShowAdditional(true)
  }, [answer])

  const renderQuestion = () => {
    const answerOptions = question.question_options.split(',')
    return (
      question.answer === 'partially' ? (
        <div className={'single-question'}>
          <div className={'question-text'}>
            {'Have you had all of your doses of the COVID-19 vaccine'}
          </div>
          <div className={'question-toggle'}>
            <Toggle 
              inverse  
              showBothOptions 
              positiveLabel="no" 
              negativeLabel="yes" 
              choice={"Positive"} 
              onClick={() => null} 
              onStateChanged={(v: any) => v.choice === "Negative" ? setAnswer('fully') : setAnswer('partially')} 
            />
          </div>
        </div>
      ) : (
        <div className="single-question column vaccine">
          <div className="question-text">
            <span aria-label={ question.question_text }>{question.question_text}</span>
          </div>
          <div className="single-question-btn-bar">            
            {answerOptions.map(option => (
              <a
                className={`${answer === option ? 'selected-answer-option' : 'unselected-answer-option'} ${option}`}
                onClick={() => {
                  setAnswer(() => option)
                  if (answer === 'no') {
                    setShowAdditional(true)
                  }
                  else {
                    setShowAdditional(false)
                  }
                }}
                tabIndex={0}
                aria-label={question.answer === option ? `${option} is selected` : `Hit enter to select ${option}`}
              >
                {answer === option && <Icon type="solid" icon="check" padded={true} />}
                {option}
              </a>
            ))}
          </div>
        </div>
      )
    )
  }

  const submitAnswers = () => {
    if (!answer.length) {
      setError({
        error: true,
        errorText: 'Please answer all questions and try again',
        showRetry: false
      })
    } else {
      onSubmit({answer, reason: reason})
    }
  }

  const handleReasonSelect = (reasonText: string, disqualify?: boolean) => {
    setReason(reasonText)
  }

  let buttonData: any = []
  if (notEligibleReasons) {
    for (let i = 0; i < notEligibleReasons.length; i++) {
      const Reason = {
        id: `${reason === `${notEligibleReasons[i].reason}` ? 'custom-btn-active' : 'custom-btn'}`,
        text: `${notEligibleReasons[i].reason}`, 
        type: ButtonType.Primary, 
        icon: `${notEligibleReasons[i].image}`,
        onClick: () => { handleReasonSelect(`${notEligibleReasons[i].reason}`, notEligibleReasons[i].disqualify)},
      }
      buttonData.push(Reason)
    }
  } 

  return (
    <>
      <div>
        {renderQuestion()}
      </div>

      {showAdditional && (
        <>
          <Spacer />
          <p style={{textAlign: 'left', paddingLeft: '1rem', paddingTop: '0.75rem'}}>Please indicate why you have not been vaccinated</p>
          <Spacer />
          <div className="not-vaccinated-reason-container">
            <ButtonBar 
              type={ButtonBarType.Padded} 
              buttons={buttonData} 
            />
          </div>
        </>
      )}
      {showError.error && <Alert variant="danger" className={'smallMarginTop'}>{showError.errorText}</Alert>}
      <div className="text-center w-100 mt-3">
        <Button
          text="Submit"
          onClick={submitAnswers}
          type={2}
        />
      </div>

    </>
  )
}

export const ShortQuestions = ShortQuestionsComponent
