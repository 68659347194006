import * as React from "react";
import { FC, useState, useEffect } from "react";
import { mainAppState, MainAppStateInterface, CovidTextType, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { toJS } from "mobx";
import { RequirementIds, RequirementStatus } from "../../types/CovidStatusModel";
import { Container, Text, Spacer, Button, ButtonType, TextType } from "@mit/hui";
import Vaccine from "./Vaccine";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import { VaccineStatusModel } from "../../types/VaccineStatusModel";

interface MedicalOverdueProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const MedicalOverdueComponent: FC<MedicalOverdueProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {}, []);

	const getButtonText = (): string => {
		return mainAppState!.texts[COVID_TEXTS["covid19.pass.action.medical.overdue"]];
	};

	const setDrawerState = () => {
		mainAppState!.activeDrawer = ActiveDrawer.Medical;
	};

	return (
		<>
			<Text content="Covid Pass" type={TextType.Heading1} />
			<span className="badge badge-warning">Action Required</span>
			<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
				<Vaccine />
			</Provider>
			<Text content={<>{mainAppState!.texts[COVID_TEXTS["covid19.pass.noaccess.sub"]]}</>} />

			<Spacer />
			<Container
				color={1} // dont use numbers like this, it makes it difficult to maintain, rather use enums
				alignment="left"
				contents={
					<>
						<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.pass.noaccess.actionbox"]]} />
						<Spacer />
						<div className="p-4 pt-0 text-center">
							<Button type={ButtonType.Secondary} text={getButtonText()} onClick={() => setDrawerState()} />
						</div>
					</>
				}
			/>
		</>
	);
};

const MedicalOverdue = inject("mainAppState")(observer(MedicalOverdueComponent));
export default MedicalOverdue;
