import appConfig from "../app-config";
export const getEnv = (url?: boolean) => {
    if (url) {
        switch (appConfig.stage) {
            case "develop":
                return "-dev";
            case "release":
                return "-test";
            case "master":
                return "";
        }
    } else {
        switch (appConfig.stage) {
            case "develop":
                return "dev";
            case "release":
                return "staging";
            case "master":
                return "production";
        }
    }
};
