import * as React from "react";
import { FC, useState, useEffect } from "react";
import { mainAppState, MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { Text, Spacer, Button, ButtonType, ButtonState, Alert, ActionList, ActionListType, TemplateModal, TemplateModalAlignment, TemplateModalSize, AlertProps, Container, Icon } from "@mit/hui";
import dataController from "../../api/DataController";
import Barcode from "../medical-test/Barcode";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import ReactMarkdown from "react-markdown";
import { FetchPassStatusResponse } from "types/Api";

interface MedicalSymptomsProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const MedicalSymptomsComponent: FC<MedicalSymptomsProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isRefreshLoading, setIsRefreshLoading] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [showMistakenReportModal, setShowMistakenReportModal] = useState<boolean>(false);
	const [message, setMessage] = useState<AlertProps>({ text: "" });
	// const getMedicalDetails = () => {
	//   return (
	//     <>
	//       <Text
	//         content={
	//           mainAppState!.texts[COVID_TEXTS['covid19.contactt.medicaltitle']]
	//         }
	//         type={TextType.Heading3}
	//       />
	//       <PersonTests />
	//       <Barcode />
	//     </>
	//   );
	// };

	const refreshStatus = () => {
		setIsRefreshLoading(true);
		dataController
			.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId)
			.then((statusResponse: FetchPassStatusResponse) => {
				if (!statusResponse.error) {
					mainAppState!.updatePersonStatus(statusResponse);

					setMessage({ text: "Successfully refreshed status", type: "success" });

					setTimeout(() => {
						setMessage({ text: "" });
					}, 5000);
				}
			})
			.finally(() => {
				setIsRefreshLoading(false);
			});
	};

	const handleMistakenReported = () => {
		setIsBusy(true);
		dataController
			.putPassStatus(6)
			.then((res) => {
				if (!res.error) {
					dataController
						.fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId)
						.then((statusResponse) => {
							if (!statusResponse.error) {
								mainAppState!.updatePersonStatus(statusResponse);
							}
						})
						.finally(() => {
							setIsBusy(false);
						});
				} else {
					setMessage({ text: res.error, type: "error" });

					setTimeout(() => {
						setMessage({ text: "" });
					}, 5000);
					setIsBusy(false);
				}
			})
			.finally(() => {
				setShowMistakenReportModal(false);
			});
	};

	const isLinconLabs = (): boolean => {
		return mainAppState!.status_model.assignment_type === 9 
	}

	return (
		<div className="hold-container container p-4">
			{message.text && <Alert {...message} />}
			<Container
				contents={
					<div className="m-1">
						<div className="row">
							<div className="col-lg-1 col-md-1 col-sm-12">
								<div className="d-flex justify-content-center align-items-center big-icon">
									<Icon icon="exclamation-circle" type="regular" />
								</div>
							</div>
							<div className="col-sm-11">
								<div className="py-2 d-flex flex-column text-lg-left text-center">
									<Text content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.title"]] ?? "You are disqualified from accessing MIT facilities.\nPlease self-isolate as a precaution."} bold type={"h4"} />
									{/* <ReactMarkdown className="no-margin" children={mainAppState!.texts["covid19.pass.sub"]}/> */}
									<Text content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.subtitle"]] ?? "An MIT Medical clinician will contact you as soon as possible. You will be contacted in the order of information received."} type={"body"} />
								</div>
							</div>
							<div className="full-width col-md-12 justify-content-center">
								<Button outlined text={mainAppState!.texts["covid19.notsafe.button.text"] ?? "I made a mistake"} onClick={() => setShowMistakenReportModal(true)} />
							</div>
						</div>
					</div>
				}
				raised={false}
				padding={3}
			/>
			<Spacer size="3" />
			<Container
				contents={
					<div className="m-1" style={{cursor: "pointer"}} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Medical}>
						<div className="row align-items-center" style={{width: "100%", margin: "0 auto"}}>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-start">
									<Icon icon="microscope" type="regular" />
								</div>
							</div>
							<div className="col-sm-10">
								<div className="py-2 d-flex flex-column text-lg-left text-center no-margin">
									<Text content={mainAppState!.texts["covid19.pass.testing.title"] ?? "Testing"} bold type={"h4"} />
									<Text content={mainAppState!.texts["covid19.pass.testing.info"] ?? "Unobserved testing and history"} type={"body"} />
								</div>
							</div>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-end">
									<Icon icon="chevron-right" type="regular" />
								</div>
							</div>
						</div>
					</div>
				}
				raised={false}
				padding={1}
			/>
			<Spacer size="3" />
			{!isLinconLabs() && (
				<div className="report-btn">
					<Button type={ButtonType.Primary} text={mainAppState!.texts["covid19.pass.notify_mit.action"] ?? "I need to report..."} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.ReportOptions} />
				</div>
			)}
			{/* <h4>
				<p>{mainAppState!.texts[COVID_TEXTS["covid19.notsafe.title"]] ?? "You are disqualified from accessing MIT facilities.\nPlease self-isolate as a precaution."}</p>
			</h4>
			<Spacer />
			<p>
				{mainAppState!.texts[COVID_TEXTS["covid19.notsafe.subtitle"]] ??
					"An MIT Medical clinician will contact you as soon as possible. You will be contacted in the order of information received."}
			</p>
			<Spacer /> */}
			{/* <div className="py-2 px-2">
				<ActionList
					type={ActionListType.Flush}
					items={[
						{
							icon: "microscope",
							text: mainAppState!.texts["covid19.medical_hold.testing.link"] ?? "View Testing Information",
							onClick: () => (mainAppState!.activeDrawer = ActiveDrawer.Medical),
							actions: [
								{
									icon: "chevron-right",
									type: ButtonType.IconNaked,
									onClick: () => (mainAppState!.activeDrawer = ActiveDrawer.Medical),
								},
							],
						},
					]}
				/>
			</div> */}
			{/* <Spacer size="2" />
			<div>
				{mainAppState?.status_model.assignment_type !== 9 && (
					<div
						style={{ cursor: "pointer" }}
						onClick={() => {
							if (mainAppState?.texts["covid19.notsafe.link.url"]) window.open(mainAppState?.texts["covid19.notsafe.link.url"]);
						}}>
						<ReactMarkdown linkTarget={"_blank"} children={mainAppState?.texts["covid19.notsafe.link.markdowntext"] ?? ""} />
					</div>
				)}
			</div> */}
			{/* <Spacer size="2" />
			<div className="d-flex justify-content-center">
				<Button outlined text={mainAppState!.texts["covid19.notsafe.button.text"] ?? "I made a mistake"} onClick={() => setShowMistakenReportModal(true)} />
			</div> */}
			{/* <Spacer size="2" />
			<div className="d-flex justify-content-center">
				<Button
					isBusy={isRefreshLoading}
					type={ButtonType.TextNaked}
					state={isRefreshLoading ? ButtonState.Disabled : ButtonState.Enabled}
					icon="sync-alt"
					text="Refresh my status"
					onClick={() => refreshStatus()}
				/>
			</div>
			{mainAppState!.features["covid19.app.medical.always.show.barcode"] && <Barcode />} */}
			<TemplateModal
				body={<Text content={mainAppState!.texts["covid19.notsafe.popup.message"] ?? "I have misjudged my symptoms"} properties={{ role: "main" }} />}
				bodyAlignment={TemplateModalAlignment.Left}
				show={showMistakenReportModal}
				onClose={() => setShowMistakenReportModal(false)}
				header={<Text content={mainAppState!.texts["covid19.notsafe.popup.title"] ?? "Mistaken Report"} type={"h3"} />}
				size={TemplateModalSize.Default}
				theme="medical"
				imageUrl=""
				footer={
					<div>
						<Button overwriteColor="danger" outlined onClick={() => setShowMistakenReportModal(false)} text={mainAppState!.texts["covid19.notsafe.popup.submit"] ?? "I have symptoms"} />
						&nbsp;
						<Button onClick={handleMistakenReported} text={mainAppState!.texts["covid19.notsafe.popup.cancel"] ?? "I DON'T have symptoms"} isBusy={isBusy} state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} />
					</div>
				}
				name="confirmPhoneDialog"
			/>

			{/* LEGACY CODE */}
			{/* <Text content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.results.title"]]} type={TextType.Heading1} />
			<Spacer />
			<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.notsafe.subtitle"]]} />
			<Spacer />
			<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
				<Vaccine />
			</Provider>
			<Spacer />
			<div style={{ textAlign: "center" }}>
				<Button
					elevation="0"
					onClick={() => {
						mainAppState!.activeDrawer = ActiveDrawer.Medical;
					}}
					padded
					text={mainAppState!.texts["covid19.medical_hold.testing.link"]}
					type="primary"
				/>
			</div>
			<Spacer />
			<br />
			{mainAppState?.status_model.assignment_type !== 9 && (
				<div style={{ textAlign: "center" }}>
					<div
						onClick={() => {
							window.open(mainAppState!.texts["covid19.notsafe.link.url"]);
						}}>
						{ReactHtmlParser(mainAppState!.texts["covid19.notsafe.link.text"])}
					</div>
				</div>
			)} */}
			{/* <Spacer />
      <div className='content-area'>{getMedicalDetails()}</div> */}
		</div>
	);
};

const MedicalSymptoms = inject("mainAppState")(observer(MedicalSymptomsComponent));
export default MedicalSymptoms;
