import {action, autorun, computed, decorate, observable} from "mobx";

export interface UserProfile {
    mitId?: string;
    kerberosId?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    phoneNr?: string;
    canSubmitOnBehalf: boolean;
}

export interface UserProfileStoreInterface {
    userProfile: UserProfile;
}

export class UserProfileStore {
    userProfile: UserProfile = {canSubmitOnBehalf: false}
    

    setUserProfile(userProfile: UserProfile) {
        this.userProfile = {
            ...userProfile
        }
    }

    setCurrentUser(mitId: string, kerberosId: string, firstName: string, lastName: string, phoneNr:string, displayName: string, canSubmitOnBehalf: boolean): void {
        this.userProfile = {
            mitId, kerberosId, firstName, lastName, phoneNr, displayName, canSubmitOnBehalf
        }
    }

    get currentUser(): UserProfile {
        return this.userProfile
    }
}

decorate(UserProfileStore, {
    currentUser: computed,
    setCurrentUser: action,
    userProfile: observable
})

export const userProfileStoreState = new UserProfileStore()