import * as React from 'react'
import { FC, useEffect } from 'react'
import { useState } from "react";
import { Alert, Row } from "react-bootstrap";
import 'react-day-picker/lib/style.css';
import dataController from "../../api/DataController";
import { mainAppState, MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer } from "mobx-react";
import { PageError } from "../../types/PageErrors";
import ErrorMessage from "../../common/ErrorMessage";
import { withRouter } from "react-router";
import Loader from 'react-loader'
import { mediumLoaderOptions } from "../../common/Defaults";
import { COVID_TEXTS } from "../../common/Texts";
import waiverApi, { WaiverInterface, WaiverResponse } from "./WaiverApi";
import { Button, ButtonType } from '@mit/hui';
import $ from 'jquery'
import { PostWaiverResponse, FetchPassStatusResponse } from 'types/Api';
import mitLogo from 'svg/mit-logo-black.png'

export interface WaiverProps {
    mainAppState?: MainAppStateInterface;
    history?: any;
}


const WaiverComponent: FC<WaiverProps> = ({ mainAppState, history }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })

    const [validationError, setValidationError] = useState(false)

    const [waivers, setWaivers] = useState<WaiverInterface[] | undefined>(undefined)
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [agreements, setAgreements] = useState<any>({})

    useEffect(() => {
        
        waiverApi.fetchWaiverTextAll().then((resp: WaiverResponse) => {
            setIsLoading(false)

            if (!resp.error) {
                setWaivers(resp.waivers)
                setAgreements(resp.waivers!.reduce((total, curr, i) => ({ ...total, [i]: undefined }), {}))

                mainAppState!.stepperSelectedIndex = 0;
                mainAppState!.stepperItemCount = resp.waivers!.length;

            } else {
                setPageError({
                    error: true,
                    errorText: `An error occurred loading the forms. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS['covid19.pass.help_email']]}.`,
                    showRetry: false
                })
            }
        })
    }, [])

    const clickAgreeButton = () => {

        agreements[currentStep] = true;

        setAgreements(agreements)

        submit(true, true);
    }

    const clickOptoutButton = () => {

        agreements[currentStep] = true;

        setAgreements(agreements)

        submit(false, true);
    }

    const submit = (agree: boolean, analyticsChecked: boolean) => {

        if (currentStep === waivers!.length - 1 || !agree) { //last step or declined
            let analyticsValue: boolean | undefined = analyticsChecked

            if (mainAppState!.features['coivd19.web.waiver.analytics']) {
                analyticsValue = analyticsChecked === true
            } else {
                analyticsValue = undefined
            }

            let agree = agreements[waivers!.filter((w: WaiverInterface) => w.type === 'waiver').length - 1]
            submitAgreement(!!agree, analyticsValue)
        } else {
            $('.drawer').scrollTop(0);
            setCurrentStep(currentStep + 1);
        }

        if ((mainAppState!.stepperItemCount - 1) !== mainAppState!.stepperSelectedIndex) {
            mainAppState!.stepperSelectedIndex = currentStep + 1;
        }
    }

    const submitAgreement = (agreed: boolean, analyticsAgreed: boolean | undefined) => {
        setIsLoading(true)

        dataController.postWaiver(agreed, analyticsAgreed).then((result: PostWaiverResponse) => {

            if (result.success) {
                dataController.fetchPassStatus().then((statusResponse: FetchPassStatusResponse) => {
                    setIsLoading(false);

                    mainAppState!.cancelOnBehalfTransaction();
                    mainAppState!.updatePersonStatus(statusResponse);
                    mainAppState!.activeDrawer = ActiveDrawer.None;
                })
            } else {
                setIsLoading(false);

                setPageError({
                    error: true,
                    errorText: `An error occurred submitting your waiver. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS['covid19.pass.help_email']]}.`,
                    showRetry: false
                })
            }
        })
    }

    return (
        <>
            <Loader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: 'black' }}>
                <div className={'splash-container p-4'}>
                    {waivers && <Row>

                        <img src={mitLogo} className={'splash-icon'} alt="MIT-logo" />
                        <div className={'splash-text'}>
                            <h1>{waivers[currentStep].ack_title}</h1>
                            <div className={'waiver-text'}>
                                {waivers && <div dangerouslySetInnerHTML={{ __html: waivers[currentStep].text }} role="main" />}
                            </div>
                        </div>

                        {validationError &&
                            <Alert variant={'danger'} className={'smallMarginTop'}>Please review your answers and try
                                again.</Alert>}
                        <div className="text-right w-100 opt-btns">

                            {waivers[currentStep].type === 'analytics' && mainAppState!.features['coivd19.web.waiver.analytics'] &&
                                <>
                                    <Button theme="medical" text={'Opt out'} type={ButtonType.Primary | ButtonType.Ghost} icon="times"
                                        onClick={clickOptoutButton} />
                                </>
                            }

                            <Button theme="medical-dark" text={'I agree to the above'} type={ButtonType.Primary} icon="check"
                                onClick={clickAgreeButton} />
                        </div>
                        {pageError.error && <ErrorMessage text={pageError.errorText} className={'color-black'} />}


                    </Row>}
                </div>
            </Loader>
        </>
    )
}

const Waiver = withRouter(inject('mainAppState')(observer(WaiverComponent)))
export default Waiver
