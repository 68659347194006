import * as React from "react";
import { FC, useState, useEffect } from "react";
import { ActiveDrawer, mainAppState, MainAppStateInterface } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { Container, Text, Spacer, Button, ButtonType, TextType, Icon, TemplateTwoColumns } from "@mit/hui";
// import PersonTests from "../medical-test/PersonTests";
import Barcode from "../medical-test/Barcode";
import { RequirementIds, RequirementStatus } from "../../types/CovidStatusModel";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import Vaccine from "./Vaccine";

interface NoAccessTestingProps {
    mainAppState?: MainAppStateInterface;
    userProfileStoreState?: UserProfileStoreInterface;
}

const NoAccessTestingComponent: FC<NoAccessTestingProps> = ({ mainAppState, userProfileStoreState }) => {
    const getWaiverText = (): any => {
        return (
            <>
                <Icon icon="check" type="regular" /> {mainAppState!.texts[COVID_TEXTS["covid19.noaccess.wtesting.waiver"]]}
            </>
        );
    };

    // return true if ack has been completed.
    const IsNoAccessWithTesting = () => {
        if (mainAppState!.getRequirement(RequirementIds.acknowledge).required && mainAppState!.evalRequirement(RequirementIds.acknowledge, RequirementStatus.complete)) {
            return true;
        }

        return false;
    };

    const getButtonText = (): string => {
		//TODO: Get this from COVID_TEXTS
		return "View medical results";
	};

    const setDrawerState = () => {
		mainAppState!.activeDrawer = ActiveDrawer.TestingHistory;
	};

    const renderNoAccessTesting = () => {
        return (
            <>
                {IsNoAccessWithTesting() && (
                    <>
                        <Text content={mainAppState!.texts[COVID_TEXTS["covid19.noaccess.wtesting.title"]]} type={TextType.Heading1} />

                        <Spacer />

                        <div dangerouslySetInnerHTML={{ __html: mainAppState!.texts[COVID_TEXTS["covid19.noaccess.wtesting.text"]]}} className="my-3" />

                        <Spacer />

                        <Container
                            color="green"
                            alignment="left"
                            contents={
                                <>
                                    <div className="pl-4 pr-4 text-align-center">
                                        <Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.noaccess.instructions"]]} />
                                        <Spacer />
                                    </div>
                                    <div className="p-4 pt-0 text-center">
                                        <Button type={ButtonType.Primary} text={getButtonText()} onClick={setDrawerState} />
                                    </div>
                                </>
                            }
                        />

                        <Spacer />

                        <Barcode />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
                <Vaccine />
            </Provider>
            <Spacer />

            {renderNoAccessTesting()}
        </>
    );
};

const NoAccessTesting = inject("mainAppState")(observer(NoAccessTestingComponent));
export default NoAccessTesting;
