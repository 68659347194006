import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {inject, observer} from "mobx-react";
import {MainAppStateInterface} from "../../../model/MainAppStateStore";
import OnBehalfHeader from "../../onBehalf/OnBehalfHeader";
import {UserProfileStoreInterface} from "../../../model/UserProfileStore";
import Barcode from "../../medical-test/Barcode";
import {PassStatus, HoldReason} from "../../../types/CovidStatusModel";
import { COVID_TEXTS, default as CovidText } from "../../../common/Texts";
import moment from "moment";
import QueueLengths from 'components/medical-test/QueueLengths';
import {
	Text
} from "@mit/hui";

interface LandingNotClearedProps {
    mainAppState?: MainAppStateInterface;
    userProfileStore?: UserProfileStoreInterface;
}

const LandingNotClearedComponent: FC<LandingNotClearedProps> = ({mainAppState, userProfileStore}) => {
    const [displayName, setDisplayName] = useState('')

    useEffect(() => {
        if (mainAppState!.submittingOnBehalfName != '') {
            setDisplayName(mainAppState!.submittingOnBehalfName)
        } else {
            // userController.fetchProfile(mainAppState!.submittingOnBehalfKerbId).then((profile: any) => {
            //     setDisplayName(profile.display_name)
            // })
            setDisplayName(`${userProfileStore!.userProfile.firstName} ${userProfileStore!.userProfile.lastName}`)
        }

    }, [])

    const showQueueLengths = (): boolean => {
		// return mainAppState!.features["covid19.pass.medical_queue"] && mainAppState!.getRequirement(RequirementIds.medical).required;
		return mainAppState!.features["covid19.pass.medical_queue"]
	};

    return <div className={'content-page smallPaddingTop'}>

        <OnBehalfHeader/>

        <div className={'building-container-main'}>
            {/* <div className={'building-container blue'} style={{textAlign: 'center'}}>
                <Text content={displayName} type={"h3"} />
                {mainAppState!.status_model.hold_reason === HoldReason.awaiting_test_outcome &&
                    <div>
                        <p className={'nosafe-title'}>{mainAppState!.texts['covid19.medical.hold.awaiting_test_outcome.title']}</p>

                        <p className={'nosafe-body'}>{mainAppState!.texts['covid19.medical.hold.awaiting_test_outcome.text']}</p>

                    </div>
                }
                {mainAppState!.status_model.hold_reason === HoldReason.none &&
                    <div>
                        <CovidText textId={COVID_TEXTS['covid19.pass.disqualified']} />
                    </div>
                }
                {mainAppState!.status_model.status !== PassStatus.medical_overdue ? <div>
                </div> : <div>
                    <CovidText textId={COVID_TEXTS['covid19.pass.medical_overdue']}/>
                    </div>}

                {mainAppState!.status_model.hold_expiry !== null && mainAppState!.status_model.hold_expiry !== '' &&
                    <div><p>Hold Release: {moment(mainAppState!.status_model.hold_expiry).format('ll')}</p></div>
                }
            </div>
            {mainAppState!.status_model.status === PassStatus.medical_overdue &&
            <div className={'white-content max-width-content text-align-center'}>
                <CovidText textId={COVID_TEXTS['covid19.pass.must_test']}/>
            </div>} */}

            {showQueueLengths() && <QueueLengths />}

            <div className={'smallMarginTop'}>
                <Barcode/>
            </div>
        </div>
    </div>
}

const LandingNotCleared = inject('mainAppState', 'userProfileStore')(observer(LandingNotClearedComponent))
export default LandingNotCleared
