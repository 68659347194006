import * as React from 'react'
import { FC, useState } from 'react'
import { Alert, Col, Container, Row, Tabs, Tab, Form } from "react-bootstrap";
import dataController from "../../api/DataController";
import { useEffect } from "react";
import './QueueLengths.scss'
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Spacer, TemplateModal, TemplateModalSize, TemplateModalAlignment, Text, TextType, ListItem } from '@mit/hui';
import { LocalStorage, StorageKeys } from '../../common/LocalStorage';
import moment from 'moment';
import { MedicalQueueLengths, FetchMedicalQueueLengthResponse } from 'types/Api';

const QueueLengths: FC<any> = ({ mainAppState }) => {

    const [queueLengths, setQueueLengths] = useState<{ [name: string]: MedicalQueueLengths[] }>({})
    const [favoriteLocationId, setFavoriteLocationId] = useState<number>(0)
    const [selectionError, setSelectionError] = useState<string>('')
    const [activeTab, setActiveTab] = useState<string>('Today')
    const [isShowingPopup, setIsShowingPopup] = useState(false);

    useEffect(() => {
        let abortController = new AbortController();
        let favLocId = LocalStorage.get(StorageKeys.FAVORITE_LOCATION);

        setFavoriteLocationId(favLocId);

        dataController.fetchMedicalQueueLengths(abortController.signal, 7).then((response: FetchMedicalQueueLengthResponse) => {
            if (!response.error) {

                /* Group data by day_title */
                let groupedData: { [name: string]: MedicalQueueLengths[] } = response.items!.reduce((prev: any, curr: MedicalQueueLengths) => {
                    if (!prev[curr.day_title])
                        prev[curr.day_title] = {};

                    if (curr.day_title === "Today")
                        curr.favorite = (curr.location_id === favLocId);
                    else
                        curr.favorite = undefined;

                    /* Create single object instead of array of objects for easier state update. */
                    prev[curr.day_title] = { ...prev[curr.day_title], ...{ [curr.location_id]: curr } };

                    return prev;
                }, {});

                if (favLocId == null) {
                    if (response.items && response.items?.length > 0) {
                        setFavoriteLocationId(response.items[0].location_id);
                    }
                }   

                setQueueLengths(groupedData);
            }
            else
                setSelectionError(response.error);
        }).catch(() => {
            //TODO
        })

        return () => (abortController.abort())
    }, [])

    const showWaitTime = (closeTime: string) => {

        let todayClose = moment(moment().format("YYYY-MM-DD ") + closeTime, "YYYY-MM-DD HH:mm A");
        return (moment() < todayClose);
    };

    const favoriteClicked = (event: any) => {

        let selectedLocationId = event.target.value;
        let day_title = activeTab;
        let copyQueueLengths = { ...queueLengths };

        for (let locationId in copyQueueLengths[day_title])
            copyQueueLengths[day_title][locationId].favorite = (locationId === selectedLocationId);

        setQueueLengths(copyQueueLengths);

        setFavoriteLocationId(selectedLocationId);
        LocalStorage.save(StorageKeys.FAVORITE_LOCATION, selectedLocationId);
    }

//className={'text-align-center'}
    return <>
        <div>
            {favoriteLocationId && queueLengths["Today"] &&
                <div className={'favorite-location mx-auto'}>
                    <div>
                        Today
                    </div>
                    <div className={'inner'}>
                        {queueLengths["Today"][favoriteLocationId].is_open &&
                            <Row>
                                <Col xs={6}>
                                    <span className={'location'}>{queueLengths["Today"][favoriteLocationId].name}</span>
                                    <br />
                                    {queueLengths["Today"][favoriteLocationId].medical_queue_indicator && showWaitTime(queueLengths["Today"][favoriteLocationId].close_time) &&
                                        <span className={'favorite-location__body__wait-time'}>{queueLengths["Today"][favoriteLocationId].wait_time_text}</span>
                                    }
                                </Col>
                                <Col xs={2}>
                                    <span className={'filler-words'}>{mainAppState!.texts['covid19.medical.queue.from']}</span>
                                    <br />
                                    <span className={'filler-words'}>{mainAppState!.texts['covid19.medical.queue.to']}</span>
                                </Col>
                                <Col xs={4}>
                                    <span className={'favorite-location__body__open-close-time'}>{queueLengths["Today"][favoriteLocationId].open_time}</span>
                                    <br />
                                    <span className={'favorite-location__body__open-close-time'}>{queueLengths["Today"][favoriteLocationId].close_time}</span>
                                </Col>
                            </Row>
                        }
                        {!queueLengths["Today"][favoriteLocationId].is_open &&
                            <Row>
                                <Col xs={6}>
                                    <span className={'favorite-location__body__name'}>{queueLengths["Today"][favoriteLocationId].name}</span>
                                </Col>
                                <Col xs={3}>
                                </Col>
                                <Col xs={2}>
                                    <span className={'favorite-location__body__name'}>{mainAppState!.texts['covid19.medical.queue.closed']}</span>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            }
        </div>

        <div className="py-3 test-location text-center">
            <div role="list" className="list-group list-group-flush" >
                <ListItem icon={'clock'} text={mainAppState!.texts['covid19.medical.location.button']} onClick={() => setIsShowingPopup(true)} /> 
            </div>
        </div>
        <Spacer/>
        <Spacer/>
        <TemplateModal          
            body={
                <React.Fragment>
                    {selectionError !== '' && <Row><Col xs={12}><Alert variant={'danger'}>{selectionError}</Alert></Col></Row>}

                    <Tabs id="uncontrolled-tab-example" variant={"pills"}
                        activeKey={activeTab}
                        onSelect={(k: any) => setActiveTab(k)}>

                        {queueLengths && Object.keys(queueLengths).map((day: string) => {
                            return <Tab eventKey={day} title={day} key={day}>

                                {day === "Today" &&
                                    <div className={'queue-wait-select py-3'}>{mainAppState!.texts['covid19.medical.queue.default_location']}</div>
                                }

                                <Container>
                                    {Object.values(queueLengths[day]).map((queue: MedicalQueueLengths, i: number) => {
                                        return <>
                                            <Row className={'queue-wait'} key={i + '-' + queue.location_id + '-' + queue.day_title}>
                                                <Col xs={1}>
                                                    {queue.favorite !== null &&
                                                        <Form.Check type={'radio'} checked={queue.favorite} value={queue.location_id} onChange={favoriteClicked}></Form.Check>
                                                    }
                                                </Col>
                                                <Col xs={7}>
                                                    <span className={'queue-wait__name'}>{queue.name}</span>
                                                    <br />
                                                    {queue.medical_queue_indicator &&
                                                        <span className={'queue-wait__time-text'}>{queue.wait_time_text}</span>
                                                    }
                                                </Col>
                                                {queue.is_open &&
                                                    <Col xs={2} className="filler-words" >
                                                        <span className={'queue-wait__open-close'}>{mainAppState!.texts['covid19.medical.queue.from']}</span>
                                                        <br />
                                                        <span className={'queue-wait__open-close'}>{mainAppState!.texts['covid19.medical.queue.to']}</span>
                                                    </Col>
                                                }
                                                {!queue.is_open &&
                                                    <Col xs={2}>
                                                    </Col>
                                                }
                                                {queue.is_open &&
                                                    <Col xs={2}>
                                                        <span className={'queue-wait__open-close-time'}>{queue.open_time}</span>
                                                        <br />
                                                        <span className={'queue-wait__open-close-time'}>{queue.close_time}</span>
                                                    </Col>
                                                }
                                                {!queue.is_open &&
                                                    <Col xs={2}>
                                                        <span className={'queue-wait__open-close-time'}>Closed</span>
                                                        <br />
                                                        <span className={'queue-wait__open-close-time'}>&nbsp;</span>
                                                    </Col>
                                                }
                                            </Row>
                                        </>

                                    })}
                                </Container>
                            </Tab>
                        })}

                    </Tabs>
                </React.Fragment>
            }
            show={isShowingPopup}
            onClose={() => setIsShowingPopup(false)}
            bodyAlignment={TemplateModalAlignment.Left}
            header={<Text content={mainAppState!.texts['covid19.medical.queue.title']} type={TextType.Heading3} />}
            size={TemplateModalSize.Large}
            imageUrl=""
            theme="medical"
            footer={<div></div>}
            name="queueLengthsModal"
        />
    </>
}

const queueLengths = withRouter(inject('mainAppState')(observer(QueueLengths)))
export default queueLengths