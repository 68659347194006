import * as React from "react";
import { FC } from "react";
import { useEffect, useState } from "react";
import { inject, observer, Provider } from "mobx-react";
import { mainAppState, MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import { Button, ButtonType, Text } from "@mit/hui";
import { getEnv } from "../../common/Environment";
import moment from "moment";
import dataController from "../../api/DataController";
import { VaccineStatusModel } from "../../types/VaccineStatusModel";
import Loader from "react-loader";
import { mediumLoaderOptions } from "../../common/Defaults";
import { HoldReason, PassStatus } from "../../types/CovidStatusModel";
import { VaccineStatusResponse } from "types/Api";

interface VaccineProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const VaccineComponent: FC<VaccineProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [vaccineStatus, setVaccineStatus] = useState<VaccineStatusModel>();
	const [vaccineQuestion, setVaccineQuestion] = useState<any>()
	
	const gotoVaccineWaiver = () => {
		window.location.href = `https://covidvaccine${getEnv(true)}.mit.edu`;
	};

	const showVaccineWaiver = (): boolean => {
        if (!mainAppState!.features["vaccine.enabled"]) return false;
		console.log('key::showVaccineWaiver', {vaccine_eligible: vaccineStatus?.eligible, vaccine_status: vaccineStatus?.status, status: mainAppState!.status_model.status, hold_reason: mainAppState!.status_model.hold_reason})

		if(mainAppState!.status_model.status === PassStatus.hold && mainAppState!.status_model.hold_reason === HoldReason.vaccine) return false;

		if (!vaccineQuestion?.show_vaccine_proof_pending_message) return false;

        if(!vaccineStatus?.eligible) return true;

        if(vaccineStatus?.status === "eligible") return true;
		
        // if(vaccineQuestion?.show_vaccine_proof_pending_message) return true;

		return false;
	};

	const showVaccineReminder = (): boolean => {
		if (!vaccineStatus) {
			return false;
		}

		return vaccineStatus.status === "in_progress";
	};

	useEffect(() => {
		dataController.fetchVaccineStatus().then((data: VaccineStatusResponse) => {
			setVaccineStatus(data.status);
			setIsLoading(false);
		}).catch((err) => console.error(err));
		dataController.fetchArray('questions_vaccine')
		.then((response: any) => {
			setVaccineQuestion(response.items)
		}).catch((err) => console.error(err))
		// const vaccineStatusPromise = dataController.fetchVaccineStatus();
		// const questionsVaccinePromise = dataController.fetchArray('questions_vaccine');

		// Promise.all([vaccineStatusPromise, questionsVaccinePromise])
		// 	.then(([VaccineStatusResponse, questionsVaccineResponse]) => {
		// 		setVaccineStatus(VaccineStatusResponse.status);
		// 		setVaccineQuestion(questionsVaccineResponse.items)
		// 		setIsLoading(false);
		// 	}).catch((err) => console.error(err))
	}, []);

	useEffect(() => {
		if (mainAppState!.isVaccineStatus) {
			dataController.fetchArray('questions_vaccine')
				.then((response: any) => {
					setVaccineQuestion(response.items)
					mainAppState!.checkVaccineStatus(false)
				})
		}
	}, [mainAppState!.isVaccineStatus])

	const getDateText = (text: string): string => {
		if (!vaccineStatus || !vaccineStatus.appointments || vaccineStatus.appointments.length === 0) {
			return text;
		}

		if (vaccineStatus.appointments[0].vaccine_schedule) {
			text = text.replace("{date}", moment(vaccineStatus.appointments[0].vaccine_schedule.start_datetime).format("LLL"));
		}

		return text;
	};

	return (
		<>
			<Loader loaded={!isLoading} options={mediumLoaderOptions}>
				{showVaccineWaiver() && (
					<div className="card pure-green-card text-center">
						<div className={`m-4 medical-text-container submit-result-message max-width-content`}>
							<div className="large-icon text-center m-3">
								<i className="fas fa-syringe"></i>
							</div>
							<Text content={vaccineQuestion?.vaccine_proof_by_message} />
							<div className="text-center">
								<Button text={"Upload proof of Vaccination"} type={ButtonType.Secondary} url={`https://covidvaccine${getEnv(true)}.mit.edu`} newWindow></Button>
							</div>
							{/* {!vaccineStatus?.eligible && (
								<>
									
									<p dangerouslySetInnerHTML={{ __html: mainAppState!.texts[`vaccine.pass.prescreening.text`] ?? "" }} />
									<div className="text-center">
										<Button text={mainAppState!.texts[`vaccine.pass.prescreening.action`] ?? "View Vaccine"} type={ButtonType.Secondary} onClick={gotoVaccineWaiver}></Button>
									</div>
								</>
							)} */}
							{/* {vaccineStatus?.appointment_today && (
								<>
									<p dangerouslySetInnerHTML={{ __html: mainAppState!.texts[`vaccine.pass.today.text`] ?? "" }} />
									<div className="text-center">
										<Button text={mainAppState!.texts[`vaccine.pass.today.action`] ?? "View Vaccine"} type={ButtonType.Secondary} onClick={gotoVaccineWaiver}></Button>
									</div>
								</>
							)}
							{vaccineStatus?.status === "standby_invite" && (
								<>
									<p dangerouslySetInnerHTML={{ __html: mainAppState!.texts[`vaccine.pass.invite.text`] ?? "" }} />
									<div className="text-center">
										<Button text={mainAppState!.texts[`vaccine.pass.invite.action`] ?? "View Vaccine"} type={ButtonType.Secondary} onClick={gotoVaccineWaiver}></Button>
									</div>
								</>
							)}
							{vaccineStatus?.status === "missed_appointment" && (
								<>
									<p dangerouslySetInnerHTML={{ __html: mainAppState!.texts[`vaccine.pass.missed.text`] ?? "" }} />
									<div className="text-center">
										<Button text={mainAppState!.texts[`vaccine.pass.missed.action`] ?? "View Vaccine"} type={ButtonType.Secondary} onClick={gotoVaccineWaiver}></Button>
									</div>
								</>
							)} */}
							{/* {vaccineStatus?.status === "eligible" && (
								<>
									{vaccineStatus.has_appointment ? (
										<>
											<p dangerouslySetInnerHTML={{ __html: getDateText(mainAppState!.texts[`vaccine.pass.appointment.text`]) ?? "" }} />
											<div className="text-center">
												<Button
													text={mainAppState!.texts[`vaccine.pass.appointment.action`] ?? "View Vaccine"}
													type={ButtonType.Secondary}
													onClick={gotoVaccineWaiver}></Button>
											</div>
										</>
									) : (
										<>
											<p dangerouslySetInnerHTML={{ __html: mainAppState!.texts[`vaccine.pass.eligible.text`] ?? "" }} />
											<div className="text-center">
												<Button text={mainAppState!.texts[`vaccine.pass.eligible.action`] ?? "View Vaccine"} type={ButtonType.Secondary} onClick={gotoVaccineWaiver}></Button>
											</div>
										</>
									)}
								</>
							)} */}
							{/* {vaccineStatus?.status === "in_progress" && (
								<>
									{vaccineStatus.has_appointment ? (
										<>
											<p dangerouslySetInnerHTML={{ __html: getDateText(mainAppState!.texts[`vaccine.pass.appointment.text`]) ?? "" }} />
											<div className="text-center">
												<Button
													text={mainAppState!.texts[`vaccine.pass.appointment.action`] ?? "View Vaccine"}
													type={ButtonType.Secondary}
													onClick={gotoVaccineWaiver}></Button>
											</div>
										</>
									) : (
										<>
											<p dangerouslySetInnerHTML={{ __html: mainAppState!.texts[`vaccine.pass.inprogress.text`] ?? "" }} />
											<div className="text-center">
												<Button text={mainAppState!.texts[`vaccine.pass.inprogress.action`] ?? "View Vaccine"} type={ButtonType.Secondary} onClick={gotoVaccineWaiver}></Button>
											</div>
										</>
									)}
								</>
							)} */}
						</div>
					</div>
				)}

				{showVaccineReminder() && (
					<div className="card pure-green-card text-center">
						<div className={`m-4 medical-text-container submit-result-message max-width-content`}>
							<i className="fas fa-shield-check"></i>
							<p
								dangerouslySetInnerHTML={{
									__html: getDateText(mainAppState!.texts[`covid19.medical.vaccine.reminder.text`]),
								}}
							/>
						</div>
					</div>
				)}
			</Loader>
		</>
	);
};

const Vaccine = inject("mainAppState", "userProfileStoreState")(observer(VaccineComponent));
export default Vaccine;
