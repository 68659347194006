import * as React from "react";
import { FC, useEffect, useState } from "react";
import { inject, observer, Provider } from "mobx-react";
import { MainAppStateInterface, ActiveDrawer, ActiveModal } from "../../model/MainAppStateStore";
import * as moment from "moment";
import { withRouter } from "react-router";
import LandingNotCleared from "../attestation/landings/LandingNotCleared";
import Barcode from "./Barcode";
import { PassStatus, RequirementIds, RequirementStatus } from "../../types/CovidStatusModel";
import CovidText, { COVID_TEXTS } from "../../common/Texts";
import QueueLengths from "./QueueLengths";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import {
	Button,
	Text,
	Spacer,
	ButtonType,
	Badge,
	Icon
} from "@mit/hui";
import dataController from "../../api/DataController";
import { VaccineStatusModel } from "../../types/VaccineStatusModel";
import { VaccineStatusResponse } from "types/Api";

interface MedicalAssistProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
	history?: any;
}

const MedicalAssistComponent: FC<MedicalAssistProps> = ({ mainAppState, userProfileStoreState, history }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [vaccineStatus, setVaccineStatus] = useState<VaccineStatusModel>();
	const [isSuccess, setIsSuccess] = useState<number>(0);
	const [isShowingPopup, setIsShowingPopup] = useState<boolean>(false);

	useEffect(() => {
		dataController.fetchVaccineStatus().then((data: VaccineStatusResponse) => {
			setVaccineStatus(data.status);
			setIsLoading(false);
		});
	}, []);

	const saveData = (data: any) => {
		setIsLoading(true);

		dataController.postVaccineSymptoms({ ...data }).then(
			(res: any) => {
				setIsSuccess(1);
				setIsLoading(false);
			},
			(error: any) => {
				setIsLoading(false);
				setIsSuccess(-1);
			}
		);
	};

	const renderNextStepButton = () => {
		if (mainAppState!.evalRequirement(RequirementIds.training, RequirementStatus.incomplete)) {
			return (
				<div className="text-center">
					<Button
						type={ButtonType.Secondary | ButtonType.Ghost}
						onClick={() => (mainAppState!.activeDrawer = ActiveDrawer.Training)}
						text={mainAppState!.texts["covid19.pass.medical.continue.training"]}></Button>
				</div>
			);
		} else {
			if (
				mainAppState!.status_model.status === PassStatus.requirements_incomplete &&
				mainAppState!.getRequirement(RequirementIds.medical).medical_awaiting_test_outcome &&
				mainAppState!.getRequirement(RequirementIds.medical).status === RequirementStatus.pending
			) {
				return <div></div>;
			} else if (mainAppState!.status_model.status === PassStatus.hold) {
				return <div></div>;
			} else {
				return (
					<div className="text-center">
						<Button
							type={ButtonType.Secondary | ButtonType.Ghost}
							onClick={() => (mainAppState!.activeDrawer = ActiveDrawer.Attestation)}
							text={mainAppState!.texts["covid19.pass.medical.continue.attestation"]}></Button>
					</div>
				);
			}
		}
	};

	const showQueueLengths = (): boolean => {
		// return mainAppState!.features["covid19.pass.medical_queue"] && mainAppState!.getRequirement(RequirementIds.medical).required;
		return mainAppState!.features["covid19.pass.medical_queue"]
	};

	const renderFluShotHeader = () => {
		let medicalRequirement = mainAppState!.getRequirement(RequirementIds.medical);

		if (medicalRequirement.flu_shot_visible || (medicalRequirement.flu_shot_required && !medicalRequirement.flu_shot_completed)) {
			return (
				<>
					<div className={"p-4"}>
						<h4>{mainAppState!.texts["covid19.medical.flushot.header"]}</h4>
						<h4>{mainAppState!.texts["covid19.medical.flushot.title"]}</h4>

						<CovidText textId={COVID_TEXTS["covid19.medical.flushot.text"]} />
						<a
							href={mainAppState!.texts["covid19.medical.flushot.url"]}
							target={"_blank"}
							dangerouslySetInnerHTML={{ __html: mainAppState!.texts["covid19.medical.flushot.action1"] + " " + mainAppState!.texts["covid19.medical.flushot.action2"] }}> <span className="sr-only">link opens in new tab</span></a>
					</div>
					<Spacer />
					<hr />
				</>
			);
		}
	};

	const renderTesting = (status: string) => {
		let attestationRequirement = mainAppState!.getRequirement(RequirementIds.attestation)

		switch (status) {
			case "incomplete":
				return (
					<div>
						{/* <div className="p-4">
							<h4>Covid testing requirement</h4>
							<CovidText textId={COVID_TEXTS["covid19.pass.medical.incomplete"]} />
						</div>
						<hr /> */}
						<Text content={mainAppState!.texts['covid19.medical.assited.button']} type={'h3'} />
						<Spacer size={'1'} />
						{showQueueLengths() && <QueueLengths />}
						<Text align="center" content={mainAppState!.texts['covid19.medical.alt.times']} type={'body'} />

						<Barcode />
					</div>
				);
			case "pending":
				return (
					<div>
						{mainAppState!.isFlushotRequired() ? (
							<>
								<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.title"]]} type={'h4'} />
								<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.text"]]} />
							</>
						) : (
							<>
								{/* <div className="p-4">
									<h4>Covid testing requirement</h4>
									{mainAppState!.getRequirement(RequirementIds.medical).medical_awaiting_test_outcome && (
										<div>
											<h4>{mainAppState!.texts["covid19.medical.hold.awaiting_test_outcome.title"] ?? "Test Required"}</h4>

											<div>
												<p>
													{mainAppState!.texts["covid19.medical.hold.awaiting_test_outcome.text"].replace(
														"[date]",
														displayDateAbbrMonth(toDate(toLocalTimeFromUct(mainAppState!.getRequirement(RequirementIds.medical).next_completion!)))
													)}
												</p>
											</div>
										</div>
									)}
									{!mainAppState!.getRequirement(RequirementIds.medical).medical_awaiting_test_outcome && (
										<div>
											<CovidText textId={COVID_TEXTS["covid19.pass.test.testComplete"]} />
										</div>
									)}
								</div>
								<div className="text-center">{renderNextStepButton()}</div>
								<hr /> */}
							</>
						)}
						<Text content={mainAppState!.texts['covid19.medical.assited.button']} type={'h3'} />
						<Spacer size={'1'} />
						{showQueueLengths() && <QueueLengths />}
						<Text align="center" content={mainAppState!.texts['covid19.medical.alt.times']} type={'body'} />
						<div className={"smallMarginTop"}>
							<Barcode />
						</div>
						{(attestationRequirement.status != 'complete' && attestationRequirement.visible == true) && (
							<div className="text-center">{renderNextStepButton()}</div>
						)}
					</div>
				);
			case "retest_due_soon":
				return (
					<div>
						<div>
							{mainAppState!.isFlushotRequired() ? (
								<>
									<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.title"]]} type={'h4'} />
									<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.text"]]} />
								</>
							) : (
								<>
									{/* <div className="p-4">
										<h4>Covid testing requirement</h4>
										<h1>Your next test is due soon.</h1>
										<p>Regular medical testing is needed as part of the safety efforts at MIT.</p>
										<p>Please visit medical before:</p>
										<p>{moment(mainAppState!.nextTestDate).format("MM/DD/YYYY")}</p>
										<p>Please continue to the Daily COVID-19 Attestation form.</p>
									</div>
									{!isLoading && <div className={"smallMarginTop"}>{renderNextStepButton()}</div>}
									<hr /> */}
								</>
							)}
							<Text content={mainAppState!.texts['covid19.medical.assited.button']} type={'h3'} />
							<Spacer size={'1'} />
							{showQueueLengths() && <QueueLengths />}
							<Text align="center" content={mainAppState!.texts['covid19.medical.alt.times']} type={'body'} />
							<Barcode />

							{(attestationRequirement.status != 'complete' && attestationRequirement.visible == true) && (
								!isLoading && <div className={"smallMarginTop"}>{renderNextStepButton()}</div>
							)}

						</div>
					</div>
				);
			case "overdue_grace":
			case "due_soon":
				return (
					<div>
						{mainAppState!.isFlushotRequired() ? (
							<>
								<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.title"]]} type={'h4'} />
								<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.text"]]} />
							</>
						) : (
							<>
								{/* <div>
									<h1>
										{mainAppState!.texts[`covid19.pass.medical.${status}.heading`].replace(
											"[date]",
											displayDateAbbrMonth(toDate(toLocalTimeFromUct(mainAppState!.getRequirement(RequirementIds.medical).next_completion!)))
										)}
									</h1>
								</div>

								<CovidText textId={`covid19.pass.medical.${status}.detail`} />
								{!isLoading && <div className={"smallMarginTop"}>{renderNextStepButton()}</div>} */}
							</>
						)}
						<Text content={mainAppState!.texts['covid19.medical.assited.button']} type={'h3'} />
						<Spacer size={'1'} />
						{showQueueLengths() && <QueueLengths />}
						<Text align="center" content={mainAppState!.texts['covid19.medical.alt.times']} type={'body'} />

						<Barcode />
						{(attestationRequirement.status != 'complete' && attestationRequirement.visible == true) && (
							!isLoading && <div className={"smallMarginTop"}>{renderNextStepButton()}</div>
						)}
					</div>
				);
			case "complete":
				return (
					<div>
						<div className="p-4">
							{mainAppState!.isFlushotRequired() ? (
								<>
									<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.title"]]} type={'h4'} />
									<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.medical.flushot.incomplete.text"]]} />
								</>
							) : (
								<>
									{/* <h4>Covid testing requirement</h4>
									<CovidText textId={COVID_TEXTS["covid19.pass.medical.complete"]} />
									{mainAppState!.getRequirement(RequirementIds.medical).next_completion !== null && (
										<div>
											<h1>
												{mainAppState!.texts[`covid19.pass.medical.due_soon.heading`].replace(
													"[date]",
													displayDateAbbrMonth(toDate(toLocalTimeFromUct(mainAppState!.getRequirement(RequirementIds.medical).next_completion!)))
												)}
											</h1>
										</div>
									)}
									{!isLoading && (
										<div className={"smallMarginTop text-center pt-3"}>
											<Button
												type={ButtonType.Secondary | ButtonType.Ghost}
												onClick={() => (mainAppState!.activeDrawer = ActiveDrawer.Attestation)}
												text={mainAppState!.texts["covid19.pass.medical.continue.attestation"]}
											/>
										</div>
									)} */}
								</>
							)}
						</div>

						<Text content={mainAppState!.texts['covid19.medical.assited.button']} type={'h3'} />
						<Spacer size={'1'} />	
						{showQueueLengths() && <QueueLengths />}
						<Text align="center" content={mainAppState!.texts['covid19.medical.alt.times']} type={'body'} />
						<Barcode />

						{(attestationRequirement.status != 'complete' && attestationRequirement.visible == true) && (
							!isLoading && (
								<div className={"smallMarginTop text-center pt-3"}>
									<Button
										type={ButtonType.Secondary | ButtonType.Ghost}
										onClick={() => (mainAppState!.activeDrawer = ActiveDrawer.Attestation)}
										text={mainAppState!.texts["covid19.pass.medical.continue.attestation"]}
									/>
								</div>
							)
						)}
					</div>
				);
		}
	};

	const hasMedicalIssue = () => {
		return (
			mainAppState!.status_model.status === PassStatus.medical_symptoms ||
			mainAppState!.status_model.status === PassStatus.medical_positive ||
			mainAppState!.status_model.status === PassStatus.medical_block ||
			mainAppState!.status_model.status === PassStatus.medical_overdue
		);
	};

	return (
		<>
			
			<div className={`submitter-view`}>
				<Provider userProfileStore={userProfileStoreState}>
					<div className={"nav-container"}>
						<div className={`main-content light-green`}>
							<div>
								<div className={"test-landing"}>
									<div className={"content-page smallPaddingTop white-content max-width-content text-align-center"}>
										{/* {mainAppState && mainAppState.isRequirementMandatory(RequirementIds.medical) ? (
											<>
												{hasMedicalIssue() ? (
													<>
														<div className="p-4">
															<LandingNotCleared />
															{showQueueLengths() && <QueueLengths />}
														</div>
													</>
												) : (
													<>												
														{renderTesting(mainAppState!.getRequirement(RequirementIds.medical).status)}
													</>
												)}
											</>
										) : (
											<>
												<CovidText textId={COVID_TEXTS["covid19.pass.medical.not_required"]} />
												<div className={"smallMarginTop"}>{renderNextStepButton()}</div>
											</>
										)} */}
										{hasMedicalIssue() ? (
											<>
												<div className="p-4">
													<LandingNotCleared />
													{/* {showQueueLengths() && <QueueLengths />} */}
												</div>
											</>
										) : (
											<>												
												{renderTesting(mainAppState!.getRequirement(RequirementIds.medical).status)}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Provider>
			</div>
		</>
	);
};

const MedicalAssist = withRouter(inject("mainAppState", "userProfileStoreState")(observer(MedicalAssistComponent)));
export default MedicalAssist;
