import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import {handleFieldChange} from "./FormUtil";

/*
To see why this is implemented like this, look at https://github.com/Hacker0x01/react-datepicker/issues/862
 */

class Input extends React.Component {
    render() {
        return <input {...this.props} className={'form-control'}/>;
    }
}

const DatePicker: React.FunctionComponent<any> = ({name, handleChange, defaultValue}) => {
    const FORMAT = 'MM/DD/YYYY'

    const handleDayChange = (day: Date) => {
        handleFieldChange(name, day, handleChange)
    }

    return <DayPickerInput onDayChange={handleDayChange} component={Input}
                           formatDate={MomentLocaleUtils.formatDate}
                           parseDate={MomentLocaleUtils.parseDate}
                           format={FORMAT}
                           placeholder={`${MomentLocaleUtils.formatDate(defaultValue ? defaultValue : new Date(), FORMAT, 'en')}`}/>
}

export default DatePicker