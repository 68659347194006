import * as React from 'react'
import {FC} from "react";

interface ErrorMessageProps {
    text: string;
    className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({text, className}) => {
    return <div className={'error-message-page'}>
        <img src={'glyphicons-basic-79-triangle-empty-alert.svg'} className={'error-image'} alt={'Error Image'}/>
        <p className={className}>{text}</p>

    </div>
}

export default ErrorMessage
