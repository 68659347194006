import {action, decorate, observable} from "mobx";
import {FetchPassStatusResponse, FetchStringResponse, InfoOverview, UnobservedStatusResponse, VaccineStatusResponse} from "types/Api";
import moment from 'moment';
import {CovidPassRequirement, PassStatus, PassStatusModel, RequirementIds, RequirementStatus} from "../types/CovidStatusModel";
import {COVID_TEXTS} from "../common/Texts";
import { VaccineStatusModel } from "../types/VaccineStatusModel";

export interface WaiverInterface {
    traveled?: boolean;
    state?: string;
    returnDate?: moment.Moment;
}

type dropOfforPickUpType = 'pickUp' | 'dropOff' | ''

export interface WebFeatures {
    'coivd19.web.waiver.analytics': boolean;
    'covid19.waiver.student': boolean;
    'covid19.pass.medical_plan': boolean;
    'covid19.pass.medical_queue': boolean;
    'vaccine.enabled': boolean;
    'covid19.attestation.v2.enabled': boolean;
    'covid19.pass.unobserved_test': boolean;
    'covid19.pass.unobserved_test.show_info': boolean;
    'covid19.pass.medical_assist': boolean;
    'covid19.medical.flu_shot.title.visible': boolean;
    'covid19.app.medical.always.show.barcode': boolean;
}

export interface CovidTextType {
    [id:string]: COVID_TEXTS
}

export enum ActiveDrawer {
    None,
    Medical,
    MedicalAssist,
    UnobservedTesting,
    TestingHistory,
    Acknowledgement,
    Training,
    Attestation,
    ReleaseNotes,
    VaccineAcknowledgement,
    VaccineSurvey,
    ReportPositiveCovidResult,
    ReportCovidSymptoms,
    ReportCloseContact,
    ReportOptions
    // InfoOverview
}

// export type ActiveDrawerT = 'none' | 'acknowledge' | 'training' | 'medical' | 'attestation'

export enum ActiveModal {
    None,
    InfoOverview
}

export interface MainAppStateInterface {
    reload: boolean;
    status_model: PassStatusModel;
    vaccine_status_model: VaccineStatusModel
    getRequirement: (id: RequirementIds) => CovidPassRequirement;
    isFlushotRequired: () => boolean;
    isFlushotVisible: () => boolean;
    isFlushotCompleted: () => boolean;
    getStepperItems: () => any[];
    evalRequirement: (id: RequirementIds, value: RequirementStatus) => boolean;
    hasMedicalIssue: () => boolean;
    activeDrawer: ActiveDrawer;
    activeModal: ActiveModal;
    personMissedMedical: boolean;
    isSubmittingOnBehalf: boolean;
    attestationRequired: boolean;
    hasBeenTested: boolean;
    lastSubmission: string;
    nextTestDate: string;
    submittingOnBehalfKerbId: string;
    submittingOnBehalfName: string;
    texts: CovidTextType;
    status: string;
    features: WebFeatures;
    onCampusStartTime?: moment.Moment;
    onCampusEndTime?: moment.Moment;
    cancelOnBehalfTransaction: () => void,
    updatePersonStatus: (statusResponse: FetchPassStatusResponse) => void,
    updatePersonVaccineStatus: (statusResponse: VaccineStatusResponse) => void,
    isWaiverFieldsValid: () => boolean,
    isIncomplete: () => boolean,
    personCanAccessApplication: () => boolean,
    personCanAccessCampus: () => boolean,
    storeCurrentPersonStatus: () => void,
    restoreCurrentPersonStatus: () => void,
    allPrereqsMet: (id: RequirementIds) => [boolean, RequirementIds | undefined],
    isRequirementMandatory: (id: RequirementIds) => boolean
    waiver?: WaiverInterface;
    impersonateList?: any[];
    waiverText?: string;
    waiverStudentText?: string;
    stepperSelectedIndex: number;
    stepperItemCount: number;
    isVaccineStatus: boolean;
    checkVaccineStatus: (b: boolean) => boolean,
    unobservedStatus: UnobservedStatusResponse,
    unobservedTestDone: boolean;
    updateUnobservedStatus: (statusResponse: UnobservedStatusResponse) => void
    informationPageClose: boolean
    informationPage: number
    informationOverview: InfoOverview[]
    informationPages: any[number]
    vaccineStatus: VaccineStatusModel | undefined
}

export class MainAppStateStore {
    reload: boolean;
    status_model?: PassStatusModel;
    attestationRequired: boolean;
    personMissedMedical: boolean;
    lastSubmission?: string;
    hasBeenTested: boolean;
    status: string;
    isSubmittingOnBehalf: boolean;
    submittingOnBehalfKerbId: string;
    submittingOnBehalfName: string;
    texts: any;
    nextTestDate?: string;
    onCampusStartTime?: moment.Moment;
    onCampusEndTime?: moment.Moment;
    waiver?: WaiverInterface;
    currentPersonStatus?: MainAppStateStore;
    features?: any;
    impersonateList?: any[];
    waiverText?: string;
    waiverStudentText?: string;
    stepperSelectedIndex: number;
    stepperItemCount: number;
    activeDrawer: ActiveDrawer;
    activeModal: ActiveModal;
    isVaccineStatus: boolean;
    vaccineQuestionStatus: any;
    unobservedStatus: UnobservedStatusResponse;
    unobservedTestDone: boolean;
    informationPageClose: boolean;
    informationPage: number;
    informationOverview: InfoOverview[];
    informationPages: any[number];
    vaccineStatus: VaccineStatusModel | undefined;

    constructor() {
        this.reload = false;
        this.status_model = undefined
        this.status = ''
        this.hasBeenTested = false
        this.personMissedMedical = false
        this.attestationRequired = false
        this.isSubmittingOnBehalf = false
        this.features = {}
        this.submittingOnBehalfKerbId = ''
        this.submittingOnBehalfName = ''
        this.lastSubmission = ''
        this.onCampusStartTime = moment().set({'hour': 8, 'minute': 0})
        this.onCampusEndTime = moment().set({'hour': 17, 'minute': 0})
        this.waiver = {}
        this.texts = {
            'covid19.pass.campusDate': '2020-06-15 08:00 am'
        }
        this.waiverText = '';
        this.waiverStudentText = '';
        this.stepperItemCount = 0;
        this.stepperSelectedIndex = 0;
        this.activeDrawer = ActiveDrawer.None;
        this.activeModal = ActiveModal.None
        this.isVaccineStatus = false;
        this.informationPageClose = false;
        this.informationPage = 0;
        this.informationPages = 0;
        this.informationOverview = [];
        this.unobservedTestDone = false;
        this.unobservedStatus = {} as any
        this.vaccineStatus = {} as any
    }

    isIncomplete(): boolean {
        return this.status_model?.status === PassStatus.requirements_incomplete
    }

    evalRequirement(id: RequirementIds, value: RequirementStatus): boolean {

        if (this.getRequirement) {
            let result = this.getRequirement(id);

            if (result) {
                return result!.status === value;
            }
        }

        return false;
    }

    personCanAccessApplication() {
        return this.status_model && this.status_model.status !== PassStatus.no_access
    }

    personCanAccessCampus() {
        return this.status_model && this.status_model.status === PassStatus.access_granted
    }

    getRequirement(id: RequirementIds): CovidPassRequirement | undefined {
        return this.status_model && this.status_model.requirements.find((r: CovidPassRequirement) => r.id === id)
    }

    isFlushotVisible(): boolean {
      const medicalRequirement = this.getRequirement(RequirementIds.medical);
      if (medicalRequirement?.flu_shot_visible) {
        return true
      }
      return false
    }

    isFlushotCompleted(): boolean {
      const medicalRequirement = this.getRequirement(RequirementIds.medical);
      if (medicalRequirement?.flu_shot_completed) {
        return true
      }
      return false
    }

    isFlushotRequired(): boolean {

        const medicalRequirement = this.getRequirement(RequirementIds.medical);

        // (status.getMedical().isFluShotVisible() ||
        // (status.getMedical().isFluShotRequired() &&
        // !status.getMedical().isFluShotCompleted()))

        if (medicalRequirement
            && medicalRequirement.status != RequirementStatus.incomplete
            && medicalRequirement.flu_shot_required
            && !medicalRequirement.flu_shot_completed) {

            return true;
        }

        return false;
    }

    getStepperItems(): any[] {

        const selectedItems: any[] = [];

        if (this.stepperItemCount == 0)
            return selectedItems;

        for (var a = 0; a < this.stepperItemCount; a++) {
            selectedItems.push({ selected: false });
        }

        selectedItems[this.stepperSelectedIndex].selected = true;

        return selectedItems;
    }

    cancelOnBehalfTransaction() {
        this!.isSubmittingOnBehalf = false
        this!.submittingOnBehalfKerbId = ''
        this!.submittingOnBehalfName = ''

        if (this!.currentPersonStatus) {
            this!.restoreCurrentPersonStatus()
        }
    }

    storeCurrentPersonStatus() {
        this!.currentPersonStatus = Object.assign({}, mainAppState)
    }

    restoreCurrentPersonStatus() {
        mainAppState!.status_model = this!.currentPersonStatus!.status_model
    }

    updatePersonStatus(statusResponse: FetchPassStatusResponse) {
        mainAppState!.status_model = statusResponse.status_model
    }

    updateWaiverText(waiverTextResponse: FetchStringResponse) {
        mainAppState!.waiverText = waiverTextResponse.value!;
    }

    updateWaiverStudentText(waiverTextResponse: FetchStringResponse) {
        mainAppState!.waiverStudentText = waiverTextResponse.value!;
    }

    hasMedicalIssue(): boolean {
        return this!.status_model!.status === PassStatus.medical_symptoms ||
            this!.status_model!.status === PassStatus.medical_positive ||
            this!.status_model!.status === PassStatus.medical_block ||
            this!.status_model!.status === PassStatus.medical_overdue
    }

    /*
        First check higher level statuses, then check requirement statuses
     */
    allPrereqsMet(id: RequirementIds): [boolean, undefined | RequirementIds] {
        let allPrereqComp = true
        let firstFailedOn: undefined | RequirementIds = undefined
        if (this) {
            //if higher level statuses are fine, continue
            if (this.status_model!.status === PassStatus.waiver_declined) {
                allPrereqComp = false
                firstFailedOn = RequirementIds.acknowledge
            } else if (this.hasMedicalIssue()) {
                allPrereqComp = false
                firstFailedOn = RequirementIds.medical
            }

            //no need to continue if already failed
            if (allPrereqComp) {
                this.getRequirement(id)!.prerequisites!.forEach((req: any) => {
                    if (this.getRequirement(req)!.status !== RequirementStatus.complete) {
                        //if medical result is pending, requirement is met for attestation
                        if (!(id === RequirementIds.attestation && req === RequirementIds.medical &&
                            (this.getRequirement(req)!.status === RequirementStatus.pending ||
                                this.getRequirement(req)!.status === RequirementStatus.overdue_grace ||
                                this.getRequirement(req)!.status === RequirementStatus.due_soon))) {
                            allPrereqComp = false
                            if (!firstFailedOn)
                                firstFailedOn = req
                        }
                    }
                })
            }
        }

        if (allPrereqComp) {
            return [allPrereqComp, undefined]
        } else {
            return [false, firstFailedOn]
        }
    }

    isRequirementMandatory(id: RequirementIds): boolean {
        if (this && this.getRequirement) {
            let result = this.getRequirement(id);

            if (result) {
                return result!.required;
            }
        }

        return false;
    }

    checkVaccineStatus(state: boolean): void {
        this.isVaccineStatus = state
    }

    updateUnobservedStatus(statusResponse: UnobservedStatusResponse) {
        mainAppState!.unobservedStatus = statusResponse
    }

    updateVaccineStatus(statusResponse: VaccineStatusModel | undefined) {
        mainAppState!.vaccineStatus = statusResponse
    }
}

decorate(MainAppStateStore, {
    updatePersonStatus: action,
    restoreCurrentPersonStatus: action,
    storeCurrentPersonStatus: action,
    cancelOnBehalfTransaction: action,
    checkVaccineStatus: action,
    updateUnobservedStatus: action,
    attestationRequired: observable,
    personMissedMedical: observable,
    lastSubmission: observable,
    hasBeenTested: observable,
    status: observable,
    isSubmittingOnBehalf: observable,
    submittingOnBehalfKerbId: observable,
    submittingOnBehalfName: observable,
    texts: observable,
    nextTestDate: observable,
    onCampusStartTime: observable,
    onCampusEndTime: observable,
    waiver: observable,
    currentPersonStatus: observable,
    impersonateList: observable,
    status_model: observable,
    waiverText: observable,
    waiverStudentText: observable,
    stepperSelectedIndex: observable,
    stepperItemCount: observable,
    activeDrawer: observable,
    activeModal: observable,
    isVaccineStatus: observable,
    unobservedStatus: observable,
    unobservedTestDone: observable,
    informationPage: observable,
    informationPages: observable,
    reload: observable
})

export const mainAppState = new MainAppStateStore()
