import * as React from 'react'
import { FC, useEffect } from 'react'
import { useState } from "react";
import { Alert, Row } from "react-bootstrap";
import 'react-day-picker/lib/style.css';
import dataController from "../../api/DataController";
import { mainAppState, MainAppStateInterface, ActiveDrawer } from "../../model/MainAppStateStore";
import { inject, observer, Provider } from "mobx-react";
import { PageError } from "../../types/PageErrors";
import ErrorMessage from "../../common/ErrorMessage";
import { withRouter } from "react-router";
import Loader from 'react-loader'
import { mediumLoaderOptions } from "../../common/Defaults";
import { COVID_TEXTS } from "../../common/Texts";
import vaccineWaiverApi, { VaccineWaiverInterface, VaccineWaiverResponse } from "./VaccineWaiverApi";
import { PagingContainer, Button, ButtonState, Text, ButtonType, TemplateModal, TemplateModalSize, TemplateModalAlignment, TextType } from '@mit/hui';
import $ from 'jquery'
import { PassStatus, RequirementIds, RequirementStatus } from '../../types/CovidStatusModel';
import { withComponent } from '../../common/WithComponent';
import { PostWaiverResponse, FetchPassStatusResponse } from 'types/Api';

export interface VaccineWaiverProps {
    mainAppState?: MainAppStateInterface;
    history?: any;
}


const VaccineWaiverComponent: FC<VaccineWaiverProps> = ({ mainAppState }) => {
    const [isShowingConfirmationPopup, setIsShowingConfirmationPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })

    const [validationError, setValidationError] = useState(false)

    const [waivers, setWaivers] = useState<VaccineWaiverInterface[] | undefined>(undefined)
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [agreements, setAgreements] = useState<any>({})

    useEffect(() => {
        
        vaccineWaiverApi.fetchWaiverTextAll().then((resp: VaccineWaiverResponse) => {
            setIsLoading(false)

            if (!resp.error) {
                setWaivers(resp.waivers)
                setAgreements(resp.waivers!.reduce((total, curr, i) => ({ ...total, [i]: undefined }), {}))

                mainAppState!.stepperSelectedIndex = 0;
                mainAppState!.stepperItemCount = resp.waivers!.length;

            } else {
                setPageError({
                    error: true,
                    errorText: `An error occurred loading the forms. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS['covid19.pass.help_email']]}.`,
                    showRetry: false
                })
            }
        })
    }, [])

    const clickAgreeButton = () => {

        agreements[currentStep] = true;

        setAgreements(agreements)

        submit(true);
    }

    const clickVaccineDeclineButton = () => {

        agreements[currentStep] = false;

        setAgreements(agreements)

        submit(false);
    }


    const submit = (agree: boolean) => {

        if (currentStep === waivers!.length - 1 || !agree) { //last step or declined
            let agree = agreements[waivers!.filter((w: VaccineWaiverInterface) => w.type === 'waiver').length - 1]
            submitVaccineAgreement(!!agree)
        } else {
            $('.drawer').scrollTop(0);
            setCurrentStep(currentStep + 1);
        }

        if ((mainAppState!.stepperItemCount - 1) !== mainAppState!.stepperSelectedIndex) {
            mainAppState!.stepperSelectedIndex = currentStep + 1;
        }
    }

    const submitVaccineAgreement = (agreed: boolean) => {
        hideModal();
        setIsLoading(true)

        dataController.postVaccineWaiver(agreed).then((result: PostWaiverResponse) => {

            if (result.success) {
                dataController
                .fetchPassStatus(undefined, mainAppState!.submittingOnBehalfKerbId)
                .then((statusResponse: FetchPassStatusResponse) => {
                    if (!statusResponse.error) {
                        mainAppState!.updatePersonStatus(statusResponse);
                        setIsLoading(false);
                        mainAppState!.activeDrawer = ActiveDrawer.None;
                    }
                });
                
            } else {
                setIsLoading(false);

                setPageError({
                    error: true,
                    errorText: `An error occurred submitting your consent. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS['covid19.pass.help_email']]}.`,
                    showRetry: false
                })
            }
        })
    }

    const hideModal = () => {
        setIsShowingConfirmationPopup(false);
    }

    const showModal = () => {
        //@ts-ignore
        setIsShowingConfirmationPopup(true);
    }

    const getModalText = () => {
        return "By declining the vaccination acknowledgement you will forfeit your eligibility to receive a vaccine shot. There will be no guarantee of future eligibility. Are you sure?"
    }

    //Because our (HUI) Modal gets appended to the documentElement root, we need to rerender and update the class component in order to let our Modal re-append to the documentElement root with the latest info 
    const ModalPopup = withComponent(TemplateModal);

    return (
        <>
            <Loader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: 'black' }}>
                <div className={'splash-container p-4'}>
                    {waivers && <Row>

                        <img src={'mit-logo-black.png'} className={'splash-icon'} />
                        <div className={'splash-text'}>
                            <h1>{waivers[currentStep].ack_title}</h1>
                            <div className={'waiver-text'}>
                                {waivers && <div dangerouslySetInnerHTML={{ __html: waivers[currentStep].text }} />}
                            </div>
                        </div>

                        {validationError &&
                            <Alert variant={'danger'} className={'smallMarginTop'}>Please review your answers and try
                                again.</Alert>}
                <div className="text-right w-100">

                            <Button theme="medical-dark" text={'Decline'} type={ButtonType.Primary | ButtonType.Ghost} icon="times"
                                                        onClick={showModal} />
                            &nbsp;&nbsp;

                            <Button theme="medical-dark" text={'I agree to the above'} type={ButtonType.Primary} icon="check"
                                onClick={clickAgreeButton} />
                        </div>
                        {pageError.error && <ErrorMessage text={pageError.errorText} className={'color-black'} />}


                    </Row>}
                </div>
            </Loader>
            <ModalPopup
            body={
                <Loader loaded={true} options={{ ...mediumLoaderOptions, color: 'black' }}>
                    <Provider mainAppState={mainAppState} >
                        {getModalText()}
                    </Provider>
                </Loader>
            }
            bodyAlignment={TemplateModalAlignment.Left}
            show={isShowingConfirmationPopup}
            header={<Text content={"Confirmation"} type={TextType.Heading3} />}
            size={TemplateModalSize.Small}
            theme="medical-dark"
            imageUrl=""
            footer={
                <div>
                    <Button type={ButtonType.Secondary | ButtonType.Ghost} onClick={hideModal} text={"Go back"} />&nbsp;
                    <Button type={ButtonType.Secondary} onClick={clickVaccineDeclineButton} text={"Yes, I'm sure"} />
                </div>
            }
            name="confirmationDialog"
        />
        </>
    )
}

const VaccineWaiver = withRouter(inject('mainAppState')(observer(VaccineWaiverComponent)))
export default VaccineWaiver
