import * as React from 'react'
import { FC, useState } from 'react'
import userController from "../api/UserController";
import { useEffect } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Button, Spacer, ButtonType, TemplateModal, TemplateModalSize, TemplateModalAlignment, Text, TextType, SkeletonLoader } from '@mit/hui';
import { MainAppStateInterface } from '../model/MainAppStateStore';

interface PinProps {
    show: boolean;
    mainAppState?: MainAppStateInterface;
    onClose: any;
}

const Pin: FC<PinProps> = ({ show, mainAppState, onClose }) => {

    const [pin, setPin] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setIsLoading(true);

            userController.fetchPin().then((response: any) => {
                setPin(response.pin);

                setIsLoading(false);
            });
        }
    }, []);

    const resetPin = () => {
        setIsLoading(true);

        userController.createPin().then((response: any) => {
            setPin(response.pin);

            setIsLoading(false);
        });
    }

    const getButtonText = () => {
        if (!pin) {
            return mainAppState!.texts['pin.action.create']
        }
        else {
            return mainAppState!.texts['pin.action.reset']
        }
    }

    return <>
        <TemplateModal
            body={
                <>
                    {isLoading
                        ?
                        <SkeletonLoader type={"default"} />
                        :
                        <>
                            <div className="text-center"><Text content={pin} type={TextType.Heading1} />
                            <Spacer />
                            <Button type={ButtonType.Secondary} onClick={resetPin} text={getButtonText()} />
                            <Spacer />
                            
                            </div>
                        </>
                    }
                </>
            }
            show={show}
            onClose={onClose}
            bodyAlignment={TemplateModalAlignment.Center}
            header={<Text content={mainAppState!.texts['pin.title']} type={TextType.Heading3} />}
            size={TemplateModalSize.Default}
            imageUrl=""
            theme="default"
            footer={<div><Text content={mainAppState!.texts['pin.text']} /></div>}
            name="pinModal"
        />
    </>

}

const pin = withRouter(inject('mainAppState')(observer(Pin as any)))
export default pin
