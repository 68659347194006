import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonState, ButtonType } from "@mit/hui";
import dataController from "../api/DataController";
import { FetchBlobResponse } from "types/Api";

interface ILoadableButtonProps {
	guid: string;
	index: number;
	isFluShot: boolean;
	isVaccine: boolean;
}

const LoadableButton: React.FC<ILoadableButtonProps> = (props) => {
	let [isBusy, setIsBusy] = useState<boolean>(false);

	const downloadClicked = (isFluShot: boolean, isVaccine: boolean, guid: string, index: number) => {
		let abortController = new AbortController();
		setIsBusy(true);

		let fileName = isFluShot ? "latest_flushot_results.pdf" : "latest_results.pdf";

		dataController.fetchMedicalResultPDF(isFluShot, isVaccine, guid, abortController.signal).then((response: FetchBlobResponse) => {
			setIsBusy(false);
			if (!response.error) {
				//@ts-ignore
				saveAs(response.value!, fileName);
			} else console.error(response.error);
		});
	};

	return (
		<Button
			state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
			text="Download"
			onClick={() => downloadClicked(props.isFluShot, props.isVaccine, props.guid, props.index)}
			icon="download"
			theme="medical-dark"
			type={ButtonType.Icon}
			isBusy={isBusy}
		/>
	);
};

export default LoadableButton;
