import BaseController from "../api/BaseController";

export class LocalStorage {

    public static save(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public static delete(key: string) {
        localStorage.removeItem(key);
    }

    public static get(key: string) {
        let data: any = localStorage.getItem(key);

        if (data) {
            try {
                return JSON.parse(data);
            }
            catch (e) {
                return data;
            }
        }
        return null;
    }

    public static setWithExpiry(key: any, value: any, expiry?: number) {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + (expiry ?? 3600000)
        }

        localStorage.setItem(key, JSON.stringify(item))
    }

    public static getWithExpiry(key: any) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        console.log('key::getTime', now.getTime())
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            return null
        }
        return item.value
    }
}
export default LocalStorage

export enum StorageKeys {
    "FAVORITE_LOCATION" = "FAVORITE_LOCATION",
    "VUE_COGNITO" = "VUE_COGNITO",
    "INFO_OVERVIEW" = "INFO_OVERVIEW",
}