import * as React from "react";
import { FC } from "react";
import { inject, observer, Provider } from "mobx-react";
import { ActiveDrawer, mainAppState, MainAppStateInterface } from "../../model/MainAppStateStore";
import OnBehalfHeader from "../onBehalf/OnBehalfHeader";
import moment from "moment";
import Loader from "react-loader";
import { useEffect } from "react";
import dataController from "../../api/DataController";
import { useState } from "react";
import { COVID_TEXTS, default as CovidText } from "../../common/Texts";
import { UserProfileStoreInterface } from "../../model/UserProfileStore";
import { Button, ButtonType, TextType, Text, Spacer, Container, TemplateModal, TemplateModalSize, TemplateModalAlignment, Icon, ListItemProps, ButtonState, ActionList } from "@mit/hui";
import { Dropdown as BDropdown, DropdownButton } from "react-bootstrap";
import { mediumLoaderOptions } from "../../common/Defaults";
import { PassStatus, RequirementIds, RequirementStatus } from "types/CovidStatusModel";
import ReactMarkdown from "react-markdown";
import INeedToDropdown from "./INeedToDropdown";

interface AccessGrantedProps {
	mainAppState?: MainAppStateInterface;
	userProfileStoreState?: UserProfileStoreInterface;
}

const MapButton = () => {
	const openMap = () => {
		window.open(mainAppState!.texts["covid19.pass.pdf.buildings.url"], "_blank");
	};

	return <Button type={ButtonType.TextNaked} text={mainAppState!.texts["covid19.pass.pdf.buildings.text"]} onClick={openMap} />;
};

const CampusAccessButton = () => {
	const openCampusAccess = () => {
		window.open(mainAppState!.texts["covid19.pass.pdf.access.url"], "_blank");
	};

	return <Button type={ButtonType.TextNaked} text={mainAppState!.texts["covid19.pass.pdf.access.text"]} onClick={openCampusAccess} />;
};

const AccessGrantedComponent: FC<AccessGrantedProps> = ({ mainAppState, userProfileStoreState }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [buildingAccessError, setBuildingAccessError] = useState("");
	const [buildings, setBuildings] = useState<string[]>([]);
	const [showBuildingAccessModal, setShowBuildingAccessModal] = useState<boolean>(false);
	const [showReportOptions, setShowReportOptions] = useState<boolean>(false)

	useEffect(() => {
		dataController.fetchBuildingAccess(mainAppState!.submittingOnBehalfKerbId).then(
			(buildingsResp: any) => {
				setBuildings(buildingsResp.items);
				setIsLoading(false);
			},
			(error: any) => {
				setBuildingAccessError(
					`Your Building Access could not be loaded at this time. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS["covid19.pass.help_email"]]}.`
				);
				setIsLoading(false);
			}
		);
	}, []);

	const goLive = moment(mainAppState!.texts["covid19.pass.campusDate"], "YYYY-MM-DD hh:mm:ss a");

	const isAttestationHidden = (): boolean => {
		return mainAppState!.getRequirement(RequirementIds.attestation) && !mainAppState!.getRequirement(RequirementIds.attestation).visible && !mainAppState!.getRequirement(RequirementIds.attestation).required;
	};

	// State ONE
	// mainAppState!.status_model.requirements[2].visible = false
	const isMedicalVisible = (): boolean => {
		return mainAppState!.getRequirement(RequirementIds.medical).visible
	}

	const MedicalTest = (): JSX.Element => {
		return <>
			<Container
				contents={
					<div className="m-1" style={{cursor: "pointer"}} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.Medical}>
						<div className="row align-items-center" style={{width: "100%", margin: "0 auto"}}>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-start">
									<Icon icon="microscope" type="regular" />
								</div>
							</div>
							<div className="col-sm-10">
								<div className="py-2 d-flex flex-column text-lg-left text-center no-margin">
									<Text content={mainAppState!.texts["covid19.pass.testing.title"]} bold type={"h4"} />
									<Text content={mainAppState!.texts["covid19.pass.testing.info"] ?? "Unobserved testing and history"} type={"body"} />
								</div>
							</div>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-end">
									<Icon icon="chevron-right" type="regular" />
								</div>
							</div>
						</div>
					</div>
				}
				raised={false}
				padding={1}
			/>
		</>
	}

	const MedicalHistory = (): JSX.Element => {
		return <>
			<Container
				contents={
					<div className="m-1" style={{cursor: "pointer"}} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.TestingHistory}>
						<div className="row align-items-center" style={{width: "100%", margin: "0 auto"}}>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-start">
									<Icon icon="microscope" type="regular" />
								</div>
							</div>
							<div className="col-sm-10">
								<div className="py-2 d-flex flex-column text-lg-left text-center no-margin">
									<Text content={mainAppState!.texts["covid19.medical.results.button"]} bold type={"h4"} />
									<Text content={mainAppState!.texts["covid19.pass.testing.history-info"] ?? "Unobserved testing history"} type={"body"} />
								</div>
							</div>
							<div className="col-lg-1 col-md-1 col-sm-1">
								<div className="d-flex justify-content-center align-items-center list-icon-end">
									<Icon icon="chevron-right" type="regular" />
								</div>
							</div>
						</div>
					</div>
				}
				raised={false}
				padding={1}
			/>
		</>
	}

	//State ONE
	// mainAppState!.status_model.assignment_type = 9 
	const isLinconLabs = (): boolean => {
		return mainAppState!.status_model.assignment_type === 9 
	}

	return (
		<div className="col-lg-6 col-lg-9 mx-auto">
			<Loader loaded={!isLoading} options={mediumLoaderOptions}>
				<div className="mb-3">
					<Text content="Covid Pass" type={TextType.Heading1} />
				</div>
				{!isAttestationHidden() && <span className="badge badge-success">Cleared for access</span>}
				<OnBehalfHeader />
				<Provider mainAppState={mainAppState} userProfileStoreState={userProfileStoreState}>
					{isAttestationHidden() ? (
						<>
							{/* NEW HIDDEN ATTESTATION ACCESS PAGE */}
							<Container
								contents={
									<div className="m-1">
										<div className="row">
											<div className="col-lg-1 col-md-1 col-sm-12">
												<div className="d-flex justify-content-center align-items-center big-icon success">
													<Icon icon="check-circle" type="solid" />
												</div>
											</div>
											<div className="col-sm-11">
												<div className="py-2 d-flex flex-column text-lg-left text-center">
													<Text content={mainAppState!.texts["covid19.pass.essential"]} bold type={"h4"} />
													<ReactMarkdown className="no-margin" children={mainAppState!.texts["covid19.pass.sub"]}/>
												</div>
											</div>
										</div>
									</div>
								}
								raised={false}
								padding={3}
							/>
							<Spacer size="3" />

							{isMedicalVisible() ? (
								<MedicalTest />
							) : (
								<MedicalHistory />
							)}

							<Spacer size="3" />
							
							{!isLinconLabs() && (
								<div className="report-btn">
									<Button type={ButtonType.Primary} text={mainAppState!.texts["covid19.pass.notify_mit.action"] ?? "I need to report..."} onClick={() => mainAppState!.activeDrawer = ActiveDrawer.ReportOptions} />
								</div>
							)}
						</>
					) : (
						<>
							{/* NON HIDDEN ATTESTATION ACCESS PAGE */}
							<ReactMarkdown className="no-margin" children={mainAppState!.texts["covid19.pass.sub"]}/>
							<Container
								contents={
									<div className="m-4">
										<div className="d-flex flex-column justify-content-center align-items-center">
											<div className="py-2 text-center">
												<h3>{mainAppState!.texts["covid19.pass.essential"]}</h3>
											</div>
											{mainAppState!.getRequirement(RequirementIds.medical)?.status === RequirementStatus.complete &&
												mainAppState!.getRequirement(RequirementIds.medical)?.required &&
												mainAppState!.getRequirement(RequirementIds.medical)?.visible && (
													<p className="text-center">
														{mainAppState!.texts["covid19.medical.completed.test.until"]?.replace("{date}", "")}
														<br />
														<strong>{moment(mainAppState!.getRequirement(RequirementIds.medical)?.next_completion ?? "").format("MMM D, YYYY")}</strong>
													</p>
												)}
										</div>
										<div className="m-2 d-flex justify-content-center align-items-center">
											<div className="w-50">
												<DropdownButton className="d-flex justify-content-center align-items-center link-btn" variant="outline-primary" title="Additional Information">
													<BDropdown.Item
														onClick={() => {
															window.print();
														}}>
														Print
													</BDropdown.Item>
													{buildings.length > 0 && (
														<BDropdown.Item
															onClick={() => {
																setShowBuildingAccessModal(true);
															}}>
															Your building access
														</BDropdown.Item>
													)}
													<BDropdown.Item
														onClick={() => {
															window.open(mainAppState!.texts["covid19.pass.pdf.buildings.url"], "_blank");
														}}>
														{mainAppState!.texts["covid19.pass.pdf.buildings.text"]}
													</BDropdown.Item>
													<BDropdown.Item
														onClick={() => {
															window.open(mainAppState!.texts["covid19.pass.pdf.access.url"], "_blank");
														}}>
														{mainAppState!.texts["covid19.pass.pdf.access.text"]}
													</BDropdown.Item>
												</DropdownButton>
											</div>
										</div>
									</div>
								}
								raised={false}
								padding={4}
							/>
						</>
					)}

					<TemplateModal
						body={
							<>
								{buildingAccessError != "" && <div>{buildingAccessError}</div>}

								{buildingAccessError == "" && (
									<>
										{buildings.length > 0 && (
											<div className={"building-list"}>
												{buildings.sort().map((b: string) => {
													return (
														<div key={b}>
															<i className="far fa-building"></i>&nbsp;&nbsp;{b}
														</div>
													);
												})}
											</div>
										)}
									</>
								)}
							</>
						}
						show={showBuildingAccessModal}
						onClose={() => {
							setShowBuildingAccessModal(false);
						}}
						bodyAlignment={TemplateModalAlignment.Left}
						header={<h5>Building Access</h5>}
						size={TemplateModalSize.Default}
						imageUrl=""
						theme="medical"
						footer={<div></div>}
						name="buildingAccess"
					/>

					{/* LEGACY CODE */}

					{/* {moment().isBefore(goLive) ? (
						<div className={"max-width-content"}>
							<div className={"flex center action-buttons"}>
								<RetakeSurveyButton />
								<MapButton />
							</div>
						</div>
					) : (
						<>
							<Vaccine />
							{buildings.length > 0 ? (
								<>
									<BuildingAccess />
									{mainAppState!.isFlushotVisible() && !mainAppState!.isFlushotCompleted() && (
										<Container
											color="grey"
											alignment="left"
											contents={
												<>
													<div className="p-3">
														<Text padded={true} content={mainAppState!.texts[COVID_TEXTS["covid19.pass.medical.flushot.text"]]} />
													</div>
													<div className="m-3 text-center">
														<Button url={`https://covidvaccine${getEnv(true)}.mit.edu`} type={ButtonType.Primary} text={"Continue to Vaccine"} icon={"vial"} />
													</div>
												</>
											}
										/>
									)}
									<br />
									<div>
										<PrintButton />
										<RetakeSurveyButton />
										<MapButton />
										<CampusAccessButton />
									</div>
									<Spacer size="5" />
								</>
							) : (
								<>
									<CovidText textId={COVID_TEXTS["covid19.pass.no_buildings"]} className={"submit-result-message"} />
								</>
							)}
						</>
					)} */}
				</Provider>
			</Loader>
		</div>
	);
};

const AccessGranted = inject("mainAppState", "userProfileStoreState")(observer(AccessGrantedComponent));
export default AccessGranted;
