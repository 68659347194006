import * as React from "react";
import { FC, Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { AtlasPage, Profile, NavBar, NavBarTypes, NavItemProps, NavItemState, Loader, Error, ErrorType, DropDownState, DropdownItemProps } from "@mit/hui";
import Myself from "./components/Myself";
import userController from "./api/UserController";
import dataController from "./api/DataController";
import PeopleController from "./api/PeopleController";
import { PageError } from "./types/PageErrors";
import { TextItem } from "./types/Questions";
import { mainAppState, ActiveDrawer } from "./model/MainAppStateStore";
import { userProfileStoreState } from "./model/UserProfileStore";
import { COVID_TEXTS } from "./common/Texts";
import { Provider } from "mobx-react";
import { getEnv } from "./common/Environment";
import appConfig from "./app-config";
import { withComponent } from "./common/WithComponent";
import Pin from "./components/Pin";
import appTextData from "./covidPassText.json";
import { AppConfig, AuthProvider, Region } from "@mit/aws-react";
import { RequirementIds } from "types/CovidStatusModel";

interface HomeProps {
	history?: any;
}

const Home: FC<HomeProps> = ({ history }) => {
	const [imageUrl, setImageUrl] = useState("");
	const [displayName, setDisplayName] = useState("");
	const [department, setDepartment] = useState("");
	const [utilNavItems, setUtilNavItems] = useState<NavItemProps[]>([
		{ icon: "bell", text: "What's new!", iconOnly: false, onClick: () => showReleaseNotes() },
		{ icon: "question-circle", text: "Help", iconOnly: false, properties: { href: "mailto:covidapps-help@mit.edu" } },
	]);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [canAccess, setCanAccess] = useState<boolean>(false);
	const [pageLoaded, setPageLoaded] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isReady, setIsReady] = useState<boolean>(false);
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: "", showRetry: true });
	const [showPin, setShowPin] = useState<boolean>(false);

	const appConfigCopy = (appConfig as unknown) as AppConfig;
	const region = new Region(appConfigCopy);

	const profileDropdownItems: DropdownItemProps[] = [
		{
			icon: "id-card",
			text: "My Access PIN",
			state: DropDownState.None,
			onClick: () => {
				setShowPin(true);
			},
			id: "1",
		},
		{
			icon: "sign-out",
			text: "Logout",
			state: DropDownState.None,
			onClick: async () => {
				await new AuthProvider(region.getActiveRegionConfig()).signOut();
			},
		},
	];

	let navItems = [
		{
			icon: "heart",
			iconOnly: false,
			text: "Pass",
			state: window.location.pathname == "/" ? NavItemState.Active : NavItemState.None,
		},
		{
			icon: "plane",
			iconOnly: false,
			text: "Travel",
			state: mainAppState!.features["travel.enabled"] ? NavItemState.None : NavItemState.Invisible,
			onClick: () => (window.location.href = `https://covidtravel${getEnv(true)}.mit.edu`),
		},
		{
			icon: "vial",
			iconOnly: false,
			text: "Vaccine",
			state: NavItemState.None,
			onClick: () => (window.location.href = `https://covidvaccine${getEnv(true)}.mit.edu`),
		},
		{
			icon: "book-reader",
			iconOnly: false,
			text: "Spaces",
			state: mainAppState!.features["spaces.enabled"] ? NavItemState.None : NavItemState.Invisible,
			onClick: () => (window.location.href = `https://spaces${getEnv(true)}.mit.edu`),
		},
	];

	const reset = () => {
		dataController.postReset().then(() => window.location.reload());
	};

	const navigateToDev = () => {
		window.location.href = "/dev-portal/person/index.html";
	};

	const goHome = () => {
		history.push("/");
	};

	const showReleaseNotes = () => {
		mainAppState!.activeDrawer = ActiveDrawer.ReleaseNotes;
	};

	const detectBrowser = () => {
		let nVer = navigator.appVersion;
		let nAgt = navigator.userAgent;
		let browserName = navigator.appName;
		let fullVersion = "" + parseFloat(navigator.appVersion);
		let majorVersion = parseInt(navigator.appVersion, 10);
		let nameOffset, verOffset, ix;

		//Non-Chromium Edge
		if (nAgt.indexOf("Edge") != -1) {
			return false;
		}
		// In Opera, the true version is after "Opera" or after "Version"
		else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
			browserName = "Opera";
			fullVersion = nAgt.substring(verOffset + 6);
			if ((verOffset = nAgt.indexOf("Version")) != -1) fullVersion = nAgt.substring(verOffset + 8);
		}
		// In MSIE, the true version is after "MSIE" in userAgent
		else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
			browserName = "Microsoft Internet Explorer";
			fullVersion = nAgt.substring(verOffset + 5);
		}
		// In Chrome, the true version is after "Chrome"
		else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
			browserName = "Chrome";
			fullVersion = nAgt.substring(verOffset + 7);
		}
		// In Safari, the true version is after "Safari" or after "Version"
		else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
			browserName = "Safari";
			fullVersion = nAgt.substring(verOffset + 7);
			if ((verOffset = nAgt.indexOf("Version")) != -1) fullVersion = nAgt.substring(verOffset + 8);
		}
		// In Firefox, the true version is after "Firefox"
		else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
			browserName = "Firefox";
			fullVersion = nAgt.substring(verOffset + 8);
		}
		// In most other browsers, "name/version" is at the end of userAgent
		else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
			browserName = nAgt.substring(nameOffset, verOffset);
			fullVersion = nAgt.substring(verOffset + 1);
			if (browserName.toLowerCase() == browserName.toUpperCase()) {
				browserName = navigator.appName;
			}
		}
		// trim the fullVersion string at semicolon/space if present
		if ((ix = fullVersion.indexOf(";")) != -1) fullVersion = fullVersion.substring(0, ix);
		if ((ix = fullVersion.indexOf(" ")) != -1) fullVersion = fullVersion.substring(0, ix);

		majorVersion = parseInt("" + fullVersion, 10);
		if (isNaN(majorVersion)) {
			fullVersion = "" + parseFloat(navigator.appVersion);
			majorVersion = parseInt(navigator.appVersion, 10);
		}

		if (browserName.toLowerCase() === "safari" && majorVersion < 13) {
			return false;
		} else {
			return true;
		}
	};

	const appText = () => {
		dataController
			.fetchArray("texts")
			.then((textResponse) => {
				if (textResponse.items) {
					let texts = textResponse.items.reduce((all: any, current: TextItem) => {
						return {
							...all,
							[current.id]: current.text,
						};
					}, {});
					// downloadTextAPI(texts, 'covidPassText.json', 'text/plain')
					mainAppState!.texts = texts;
				} else {
					setPageError({ error: true, errorText: "An error occurred loading texts.", showRetry: true });
				}
			})
			.catch((error) => {
				console.error(error);
				mainAppState!.texts = appTextData;
			});
	};

	/**Download Texts from text API
	 * Uncomment downloadTextAPI() when you need to update the offline textAPI
	 * ./covidPassText.json contains a copy of the textAPI used for offline
	 */
	// const downloadTextAPI = (data: any, fileName: string, contentType: string) => {
	//     const text = document.createElement("a");
	//     const jsonString = JSON.stringify(data)
	//     const file = new Blob([jsonString], {type: contentType});
	//     text.href = URL.createObjectURL(file);
	//     text.download = fileName;
	//     text.click();
	// }

	React.useEffect(() => {
		if (appConfig.stage != "master") {
			utilNavItems.unshift({ icon: "power-off", text: "Reset Account", iconOnly: false, onClick: () => reset() });
			utilNavItems.unshift({ icon: "file-code", text: "Developer Tools", iconOnly: false, onClick: () => navigateToDev() });

			setUtilNavItems(utilNavItems);
		}

		userController.fetchPicture().then((url: any) => {
			setImageUrl(url.value);
		});

		userController.fetchProfile().then((profileResponse) => {
			setDisplayName(profileResponse.display_name);
			setDepartment(profileResponse.department);
		});

		let supported = detectBrowser();

		if (supported) {
			const featuresPromise = userController.fetchFeatures();
			const profilePromise = userController.fetchProfile();
			const statusPromise = dataController.fetchPassStatus();
			const peopleController = new PeopleController();
			const impersonateListPromise = peopleController.fetchPeopleImpersonate();
			const unobservedStatusPromise = dataController.getUnobservedStatus();
			const vaccineStatusPromise = dataController.fetchVaccineStatus();

			Promise.all([featuresPromise, profilePromise, statusPromise, impersonateListPromise, unobservedStatusPromise, vaccineStatusPromise])
				.then(([featureResponse, profileResponse, statusResponse, impersonateListResponse, unobservedStatusResponse, vaccineStatusResponse]) => {
					setIsAuthenticated(true);

					try {
						dataController
							.informationOverview()
							.then((informationOverviewResponse) => {
								mainAppState!.informationOverview = informationOverviewResponse;

								// Checking if status loaded
								if (statusResponse.status_model === null) {
									setPageError({
										error: true,
										errorText: `An error occurred and the application could not be loaded.`,
										showRetry: true,
									});
								} else {
									if (statusResponse.error) {
										setPageError({
											error: true,
											errorText: `An error occurred and the application could not be loaded.`,
											showRetry: true,
										});
									} else {
										if (statusResponse.status_model!.status === null) {
											setPageError({
												error: true,
												errorText: `An error occurred and the application could not be loaded.`,
												showRetry: true,
											});
										}
									}
								}

								let permissions = featureResponse.reduce((obj: any, curr: any) => {
									return { ...obj, [curr.id]: curr.value };
								}, {});

								mainAppState!.features = permissions;

								let impersonateList = impersonateListResponse.people;

								mainAppState!.impersonateList = impersonateList;
								mainAppState!.unobservedStatus = unobservedStatusResponse.data;
								mainAppState!.updateUnobservedStatus(unobservedStatusResponse.data);
								mainAppState!.updateVaccineStatus(vaccineStatusResponse.status);

								if (!!unobservedStatusResponse.data.barcode && !unobservedStatusResponse.data.canSubmit) {
									mainAppState!.unobservedTestDone = true;
								} else {
									mainAppState!.unobservedTestDone = false;
								}

								//setDisplayName(profileResponse.display_name)
								userProfileStoreState!.setUserProfile({
									kerberosId: profileResponse.kerberos,
									firstName: profileResponse.first_name,
									lastName: profileResponse.last_name,
									phoneNr: profileResponse.phone_number,
									displayName: profileResponse.display_name,
									canSubmitOnBehalf: permissions["covid19.impersonate"] || impersonateList!.length > 0,
								});

								// have a null check up higher
								if (!statusResponse.error) {
									mainAppState!.updatePersonStatus(statusResponse);
									if (mainAppState.personCanAccessApplication()) {
										setCanAccess(true);
									} else {
										setCanAccess(false);
									}
								} else {
									setPageError({
										error: true,
										errorText: `An error occurred and the application could not be loaded. If the problem persists, please contact ${
											mainAppState!.texts[COVID_TEXTS["covid19.pass.help_email"]]
										}.`,
										showRetry: true,
									});
								}

								setPageLoaded(true);
								setTimeout(() => {
									setIsLoading(false);
								}, 500);
								setTimeout(() => {
									setIsReady(true);
								}, 500);
							})
							.catch((error: any) => console.error(error));
					} catch (exception) {
						console.error(exception);
					}
				})
				.catch((error: any) => {
					console.error(error);
					setPageLoaded(true);
					setPageError({
						error: true,
						errorText: `An error occurred and the application could not be loaded. If the problem persists, please contact ${mainAppState!.texts[COVID_TEXTS["covid19.pass.help_email"]]}.`,
						showRetry: true,
					});

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
					setTimeout(() => {
						setIsReady(true);
					}, 500);
				});
			appText();
		} else {
			setPageLoaded(true);
			setPageError({
				error: true,
				errorText: `This browser is not supported, please upgrade to a later browser`,
				showRetry: false,
			});

			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
			setTimeout(() => {
				setIsReady(true);
			}, 2000);
		}
	}, []);

	const Modal = withComponent(Pin);

	return (
		<div>
			<Provider mainAppState={mainAppState}>
				<Modal show={showPin} onClose={() => setShowPin(false)} />
				{!isReady ? (
					<Loader name="COVID Pass" theme={"medical"} contactEmail="" exit={!isLoading} />
				) : pageError.error ? (
					<Error message={pageError.errorText} type={ErrorType.AccessDenied} context={{} as any} />
				) : (
					<AtlasPage
						gaId={"G-EZEXRSFQES"}
						onLogoClick={() => goHome()}
						name={"COVID Pass"}
						subtitle={""}
						content={
							<Fragment>
								<Provider userProfileStoreState={userProfileStoreState}>
									<Myself />
								</Provider>
							</Fragment>
						}
						profile={<Profile name={displayName} imageUrl={imageUrl} subtitle={department} submenu={profileDropdownItems} />}
						navigation={<NavBar type={NavBarTypes.IconText} tabs={navItems} />}
						utilityNavigation={utilNavItems}
						environment={getEnv() as any}
						theme={"medical"}
					/>
				)}
			</Provider>
		</div>
	);
};
export default withRouter(Home);
